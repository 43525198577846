import React from 'react';
import styles from './UseOfForceForm.module.scss';
import {
  ItemValueType,
  Section,
  SectionValueType,
  UseOfForceConfigType,
  UseOfForceValueType
} from '../../../../../Models';
import { SectionForm } from '../../SectionForm/SectionForm';
import { OtherAgenciesInvolvedForm } from './OtherAgenciesInvolvedForm/OtherAgenciesInvolvedForm';

export interface UseOfForceFormProps {
  config: UseOfForceConfigType;
  value: UseOfForceValueType;
  onChange: (value: UseOfForceValueType) => void;
}

export const UseOfForceForm = ({
  config,
  value,
  onChange
}: UseOfForceFormProps): React.ReactElement<UseOfForceFormProps> => {
  const { other_agencies_involved, ...sectionValue } = value;

  const onSectionChange = (newValue: SectionValueType) => {
    onChange({
      ...value,
      ...newValue
    });
  };

  const onAgenciesChange = (agencies: ItemValueType[]) => {
    onChange({
      ...sectionValue,
      other_agencies_involved: agencies
    });
  };

  return (
    <div className={styles.useOfForceForm}>
      <SectionForm
        parentSectionName="use_of_force"
        config={config.sections[0] as Section}
        isInner
        value={sectionValue as SectionValueType}
        onChange={onSectionChange}
      />

      {config.other_agencies_involved && (
        <OtherAgenciesInvolvedForm
          config={config.other_agencies_involved}
          agencies={other_agencies_involved}
          onChange={onAgenciesChange}
        />
      )}
    </div>
  );
};
