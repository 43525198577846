import React from 'react';
import styles from './TagsInformation.module.scss';
import { ReportTags } from '../../../../Models';
import { SFChip, SFText } from 'sfui';

export interface TagsInformationProps {
  tags: ReportTags;
}

export const TagsInformation = ({
  tags
}: TagsInformationProps): React.ReactElement<TagsInformationProps> => {
  return (
    <div className={styles.tagsInformation}>
      <h4>Tags</h4>
      <div className={styles.infoItem}>
        <SFText type="component-messages" sfColor="neutral">
          AGENCY TAGS
        </SFText>
        <div className={styles.chips}>
          {tags.agency.map((t) => (
            <SFChip
              key={t.id}
              size="small"
              variant="outlined"
              sfColor={t.color}
              label={t.name}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
