import moment from 'moment';
import { MasonryElementSize } from '../Components/Masonry/MasonryElement/MasonryElement';
import {
  AnalyticsFiltersForm,
  AnalyticsPeriod,
  AnalyticsPeriodOption,
  AnalyticsPeriodOptions,
  Widget
} from '../Models';
import { formatUTCDateString } from 'ui-smartforce-settings';

interface WidgetComponentSizeDictType {
  [key: string]: MasonryElementSize;
}

const widgetComponentSizeDict: WidgetComponentSizeDictType = {
  rate_card: 'xs',
  ranking_card: 'sm',
  doughnut: 'sm',
  gradient_line: 'md',
  heat_map: 'md',
  cluster_map: 'md',
  sankey: 'md',
  filter_map: 'lg',
  pattern_map: 'lg'
};

export function getWidgetColSize(componentName: string): MasonryElementSize {
  return widgetComponentSizeDict[componentName];
}

export function onShareWidgetClone(
  element: HTMLElement,
  changeWidth: boolean
): void {
  // Add class to adjust element layout
  element.classList.add('SFAnalytics-ShareImage');
  element.style.height = 'auto';

  if (changeWidth) {
    element.style.width = 'fit-content';
  }

  //Remove map buttons
  const map = element.querySelector('div[class*=GoogleMap_module_googleMap]');

  if (map) {
    const controls: NodeListOf<Element> = map.querySelectorAll('div.gmnoprint');
    controls.forEach((e: Element) => e.remove());
  }
}

export function isMapWidget(widget: Widget): boolean {
  return ['heat_map', 'cluster_map', 'pattern_map', 'filter_map'].includes(
    widget.component.name
  );
}

export function getPeriodString(period: AnalyticsPeriod): string {
  const dateFormat: string = 'MMM D, YYYY';

  return `
    ${formatUTCDateString(period.from, dateFormat)}
    ${' - '}
    ${formatUTCDateString(period.to, dateFormat)}
  `;
}

export function getDateChipLabel(
  filters: AnalyticsFiltersForm,
  period?: AnalyticsPeriod,
  periodOptions?: AnalyticsPeriodOptions
): string {
  if (
    filters.period === 'custom' &&
    filters.dateRange?.from &&
    filters.dateRange.to
  ) {
    return `${moment(filters.dateRange.from).format('MMM D, YYYY')}-
      ${moment(filters.dateRange.to).format('MMM D, YYYY')}
    `;
  } else if (
    periodOptions &&
    (filters.period === 'quarter' ||
      filters.period === 'half_year' ||
      filters.period === 'year')
  ) {
    const filterLabel =
      filters.period === 'quarter'
        ? 'Quarter'
        : filters.period === 'half_year'
        ? 'Half-year'
        : 'Year';

    const filterValue = periodOptions[filters.period].find(
      (o) => o.value === filters.periodValue
    ) as AnalyticsPeriodOption;

    return `${filterLabel}: ${filterValue?.label}`;
  } else {
    const periodString = period ? `: ${getPeriodString(period)}` : '';
    const periodLabel = filters.period === 'week' ? 'Last week' : 'Last month';
    return periodLabel + periodString;
  }
}
