import { History, LocationState } from 'history';
import { HttpStatusCode } from 'sfui';
import { SESSION_EXPIRE } from '../Constants/Events';
import { dispatchCustomEvent } from 'ui-smartforce-settings';

export const isUnauthorized = (e: any): boolean =>
  e.code === HttpStatusCode.UNAUTHORIZED && e.detail === 'Unauthorized';

export const isBadCredentials = (e: any): boolean =>
  e.code === HttpStatusCode.BAD_REQUEST && e.detail === 'LOGIN_BAD_CREDENTIALS';

export const isUserNotVerified = (e: any): boolean =>
  e.code === HttpStatusCode.BAD_REQUEST &&
  e.detail === 'LOGIN_USER_NOT_VERIFIED';

export const isUserInvitationAlreadyAccepted = (e: any): boolean =>
  e.code === HttpStatusCode.BAD_REQUEST &&
  e.detail === 'USER_INVITATION_ALREADY_ACCEPTED';

export const isOfficerAlreadyWithAgency = (e: any): boolean =>
  e.code === HttpStatusCode.BAD_REQUEST &&
  e.detail === 'USER_ALREADY_WITH_AGENCY';

export const isOfficerIdAlreadyExist = (e: any): boolean =>
  e.code === HttpStatusCode.BAD_REQUEST &&
  e.detail === 'OFFICER_ID_ALREADY_EXISTS';

export const isAgencyAlreadyExist = (e: any): boolean =>
  e.code === HttpStatusCode.BAD_REQUEST &&
  e.detail === 'REGISTER_AGENCY_ALREADY_EXISTS';

export const isUserAlreadyExist = (e: any): boolean =>
  e.code === HttpStatusCode.BAD_REQUEST &&
  e.detail === 'REGISTER_USER_ALREADY_EXISTS';

export const isUserAlreadyVerified = (e: any): boolean =>
  e.code === HttpStatusCode.BAD_REQUEST &&
  e.detail === 'VERIFY_USER_ALREADY_VERIFIED';

export const isVerifyTokenExpired = (e: any): boolean =>
  e.code === HttpStatusCode.BAD_REQUEST && e.detail === 'VERIFY_USER_BAD_TOKEN';

export const isUserNotMember = (e: any): boolean =>
  e.code === HttpStatusCode.BAD_REQUEST &&
  e.detail === 'USER_NOT_AN_AGENCY_MEMBER';

export const isActionNoLongerPossible = (e: any): boolean =>
  e.code === HttpStatusCode.BAD_REQUEST &&
  e.detail === 'ACTION_NO_LONGER_POSSIBLE';

export const isAreaNotAvailable = (e: any): boolean =>
  e.detail === 'AREA_NOT_EXIST';

export const isUserInvitationNotExist = (e: any): boolean =>
  e.code === HttpStatusCode.BAD_REQUEST &&
  e.detail === 'USER_INVITATION_NOT_EXIST';

export const isOwnerInvited = (e: any): boolean =>
  e.code === HttpStatusCode.BAD_REQUEST &&
  e.detail === 'OWNER_USERS_CANNOT_BE_INVITED';

export const isForbidden = (e: any): boolean =>
  e.code === HttpStatusCode.FORBIDDEN;

export const isAnalyticsNotFound = (e: any): boolean =>
  e.code === HttpStatusCode.NOT_FOUND && e.detail === 'ANALYTICS_NOT_FOUND';

export const isUserNotExist = (e: any): boolean =>
  e.code === HttpStatusCode.NOT_FOUND && e.detail === 'USER_NOT_EXIST';

export const isInvalidSubscription = (e: any): boolean =>
  e.code === HttpStatusCode.CONFLICT &&
  e.detail === 'INVALID_SUBSCRIPTION_STATUS';

export const isTimeout = (e: any): boolean =>
  e.code === HttpStatusCode.GATEWAY_TIMEOUT;

export const handleError = (e: any, history: History<LocationState>): void => {
  if (isUnauthorized(e)) {
    dispatchCustomEvent(SESSION_EXPIRE);
  } else if (isUserNotMember(e)) {
    history.push('/not-member');
  } else if (isActionNoLongerPossible(e) || isAreaNotAvailable(e)) {
    history.push('/action-not-available');
  } else if (isForbidden(e)) {
    history.push('/action-not-allowed');
  } else if (isInvalidSubscription(e)) {
    history.push('/subscription-paused');
  } else {
    history.push('/error', {
      code: e.code,
      text: e.text,
      detail: e.detail
    });
  }
};
