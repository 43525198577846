import React from 'react';
import styles from './IncidentNumberView.module.scss';
import { SFChip, SFDivider, SFScrollable, SFText, SFTextField } from 'sfui';
import FooterButtons, {
  FooterButtonAction
} from '../FooterButtons/FooterButtons';
import { UserContext } from 'ui-smartforce-settings';
import { isIncidentNumberValid } from '../../../../Helpers';
import { INCIDENT_NUMBER_HELP_MSG } from '../../../../Constants/Messages';
import { StateConfigContext } from '../../../../Context/StateConfigContext';
import { Field, ReportConfig } from '../../../../Models';

function getCaseNumberConfig(stateConfig: ReportConfig): Field | undefined {
  const section = stateConfig.general_information.sections.find(
    (s) => s.name === 'to_connect_reports'
  );

  if (section) {
    return section.fields.find((f) => f.name === 'event_identifier');
  }
}

export interface IncidentNumberViewProps {
  onCancel: () => void;
  onContinue: (incidentNumber: string, caseNumber: string) => void;
}

const getIncidentNumberGenerated = (officerId: string): string => {
  const timestamp: string = new Date().getTime().toString();

  //Remove possible special characters from officer id
  const officerIdWithoutSpecial: string = officerId.replace(
    /[^A-Za-z0-9-]/g,
    ''
  );

  return `${timestamp.substring(0, 10)}-${officerIdWithoutSpecial}`;
};

const IncidentNumberView = (
  props: IncidentNumberViewProps
): React.ReactElement<IncidentNumberViewProps> => {
  const { user } = React.useContext(UserContext);
  const stateConfig: ReportConfig = React.useContext(StateConfigContext)
    .stateConfig as ReportConfig;

  const [incidentNumber, setIncidentNumber] = React.useState(
    getIncidentNumberGenerated(user?.officer_id as string)
  );
  const [caseNumber, setCaseNumber] = React.useState<string>('');

  const continueButton: FooterButtonAction = {
    label: 'Continue',
    disabled:
      !isIncidentNumberValid(incidentNumber) ||
      (caseNumber.length > 0 && !isIncidentNumberValid(caseNumber)),
    onClick: () => props.onContinue(incidentNumber, caseNumber)
  };

  const caseNumberConfig = getCaseNumberConfig(stateConfig);

  return (
    <div className={`${styles.incidentNumberView} ${styles.withFooterButtons}`}>
      <SFScrollable
        className={styles.scrollable}
        containerClassName={styles.scrollableContainer}
      >
        <div className={styles.content}>
          <h2 className={styles.title}>Add Incident Numbers</h2>
          <p className={styles.subTitle}>
            You can add the CAD number if needed.
          </p>

          <div className={styles.form}>
            <SFTextField
              label="Incident Number"
              required
              value={incidentNumber}
              autoFocus // eslint-disable-line jsx-a11y/no-autofocus
              onChange={(event): void => setIncidentNumber(event.target.value)}
              helperText={INCIDENT_NUMBER_HELP_MSG}
              error={!isIncidentNumberValid(incidentNumber)}
            />

            <SFDivider />

            {caseNumberConfig && (
              <div className={styles.caseNumber}>
                <div className={styles.caseNumberTitle}>
                  <SFText type="component-1-medium">ID to link reports</SFText>
                  <SFChip label="OPTIONAL" size="small" sfColor="default" />
                </div>
                <SFTextField
                  label={caseNumberConfig.label}
                  required={caseNumberConfig.required}
                  value={caseNumber}
                  onChange={(event): void => setCaseNumber(event.target.value)}
                  helperText={caseNumberConfig.hint}
                  error={
                    caseNumber.length > 0 && !isIncidentNumberValid(caseNumber)
                  }
                />
              </div>
            )}
          </div>
        </div>
      </SFScrollable>

      <footer>
        <FooterButtons
          onCancel={props.onCancel}
          buttonAction={continueButton}
        />
      </footer>
    </div>
  );
};

export default IncidentNumberView;
