import React, { useContext, useState, useCallback } from 'react';
import styles from './WidgetCardContainer.module.scss';
import { SFCard, SFChip } from 'sfui';
import { MediaContext } from 'ui-smartforce-settings';
import {
  AnalyticsPeriod,
  AnalyticsPeriodType,
  Widget,
  WidgetComponent
} from '../../../Models/Widget';
import { ClusterMap } from '../Widgets/ClusterMap/ClusterMap';
import { Doughnut } from '../Widgets/Doughnut/Doughnut';
import { FilterMap } from '../Widgets/FilterMap/FilterMap';
import { GradientLine } from '../Widgets/GradientLine/GradientLine';
import { HeatMap } from '../Widgets/HeatMap/HeatMap';
import { PatternMap } from '../Widgets/PatternMap/PatternMap';
import { RateCard } from '../Widgets/RateCard/RateCard';
import { Sankey } from '../Widgets/Sankey/Sankey';
import { NoData } from '../Widgets/NoData/NoData';
import {
  isValueEmpty,
  downloadCanvas,
  onShareWidgetClone,
  toCanvas,
  replaceSpecialChars
} from '../../../Helpers';
import { WidgetCardHeader } from '../WidgetCardHeader/WidgetCardHeader';
import {
  WIDGET_CLUSTER_MAP,
  WIDGET_DOUGHNUT,
  WIDGET_FILTER_MAP,
  WIDGET_GRADIENT_LINE,
  WIDGET_HEAT_MAP,
  WIDGET_PATTERN_MAP,
  WIDGET_RANKING_CARD,
  WIDGET_RATE_CARD,
  WIDGET_SANKEY
} from '../../../Constants/Analytics';
import { RankingCard } from '../Widgets/RankingCard/RankingCard';
import { SmartforceLegend } from '../SmartforceLegend/SmartforceLegend';
import { AnalyticsWidgetsContext } from '../../../App/Main/Analytics/AnalyticsWidgets/AnalyticsWidgets';

function getLimitedResponseTooltipTitle(
  widget: Widget,
  reportsLength: number
): string {
  if (widget.component.name === 'pattern_map' && widget.component.limited) {
    return `Limited to the last ${widget.component.config.mapData.length} reports.`;
  } else {
    return `Based on the ${reportsLength} most significant reports that occurred during the selected period.`;
  }
}

function NoDataPaddingContainer({
  children
}: {
  children: React.ReactNode;
}): JSX.Element {
  return <div className={styles.noDataPaddingContainer}>{children}</div>;
}

function checkShowResponseInfo(
  widget: Widget,
  isResponseLimited: boolean
): boolean {
  return (
    isResponseLimited &&
    (widget.component.name === 'cluster_map' ||
      widget.component.name === 'filter_map' ||
      widget.component.name === 'heat_map' ||
      widget.component.name === 'pattern_map') &&
    !isValueEmpty(widget.component.config.mapData)
  );
}

function getInfoMessage(
  widget: Widget,
  isResponseLimited: boolean,
  reportsLength: number,
  period: AnalyticsPeriodType
): string | undefined {
  if (checkShowResponseInfo(widget, isResponseLimited)) {
    return getLimitedResponseTooltipTitle(widget, reportsLength);
  } else if (widget.component.name === 'gradient_line') {
    let label = '';
    if (period === 'year') {
      label = 'months';
    } else if (period === 'half_year') {
      label = 'fortnights';
    } else if (period === 'quarter') {
      label = 'weeks';
    } else {
      label = 'dates';
    }
    return `X-axis values correspond to the filtered ${label}`;
  }
}

function getWidgetFileName(widget: Widget, period: AnalyticsPeriod): string {
  return `${period.from.substring(0, 10)}_${period.to.substring(
    0,
    10
  )}_${widget.component.name.replace(/_/g, '-')}_${replaceSpecialChars(
    widget.title,
    '-'
  )}`;
}

function isWithPadding(componentName: string): boolean {
  return (
    componentName !== WIDGET_GRADIENT_LINE && componentName !== WIDGET_SANKEY
  );
}

function hasDrawerOption(componentName: string): boolean {
  return (
    componentName === WIDGET_FILTER_MAP || componentName === WIDGET_PATTERN_MAP
  );
}

function getDrawerOptionLabel(componentName: string): string {
  switch (componentName) {
    case WIDGET_FILTER_MAP:
      return 'Filter by';
    case WIDGET_PATTERN_MAP:
      return 'See pattern';
    default:
      return '';
  }
}

function checkEmtpyDataWidget(widget: Widget): boolean {
  if (
    widget.component.name === WIDGET_CLUSTER_MAP ||
    widget.component.name === WIDGET_FILTER_MAP ||
    widget.component.name === WIDGET_HEAT_MAP ||
    widget.component.name === WIDGET_PATTERN_MAP
  ) {
    return isValueEmpty(widget.component.config.mapData);
  } else if (
    widget.component.name === WIDGET_DOUGHNUT ||
    widget.component.name === WIDGET_GRADIENT_LINE
  ) {
    return isValueEmpty(widget.component.config.data);
  } else if (widget.component.name === WIDGET_RANKING_CARD) {
    return isValueEmpty(widget.component.config.ranking_list);
  } else if (widget.component.name === WIDGET_RATE_CARD) {
    return isValueEmpty(widget.component.config.percentage);
  } else if (widget.component.name === WIDGET_SANKEY) {
    return (
      isValueEmpty(widget.component.config.nodes) ||
      isValueEmpty(widget.component.config.links)
    );
  } else return true;
}

const getComponent = (
  widget: Widget,
  isResponseLimited: boolean,
  periodType: AnalyticsPeriodType,
  isWidgetDataEmpty: boolean
) => {
  const component: WidgetComponent = widget.component;

  switch (component.name) {
    case WIDGET_CLUSTER_MAP:
      return !isWidgetDataEmpty ? (
        <ClusterMap {...component.config} data={component.config.mapData} />
      ) : (
        <NoData
          type={component.name}
          link={widget.help_url}
          isContentOmitted={isResponseLimited}
        />
      );
    case WIDGET_DOUGHNUT:
      return !isWidgetDataEmpty ? (
        <Doughnut {...component.config} />
      ) : (
        <NoData type={component.name} link={widget.help_url} />
      );
    case WIDGET_FILTER_MAP:
      return !isWidgetDataEmpty ? (
        <FilterMap
          {...component.config}
          title={widget.title}
          data={component.config.mapData}
          options={component.config.filterMapOptions}
        />
      ) : (
        <NoData
          type={component.name}
          link={widget.help_url}
          isContentOmitted={isResponseLimited}
        />
      );
    case WIDGET_GRADIENT_LINE:
      return !isWidgetDataEmpty ? (
        <GradientLine {...component.config} periodType={periodType} />
      ) : (
        <NoDataPaddingContainer>
          <NoData type={component.name} link={widget.help_url} />
        </NoDataPaddingContainer>
      );
    case WIDGET_HEAT_MAP:
      return !isWidgetDataEmpty ? (
        <HeatMap {...component.config} data={component.config.mapData} />
      ) : (
        <NoData
          type={component.name}
          link={widget.help_url}
          isContentOmitted={isResponseLimited}
        />
      );
    case WIDGET_PATTERN_MAP:
      return !isWidgetDataEmpty ? (
        <PatternMap
          {...component.config}
          title={widget.title}
          data={component.config.mapData}
        />
      ) : (
        <NoData
          type={component.name}
          link={widget.help_url}
          isContentOmitted={isResponseLimited}
        />
      );
    case WIDGET_RANKING_CARD:
      return !isWidgetDataEmpty ? (
        <RankingCard {...component.config} />
      ) : (
        <NoData type={component.name} link={widget.help_url} />
      );
    case WIDGET_RATE_CARD:
      return !isWidgetDataEmpty ? (
        <RateCard {...component.config} />
      ) : (
        <NoData type={component.name} link={widget.help_url} />
      );
    case WIDGET_SANKEY:
      return !isWidgetDataEmpty ? (
        <Sankey {...component.config} />
      ) : (
        <NoDataPaddingContainer>
          <NoData type={component.name} link={widget.help_url} />
        </NoDataPaddingContainer>
      );
    default:
      return <></>;
  }
};

export interface WidgetCardContainerProps {
  widget: Widget;
  isActive?: boolean;
  period: AnalyticsPeriod;
  isResponseLimited: boolean;
  reportsLength: number;
  isMaximize?: boolean;
  onToggleMaximize?: (isMaximize: boolean) => void;
  onAdd?: (id: number) => void;
  onRemove?: (id: number) => void;
}

export const WidgetCardContainer = ({
  widget,
  isActive = false,
  period,
  isResponseLimited,
  reportsLength,
  isMaximize,
  onToggleMaximize,
  onAdd,
  onRemove
}: WidgetCardContainerProps): React.ReactElement<WidgetCardContainerProps> => {
  const { isPhone } = useContext(MediaContext);
  const dateFilterChipLabel = useContext(AnalyticsWidgetsContext);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const widgetElementRef = React.useRef<HTMLElement>();

  const drawerOption =
    isPhone && hasDrawerOption(widget.component.name)
      ? {
          label: getDrawerOptionLabel(widget.component.name),
          onClick: () => setIsDrawerOpen(true)
        }
      : undefined;

  const onDrawerClose = () => setIsDrawerOpen(false);

  const onShare = async () => {
    if (widgetElementRef.current) {
      const canvas = await toCanvas(widgetElementRef.current, onCanvasClone);
      const fileName = getWidgetFileName(widget, period);
      downloadCanvas(canvas, fileName);
    }
  };

  const onCanvasClone = useCallback(
    (_d: Document, element: HTMLElement) => {
      onShareWidgetClone(element, !widget.component.name.includes('map'));
    },
    [widget.component.name]
  );

  const rowFooter =
    widget.component.name === 'gradient_line' ||
    widget.component.name === 'sankey';

  const wideWidget =
    widget.component.name !== 'rate_card' &&
    widget.component.name !== 'doughnut' &&
    widget.component.name !== 'ranking_card';

  const isWidgetDataEmpty = checkEmtpyDataWidget(widget);

  return (
    <SFCard
      className={styles.card}
      containerClassName={styles.widgetCardContainer}
      sfElevation={2}
      ref={widgetElementRef}
    >
      <WidgetCardHeader
        title={widget.title}
        isActive={isActive}
        drawerMenuOption={drawerOption}
        infoMessage={getInfoMessage(
          widget,
          isResponseLimited,
          reportsLength,
          period.name
        )}
        isMaximize={isMaximize}
        onAdd={() => onAdd && onAdd(widget.id)}
        onRemove={() => onRemove && onRemove(widget.id)}
        onShare={!isWidgetDataEmpty ? onShare : undefined}
        onToggleMaximize={onToggleMaximize}
      />

      <div
        className={`${styles.content} ${
          isWithPadding(widget.component.name) ? styles.isWithPadding : ''
        }`}
      >
        {React.cloneElement(
          getComponent(
            widget,
            isResponseLimited,
            period.name,
            isWidgetDataEmpty
          ),
          {
            isDrawerOpen,
            onDrawerClose
          }
        )}

        <div
          className={`${styles.footer} ${rowFooter ? styles.rowFooter : ''} ${
            wideWidget ? styles.wideFooter : ''
          }`}
        >
          <SFChip
            className={styles.footerChip}
            label={dateFilterChipLabel}
            sfColor="primary"
            size="small"
            variant="outlined"
          />
          <SmartforceLegend className={styles.footerLegend} />
        </div>
      </div>
    </SFCard>
  );
};
