import React, { useEffect, useState } from 'react';
import { SaveObjectPanel } from '../../../../../Components/SaveObjectPanel/SaveObjectPanel';
import {
  checkSectionsFields,
  getSectionsDefaultValue
} from '../../../../../Helpers';
import { ItemListConfig, ItemValueType, Section } from '../../../../../Models';
import { ItemForm } from '../ItemForm/ItemForm';
import {
  ItemListSection,
  ItemListSectionMessage
} from '../ItemListSection/ItemListSection';
import {
  getUserGroups,
  UserGroup,
  isEqualObject
} from 'ui-smartforce-settings';

export const UserGroupsContext = React.createContext<UserGroup[]>([]);

export interface ItemListFormProps {
  name: string;
  className?: string;
  config: ItemListConfig;
  itemLabel: string;
  itemListValue: ItemValueType[];
  disabled?: boolean;
  message?: ItemListSectionMessage;
  onChange: (itemListValue: ItemValueType[]) => void;
}

export const ItemListForm = ({
  name,
  className,
  config,
  itemLabel,
  itemListValue,
  disabled = false,
  message,
  onChange
}: ItemListFormProps): React.ReactElement<ItemListFormProps> => {
  const [isPanelOpen, setIsPanelOpen] = React.useState<boolean>(false);

  const [value, setValue] = React.useState<ItemValueType>(
    getSectionsDefaultValue(config.sections as Section[])
  );

  const [selected, setSelected] = React.useState<number>(-1);
  const [groups, setGroups] = useState<UserGroup[]>([]);

  const onAdd = () => {
    const sectionDefaultValue = getSectionsDefaultValue(
      config.sections as Section[]
    );

    //Check if its neccessary to reuse first item fields data
    if (config.reuse_fields && itemListValue.length > 0) {
      //Initialize the value for the next item with defaults
      const nextValue = { ...sectionDefaultValue };

      //Loop the fields to reuse in config
      for (let fieldString of config.reuse_fields) {
        const [fieldSection, fieldName] = fieldString.split('.');

        //If the first item has a value for that field, reuse it
        if (itemListValue[0][fieldSection][fieldName]) {
          //Initialize section if its empty
          if (!nextValue[fieldSection]) {
            nextValue[fieldSection] = {};
          }

          //Reuse field value from the first item
          nextValue[fieldSection][fieldName] =
            itemListValue[0][fieldSection][fieldName];
        }
      }

      setValue(nextValue);
    } else {
      setValue(sectionDefaultValue);
    }

    setIsPanelOpen(true);
  };

  const onSelect = (index: number): void => {
    setSelected(index);
    setValue(itemListValue[index]);
    setIsPanelOpen(true);
  };

  const onSave = () => {
    // Is a new itemList
    if (selected === -1) {
      onChange([...itemListValue, value]);
    } else {
      onChange([
        ...itemListValue.slice(0, selected),
        value,
        ...itemListValue.slice(selected + 1)
      ]);
    }

    onClosePanel();
  };

  const onClosePanel = () => {
    setIsPanelOpen(false);
    setSelected(-1);
  };

  const isSaveDisabled: boolean =
    !checkSectionsFields(config, value) ||
    (selected !== -1 &&
      isEqualObject<ItemValueType>(itemListValue[selected], value));

  useEffect(() => {
    let isSubscribed: boolean = true;
    const updateGroups = async () => {
      if (value.officer_information?.officer_name) {
        const groups = await getUserGroups(
          process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
          value.officer_information.officer_name as string,
          value.officer_information.id as string | undefined
        );

        if (isSubscribed) {
          setGroups(groups);
        }
      }
    };

    if (value.officer_information?.officer_name) {
      updateGroups();
    } else {
      setGroups([]);
    }

    return () => {
      isSubscribed = false;
    };
  }, [value.officer_information?.officer_name, value.officer_information?.id]);

  return (
    <React.Fragment>
      <SaveObjectPanel
        title={`${itemLabel} ${
          selected === -1 ? itemListValue.length + 1 : selected + 1
        }`}
        isOpen={isPanelOpen}
        isSaveDisabled={isSaveDisabled}
        saveBtnLabel={selected !== -1 ? 'Save Changes' : `Add ${itemLabel}`}
        onClose={onClosePanel}
        onSave={onSave}
      >
        <UserGroupsContext.Provider value={groups}>
          <ItemForm
            sectionName={name}
            config={config}
            value={value}
            onChange={(value: ItemValueType) => setValue(value)}
          />
        </UserGroupsContext.Provider>
      </SaveObjectPanel>

      <ItemListSection
        className={className}
        title={config.title}
        items={itemListValue}
        config={config}
        itemLabel={itemLabel}
        onAdd={onAdd}
        onSelect={onSelect}
        disabled={disabled}
        message={message}
      />
    </React.Fragment>
  );
};
