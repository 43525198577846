import React, {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useState
} from 'react';
import styles from './AnalyticsWidgets.module.scss';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { SFMenuOption, SFScrollable } from 'sfui';
import { WidgetsSection } from './WidgetsSection/WidgetsSection';
import { MyWidgets } from './MyWidgets/MyWidgets';
import {
  AnalyticsConfig,
  AnalyticsFiltersForm,
  AnalyticsFiltersRequest,
  AnalyticsPeriod,
  AnalyticsPeriodOption,
  AnalyticsPeriodOptions,
  WidgetBase
} from '../../../../Models/Widget';
import {
  formatFilterDate,
  getDateChipLabel,
  handleError,
  isAnalyticsNotFound,
  isTimeout
} from '../../../../Helpers';
import {
  Customer,
  CustomerContext,
  isRoleOfficer,
  User,
  UserContext
} from 'ui-smartforce-settings';
import { MyWidgetHeader } from './MyWidgetHeader/MyWidgetHeader';
import { NoAnalyticsReady } from './NoAnalyticsReady/NoAnalyticsReady';
import { Divider } from '../../../../Components/Divider/Divider';
import { useDispatchMainAlert } from '../../../../Hooks';
import { WidgetsFilter } from './WidgetsFilter/WidgetsFilter';
import { FilterByModal } from './FilterByModal/FilterByModal';
import { AnalyticsWidgetsLoader } from './AnalyticsWidgetsLoader/AnalyticsWidgetsLoader';
import { ManageWidgetsModal } from './ManageWidgetsModal/ManageWidgetsModal';
import AnalyticsService from '../../../../Services/AnalyticsService';
import { PeriodOptionSoon } from './FilterByModal/PeriodOptionSoon/PeriodOptionSoon';
import { AnalyticsRequestTimeout } from './AnalyticsRequestTimeout/AnalyticsRequestTimeout';

function formatOptions(items: AnalyticsPeriodOption[]): SFMenuOption[] {
  let options: SFMenuOption[] = [];
  for (let item of items) {
    if (item.coming_soon) {
      options = [
        ...options,
        {
          label: item.label,
          value: item.value,
          disabled: true,
          item: <PeriodOptionSoon label={item.label} />
        }
      ];
    } else {
      options = [
        ...options,
        {
          label: item.label,
          value: item.value
        }
      ];
    }
  }

  return options;
}

function getRequestFilters(
  filters: AnalyticsFiltersForm,
  timezone: string
): AnalyticsFiltersRequest {
  let request: AnalyticsFiltersRequest = {
    period: filters.period,
    filters: {}
  };

  if (
    filters.period === 'custom' &&
    filters.dateRange?.from &&
    filters.dateRange?.to
  ) {
    const to = moment(filters.dateRange.to).add(1, 'day').toDate();

    request.filters.general_information = {
      date: {
        from: formatFilterDate(filters.dateRange.from, timezone),
        to: formatFilterDate(to, timezone)
      }
    };
  }

  if (
    filters.period === 'quarter' ||
    filters.period === 'half_year' ||
    filters.period === 'year'
  ) {
    request.period_value = filters.periodValue;
  }

  if (filters.officerOption === 'name' && filters.officer) {
    request.filters.officers = {
      officer_information: {
        id: filters.officer?.asyncObject.id
      }
    };
  } else if (filters.officerOption === 'group' && filters.group) {
    request.filters.officers = {
      officer_information: {
        groups: filters.group?.map((g) => g.asyncObject.id)
      }
    };
  }

  if (filters.area) {
    request.filters.general_information = {
      ...request.filters.general_information,
      location: { areas: filters.area.map((a) => a.asyncObject.id) }
    };
  }

  if (filters.tags) {
    request.filters.tags = {
      agency: filters.tags.map((t) => t.id)
    };
  }

  if (filters.source && filters.source !== 'all') {
    request.filters.officers = {
      ...request.filters.officers,
      officer_information: {
        ...request.filters.officers?.officer_information,
        source: filters.source
      }
    };
  }

  return request;
}

export const AnalyticsWidgetsContext = createContext('');

export const AnalyticsWidgets = (): React.ReactElement<{}> => {
  const history = useHistory();
  const customer = useContext(CustomerContext).customer as Customer;
  const user = useContext(UserContext).user as User;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isDoneFetching, setIsDoneFetching] = React.useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [isNotAnalyticsReady, setIsNotAnalyticsReady] =
    React.useState<boolean>(false);
  const [isRequestTimeout, setIsRequestTimeout] =
    React.useState<boolean>(false);
  const [modalTitle, setModalTitle] = React.useState<string>('');
  const [modalWidgetList, setModalWidgetList] = React.useState<WidgetBase[]>(
    []
  );
  const [period, setPeriod] = React.useState<AnalyticsPeriod>();

  const [favourites, setFavourites] = useState<number[]>([]);
  const [widgetLiteList, setWidgetLiteList] = useState<WidgetBase[]>([]);
  const [suggestedWidgets, setSuggestedWidgets] = useState<WidgetBase[]>([]);
  const [newWidgets, setNewWidgets] = useState<WidgetBase[]>([]);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [filters, setFilters] = useState<AnalyticsFiltersForm>({
    period: 'month',
    officerOption: 'name',
    source: isRoleOfficer(user.role.id) ? 'created' : undefined
  });

  const [periodOptions, setPeriodOptions] = useState<AnalyticsPeriodOptions>();
  const [isResponseLimited, setIsResponseLimited] = useState<boolean>(false);
  const [reportsLength, setReportsLength] = useState<number>(0);

  useDispatchMainAlert();

  useEffect(() => {
    // Declare local storage change event listener variable
    // Declared here to remove it on useEffect clean function
    let storageEventListener: {
      (e: StorageEvent): Promise<void>;
    };

    const init = async () => {
      setIsLoading(true);
      setIsDoneFetching(false);
      setIsRequestTimeout(false);
      setIsNotAnalyticsReady(false);

      try {
        const favourites = await AnalyticsService.getFavourites();
        const config: AnalyticsConfig = await AnalyticsService.updateConfig();
        const suggested = AnalyticsService.getSuggestions();
        const newWidgets = AnalyticsService.getNewWidgets();

        if (!isRoleOfficer(user.role.id)) {
          const periods = await AnalyticsService.getPeriods();
          setPeriodOptions({
            quarter: periods.quarter ? formatOptions(periods.quarter) : [],
            half_year: periods.half_year
              ? formatOptions(periods.half_year)
              : [],
            year: periods.year ? formatOptions(periods.year) : []
          });
        }

        setIsResponseLimited(!!config.incident_data_limited);
        setReportsLength(config.incident_data.length);
        setFavourites(favourites);
        setWidgetLiteList(AnalyticsService.getWidgetListLite());
        setSuggestedWidgets(suggested);
        setNewWidgets(newWidgets);

        setPeriod({
          name: config.period,
          from: config.date_from,
          to: config.date_to
        });

        setIsDoneFetching(true);
        setTimeout(() => setIsLoading(false), 3000);
      } catch (e) {
        console.log('Analytics::getConfig', e);
        if (isAnalyticsNotFound(e)) {
          setIsNotAnalyticsReady(true);
          setIsLoading(false);
        } else if (isTimeout(e)) {
          setIsRequestTimeout(true);
          setIsLoading(false);
        } else {
          handleError(e, history);
        }
      }
    };

    // Assign storage event handler to check changes of widget favourites
    storageEventListener = async (e: StorageEvent) => {
      // Update widgets if favourites has changed in another instance (window, tab)
      if (AnalyticsService.isWidgetStorageEvent(e)) {
        const favourites = AnalyticsService.getLSFavourites();
        setFavourites(favourites);
      }
    };

    // Add event listener to window
    window.addEventListener('storage', storageEventListener);

    init();

    return () => {
      // If exists, remove the listener from window
      if (storageEventListener) {
        window.removeEventListener('storage', storageEventListener);
      }
    };
  }, [history, customer, user.role.id]);

  const updateWidgets = async (filters: AnalyticsFiltersForm) => {
    try {
      setIsLoading(true);
      setIsDoneFetching(false);
      setIsRequestTimeout(false);
      setIsNotAnalyticsReady(false);

      const config: AnalyticsConfig = await AnalyticsService.updateConfig(
        getRequestFilters(filters, customer.timezone)
      );
      const suggested = AnalyticsService.getSuggestions();
      const newWidgets = AnalyticsService.getNewWidgets();

      setIsResponseLimited(!!config.incident_data_limited);
      setReportsLength(config.incident_data.length);
      setWidgetLiteList(AnalyticsService.getWidgetListLite());
      setSuggestedWidgets(suggested);
      setNewWidgets(newWidgets);

      setPeriod({
        name: config.period,
        from: config.date_from,
        to: config.date_to
      });

      setIsDoneFetching(true);
      setTimeout(() => setIsLoading(false), 3000);
    } catch (e) {
      console.log('Analytics::getConfig', e);
      if (isAnalyticsNotFound(e)) {
        setIsNotAnalyticsReady(true);
        setIsLoading(false);
      } else if (isTimeout(e)) {
        setIsRequestTimeout(true);
        setIsLoading(false);
      } else {
        handleError(e, history);
      }
    }
  };

  const onModalOpen = (type: 'manage' | 'new' | 'suggestions') => {
    let widgets: WidgetBase[] = [];

    switch (type) {
      case 'manage':
        widgets = widgetLiteList;
        break;
      case 'new':
        widgets = newWidgets;
        break;
      case 'suggestions':
      default:
        widgets = suggestedWidgets;
    }

    setModalWidgetList(widgets);

    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const onChangeFavourites = (newFavourites: number[]) => {
    setFavourites(newFavourites);

    try {
      AnalyticsService.saveFavourites(newFavourites);
    } catch (e) {
      handleError(e, history);
      console.error('AnalyticsWidgets::onChangeFavourites', e);
    }
  };

  const onManageSave = (newFavourites: number[]) => {
    onChangeFavourites(newFavourites);
    onModalClose();
  };

  const onAddWidgets = (widgetsId: number[]) => {
    onChangeFavourites([...favourites, ...widgetsId]);
    onModalClose();
  };

  const onRemove = (id: number) => {
    onChangeFavourites(favourites.filter((f) => f !== id));
    onModalClose();
  };

  const onReorder = (newFavourites: number[]) => {
    try {
      AnalyticsService.saveFavourites(newFavourites);
    } catch (e) {
      handleError(e, history);
      console.error('AnalyticsWidgets::onReorder', e);
    }
  };

  const onFiltersSubmit = (newFilters: AnalyticsFiltersForm) => {
    if (newFilters.period !== filters.period) {
      setPeriod(undefined);
    }
    setFilters(newFilters);
    setIsFiltersOpen(false);
    updateWidgets(newFilters);
  };

  const onFilterDelete = (key: string) => {
    const newFilters = {
      ...filters,
      [key]: undefined
    };
    setFilters(newFilters);
    updateWidgets(newFilters);
  };

  const onTryLater = () => {
    const newFilters: AnalyticsFiltersForm = {
      period: 'month',
      officerOption: 'name',
      source: isRoleOfficer(user.role.id) ? 'created' : undefined
    };
    setFilters(newFilters);
    updateWidgets(newFilters);
  };

  return (
    <AnalyticsWidgetsContext.Provider
      value={getDateChipLabel(filters, period, periodOptions)}
    >
      <div className={styles.analyticsWidgets}>
        <FilterByModal
          isOpen={isFiltersOpen}
          filters={filters}
          periodOptions={periodOptions}
          onSubmit={onFiltersSubmit}
          onClose={() => setIsFiltersOpen(false)}
        />

        {isNotAnalyticsReady && <NoAnalyticsReady />}

        {!isNotAnalyticsReady && (
          <SFScrollable containerClassName={styles.scrollable}>
            <div>
              <MyWidgetHeader
                disabled={isLoading || isRequestTimeout}
                onAddWidgets={() => {
                  onModalOpen('manage');
                  setModalTitle('Manage Widgets');
                }}
              />

              <WidgetsFilter
                period={period}
                filters={filters}
                isLoading={isLoading}
                periodOptions={periodOptions}
                onFilterBy={() => setIsFiltersOpen(true)}
                onFilterDelete={onFilterDelete}
              />

              <Divider className={styles.divider} />
            </div>

            {isLoading && <AnalyticsWidgetsLoader done={isDoneFetching} />}

            {!isLoading && isRequestTimeout && (
              <AnalyticsRequestTimeout onTryLater={onTryLater} />
            )}

            {!isLoading && period && !isRequestTimeout && (
              <Fragment>
                <ManageWidgetsModal
                  title={modalTitle}
                  isOpen={isModalOpen}
                  widgets={modalWidgetList}
                  favourites={favourites}
                  onClose={onModalClose}
                  onSave={onManageSave}
                />

                <MyWidgets
                  draggable={!filters.officer}
                  favourites={favourites}
                  period={period}
                  isResponseLimited={isResponseLimited}
                  reportsLength={reportsLength}
                  onRemove={onRemove}
                  onReorder={onReorder}
                />

                <WidgetsSection
                  title="Suggestions"
                  widgets={suggestedWidgets}
                  period={period}
                  favourites={favourites}
                  isResponseLimited={isResponseLimited}
                  reportsLength={reportsLength}
                  onSeeAll={() => {
                    onModalOpen('suggestions');
                    setModalTitle('Suggestions');
                  }}
                  onAdd={(id: number) => onAddWidgets([id])}
                />

                <WidgetsSection
                  title="New widgets"
                  widgets={newWidgets}
                  period={period}
                  favourites={favourites}
                  isResponseLimited={isResponseLimited}
                  reportsLength={reportsLength}
                  onSeeAll={() => {
                    onModalOpen('new');
                    setModalTitle('New Widgets');
                  }}
                  onAdd={(id: number) => onAddWidgets([id])}
                />
              </Fragment>
            )}
          </SFScrollable>
        )}
      </div>
    </AnalyticsWidgetsContext.Provider>
  );
};
