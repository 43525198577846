import { useContext, useMemo } from 'react';
import styles from './WidgetsFilter.module.scss';
import { SFButton } from 'sfui';
import { MediaContext } from 'ui-smartforce-settings';
import { FilterChip, FilterChipList } from './FilterChipList/FilterChipList';
import {
  AnalyticsFiltersForm,
  AnalyticsPeriod,
  AnalyticsPeriodOptions
} from '../../../../../Models';
import { getDateChipLabel } from '../../../../../Helpers';

function getSourceLabel(value: string): string {
  if (value === 'created') {
    return 'My Reports';
  } else if (value === 'collaborated') {
    return 'Collaborated Reports';
  } else {
    return 'All Reports';
  }
}

function getListLabel<T extends { name: string }>(list: T[]): string {
  return list.map((item) => item.name).join(', ');
}

function getChips(
  filters: AnalyticsFiltersForm,
  period?: AnalyticsPeriod,
  periodOptions?: AnalyticsPeriodOptions
): FilterChip[] {
  let chips: FilterChip[] = [
    {
      key: 'period',
      label: getDateChipLabel(filters, period, periodOptions),
      deleteable: false
    }
  ];

  if (filters.officer) {
    chips = [
      ...chips,
      {
        key: 'officer',
        label: filters.officer.name,
        deleteable: true
      }
    ];
  }

  if (filters.group) {
    chips = [
      ...chips,
      {
        key: 'group',
        label: `Group: ${getListLabel(filters.group)}`,
        deleteable: true
      }
    ];
  }

  if (filters.area) {
    chips = [
      ...chips,
      {
        key: 'area',
        label: `Area: ${getListLabel(filters.area)}`,
        deleteable: true
      }
    ];
  }

  if (filters.source) {
    chips = [
      ...chips,
      {
        key: 'source',
        label: getSourceLabel(filters.source),
        deleteable: true
      }
    ];
  }

  if (filters.tags) {
    chips = [
      ...chips,
      {
        key: 'tags',
        label: `Agency Tags: ${filters.tags.map((t) => t.name).join(', ')}`,
        deleteable: true
      }
    ];
  }

  return chips;
}

export interface WidgetsFilterProps {
  className?: string;
  isLoading: boolean;
  period?: AnalyticsPeriod;
  filters: AnalyticsFiltersForm;
  periodOptions?: AnalyticsPeriodOptions;
  onFilterBy: () => void;
  onFilterDelete: (key: string) => void;
}

export const WidgetsFilter = ({
  className = '',
  isLoading,
  period,
  filters,
  periodOptions,
  onFilterBy,
  onFilterDelete
}: WidgetsFilterProps): React.ReactElement<WidgetsFilterProps> => {
  const { isPhone } = useContext(MediaContext);

  const chips: FilterChip[] = useMemo(
    () => getChips(filters, period, periodOptions),
    [filters, period, periodOptions]
  );

  const onChipDelete = (chip: FilterChip) => onFilterDelete(chip.key);

  return (
    <div className={`${styles.widgetsFilter} ${className}`}>
      <div className={styles.filters}>
        <FilterChipList chips={chips} onDelete={onChipDelete} />
      </div>

      <SFButton
        sfColor="blue"
        variant="contained"
        disabled={isLoading}
        size={isPhone ? 'large' : 'medium'}
        fullWidth={isPhone}
        onClick={onFilterBy}
      >
        {`Filter by (${chips.length})`}
      </SFButton>
    </div>
  );
};
