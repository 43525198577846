import moment from 'moment-timezone';
import { HttpStatusCode } from 'sfui';
import set from 'lodash.set';
import {
  AgencyReportsPagination,
  AgencyReportsResponse,
  ReportListFilters,
  ReportResponse,
  ReportValue,
  ReportsTotals
} from '../Models';
import PendingReportsService from './PendingReportsService';
import { apiGet, apiPost, cloneObject, isValueEmpty } from '../Helpers';
import {
  getUserSession,
  ReportCustomizationFieldValue,
  ReportCustomizationValue
} from 'ui-smartforce-settings';

function getDefaultFilters(timezone: string) {
  return {
    general_information: {
      date: {
        from: moment
          .tz('2022-01-01T00:00:00', timezone)
          .startOf('day')
          .toISOString(),
        to: moment.tz(timezone).endOf('day').toISOString()
      }
    }
  };
}

interface DateRange {
  from: string;
  to: string;
}

function getIncidentDateRange(
  from: string | undefined,
  to: string | undefined,
  limit: 'months' | 'year',
  timezone: string
): DateRange {
  const dateFrom = from
    ? moment.tz(from, 'MM/DD/YYYY', timezone)
    : moment.tz(timezone).startOf('day').subtract(1, limit);

  let dateTo: moment.Moment;

  if (to) {
    dateTo = moment.tz(to, 'MM/DD/YYYY', timezone).endOf('day');
  } else if (from) {
    // Exact date
    dateTo = moment.tz(from, 'MM/DD/YYYY', timezone).endOf('day');
  } else {
    // End of today
    dateTo = moment.tz(timezone).endOf('day');
  }

  return {
    from: dateFrom.toISOString(),
    to: dateTo.toISOString()
  };
}

function getFiltersWithIncidentDate(
  filters: ReportListFilters,
  hasAnalytics: boolean,
  timezone: string
): any {
  if (isValueEmpty(filters)) {
    return getDefaultFilters(timezone);
  }

  const newFilters: any = cloneObject(filters);

  set(
    newFilters,
    'general_information.date',
    getIncidentDateRange(
      newFilters.general_information?.date?.dateFrom,
      newFilters.general_information?.date?.dateTo,
      hasAnalytics ? 'year' : 'months',
      timezone
    )
  );

  return newFilters;
}

export type ReportListType = 'me' | 'agency';

export interface SaveReportResponse {
  success: boolean;
  detail?: string;
}

export const sendReport = async (report: ReportValue): Promise<void> => {
  try {
    const url: string = `${process.env.REACT_APP_API_BASE_URL}/reports/`;
    const { id, ...reportToSend } = report;

    const fetchResp = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `bearer ${getUserSession().access_token}`
      }),
      body: JSON.stringify({ ...reportToSend })
    });

    const fetchData = await fetchResp.json();

    if (!fetchResp.ok) {
      return Promise.reject({
        code: fetchResp.status,
        text: fetchResp.statusText,
        detail: fetchData.detail
      });
    }
  } catch (e) {
    return Promise.reject(e);
  }
};

export const saveReport = async (
  user: string,
  report: ReportValue
): Promise<SaveReportResponse> => {
  try {
    if (navigator.onLine) {
      const url: string = `${process.env.REACT_APP_API_BASE_URL}/reports/`;

      const fetchResp = await fetch(url, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `bearer ${getUserSession().access_token}`
        }),
        body: JSON.stringify({ ...report })
      });

      const fetchData = await fetchResp.json();

      if (fetchResp.ok) {
        return { success: true };
      } else {
        if (
          fetchResp.status === HttpStatusCode.BAD_REQUEST ||
          fetchData.detail === 'CREATED_REPORT_ALREADY_EXISTS'
        ) {
          return Promise.reject({
            code: fetchResp.status,
            text: fetchResp.statusText,
            detail: fetchData.detail
          });
        } else {
          await PendingReportsService.saveReport(user, report);
          return { success: false, detail: fetchData.detail };
        }
      }
    } else {
      await PendingReportsService.saveReport(user, report);
      return { success: false, detail: 'ERR_INTERNET_DISCONNECTED' };
    }
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getReportsByUrl = async (url: string): Promise<ReportResponse> => {
  const fetchResponse = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `bearer ${getUserSession().access_token}`
    })
  });

  if (fetchResponse.ok) {
    return await fetchResponse.json();
  } else {
    const fetchData = await fetchResponse.json();
    return Promise.reject({
      code: fetchResponse.status,
      text: fetchResponse.statusText,
      detail: fetchData.detail
    });
  }
};

export const getUserReports = async (): Promise<ReportResponse> => {
  const url: string = `${process.env.REACT_APP_API_BASE_URL}/reports/me?limit=10`;
  return getReportsByUrl(url);
};

export const getCollaboratedReports = async (): Promise<ReportResponse> => {
  const url: string = `${process.env.REACT_APP_API_BASE_URL}/reports/collaboration?limit=10`;
  return apiGet(url, getUserSession().access_token);
};

export type ReportListQuerySortBy = 'newest' | 'oldest';

interface ReportSearchBody {
  filters: ReportListFilters;
  sort_by: ReportListQuerySortBy;
}

export const getAgencyReports = async (
  filters: ReportListFilters,
  sortBy: ReportListQuerySortBy = 'newest',
  hasAnalytics: boolean,
  timezone: string
): Promise<AgencyReportsResponse> => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/reports/search?limit=10`;
  const token = getUserSession().access_token;

  return apiPost<ReportSearchBody, AgencyReportsResponse>(
    url,
    {
      filters: getFiltersWithIncidentDate(filters, hasAnalytics, timezone),
      sort_by: sortBy
    },
    token
  );
};

export const getNextAgencyReports = async ({
  url,
  body
}: AgencyReportsPagination): Promise<AgencyReportsResponse> => {
  return apiPost(url, body, getUserSession().access_token);
};

export const exportReports = async (
  filters: ReportListFilters,
  sort_by: ReportListQuerySortBy = 'newest',
  hasAnalytics: boolean,
  timezone: string
): Promise<void> => {
  const url: string = `${process.env.REACT_APP_API_BASE_URL}/reports/export`;

  const exportBody: ReportSearchBody = {
    filters: getFiltersWithIncidentDate(filters, hasAnalytics, timezone),
    sort_by
  };

  const fetchResp = await fetch(url, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `bearer ${getUserSession().access_token}`
    }),
    body: JSON.stringify(exportBody)
  });

  if (fetchResp.ok) {
    const fetchData = await fetchResp.json();
    return Promise.resolve(fetchData);
  } else {
    const fetchData = await fetchResp.json();
    return Promise.reject({
      code: fetchResp.status,
      text: fetchResp.statusText,
      detail: fetchData.detail
    });
  }
};

export const getReport = async (
  incidentNumber: string,
  createdAt?: string
): Promise<ReportValue> => {
  const createdAtQuery: string = createdAt ? `?created_at=${createdAt}` : '';
  const url: string = `${process.env.REACT_APP_API_BASE_URL}/reports/${incidentNumber}${createdAtQuery}`;
  return apiGet(url, getUserSession().access_token);
};

export const amendReport = async (report: ReportValue): Promise<void> => {
  const url: string = `${process.env.REACT_APP_API_BASE_URL}/reports/${report.general_information.incident_number}/amend`;

  const fetchResp = await fetch(url, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `bearer ${getUserSession().access_token}`
    }),
    body: JSON.stringify({ ...report })
  });

  if (fetchResp.ok) {
    const fetchData = await fetchResp.json();
    return Promise.resolve(fetchData);
  } else {
    const fetchData = await fetchResp.json();
    return Promise.reject({
      code: fetchResp.status,
      text: fetchResp.statusText,
      detail: fetchData.detail
    });
  }
};

export const getTotals = async (
  filters: ReportListFilters,
  timezone: string
): Promise<ReportsTotals> => {
  const url: string = `${process.env.REACT_APP_API_BASE_URL}/reports/calculate-totals`;
  return apiPost(
    url,
    getFiltersWithIncidentDate(filters, true, timezone),
    getUserSession().access_token
  );
};

export function getFieldConfig(
  value: ReportCustomizationValue,
  fieldName: string,
  sectionName: string,
  subsectionName?: string
): ReportCustomizationFieldValue | undefined {
  const category = value[sectionName];
  if (category) {
    if (subsectionName && category.sections) {
      const subsection = category.sections[subsectionName];
      if (subsection && subsection.fields) {
        return subsection.fields[fieldName];
      }
    } else {
      if (category.fields) {
        return category.fields[fieldName];
      }
    }
  }
}
