import React, { useContext, useMemo } from 'react';
import styles from './Doughnut.module.scss';
import { SFGrey, SFThemeType } from 'sfui';
import { EChartsOption, PieSeriesOption } from 'echarts';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { EChart } from '../../../EChart/EChart';
import { DoughnutDataType } from '../../../../Models/Widget';
import {
  ANALYTICS_DAY_PALETTE,
  ANALYTICS_NIGHT_PALETTE
} from '../../../../Constants/Colors';
import { DoughnutInformation } from './DoughnutInformation/DoughnutInformation';
import { AppearanceContext } from 'ui-smartforce-settings';

function setDataColors(data: DoughnutDataType[]): PieSeriesOption {
  return {
    data: data.map((i) => ({
      name: i.name,
      value: i.value,
      itemStyle: {
        color: i.color
      }
    }))
  };
}

function getChartOption(
  data: DoughnutDataType[],
  themeType: SFThemeType
): EChartsOption {
  const hasColors = !!data[0].color;

  return {
    series: [
      {
        data: hasColors ? setDataColors(data).data : data,
        type: 'pie',
        radius: ['70%', '95%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center',
          color: themeType === 'day' ? SFGrey[900] : SFGrey[50]
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '40',
            fontWeight: 'bold',
            formatter: (params: CallbackDataParams) =>
              `${(Math.round((params.percent || 0) * 100) / 100).toFixed(2)}%`
          }
        },
        labelLine: {
          show: false
        }
      }
    ],
    color: hasColors
      ? undefined
      : themeType === 'day'
      ? ANALYTICS_DAY_PALETTE
      : ANALYTICS_NIGHT_PALETTE
  };
}

export interface DoughnutProps {
  data: DoughnutDataType[];
}

export const Doughnut = ({
  data
}: DoughnutProps): React.ReactElement<DoughnutProps> => {
  const { themeType } = useContext(AppearanceContext);
  const option = useMemo(
    () => getChartOption(data, themeType),
    [data, themeType]
  );

  return (
    <div className={styles.doughnut}>
      <EChart className={styles.image} option={option} />

      {data && <DoughnutInformation data={data} />}
    </div>
  );
};
