import React from 'react';
import {
  AppEnv,
  SFTopBar,
  SFTopBarProps,
  UserContext,
  checkPermissions,
  logout
} from 'ui-smartforce-settings';
import { AGENCY_REPORTS_CREATE } from '../../Constants/RolesAndPermissions';
import { NetworkStatus } from '../NetworkStatus/NetworkStatus';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export interface CCTopBarProps
  extends Omit<
    SFTopBarProps,
    'onLogout' | 'product' | 'enviroment' | 'officerCardUrl'
  > {}

export const CCTopBar = (
  props: CCTopBarProps
): React.ReactElement<CCTopBarProps> => {
  const history = useHistory();
  const { user: oktaUser, logout: oktaLogout } = useAuth0();
  const { user, isOnboarding } = React.useContext(UserContext);

  const showNetworkStatus =
    !isOnboarding &&
    checkPermissions(AGENCY_REPORTS_CREATE, user?.role.permissions);

  const onLogout = () => {
    logout();

    if (oktaUser) {
      oktaLogout({
        logoutParams: {
          returnTo: `${process.env.REACT_APP_BASE_URL}/login`
        }
      });
    } else {
      history.push('/login');
    }
  };

  return (
    <SFTopBar
      {...props}
      officerCardUrl={process.env.REACT_APP_CC_OFFICER_CARD_URL as string}
      enviroment={process.env.REACT_APP_ENV as AppEnv}
      product="cc"
      onLogout={onLogout}
      actions={showNetworkStatus && <NetworkStatus />}
    />
  );
};
