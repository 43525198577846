import React, {
  Fragment,
  useCallback,
  useContext,
  useRef,
  useState
} from 'react';
import styles from './ContactFormView.module.scss';
import FooterButtons from '../FooterButtons/FooterButtons';
import { ContactForm, isValidYear } from './ContactForm/ContactForm';
import {
  checkSectionsFields,
  cloneObject,
  getGeneralFormDefaultValue,
  getUseOfForceDefaultValue,
  checkFutureDay,
  checkFutureTime,
  isDurationValid,
  getUOFValidator,
  isUOFValid,
  isBystanderValid,
  getBystanderValidator
} from '../../../../Helpers';
import {
  ReportValue,
  GeneralInformationValueType,
  FieldValueType,
  SectionValueType,
  ReportConfig,
  UseOfForceValueType,
  ConfigBase,
  ItemValueType,
  UseOfForceConfigType,
  LocationAreaType,
  LocationFormValueType,
  ReportTag
} from '../../../../Models';
import { SFScrollable } from 'sfui';
import moment from 'moment';
import { StateConfigContext } from '../../../../Context/StateConfigContext';
import { UseOfForceForm } from './UseOfForceForm/UseOfForceForm';
import { DurationForm } from './DurationForm/DurationForm';
import { ItemListForm } from './ItemListForm/ItemListForm';
import { Divider } from '../../../../Components/Divider/Divider';
import { SwitchField } from '../FieldInput/SwitchField/SwitchField';
import {
  BYSTANDER_INVALID,
  BYSTANDER_NEEDS_UOF_CITIZEN,
  BYSTANDER_NO_ACTION,
  CITIZENS_MIN_MSG,
  CITIZENS_UOF_MIN_MSG,
  OFFICERS_NEEDS_CITIZEN_MSG,
  OFFICERS_NEEDS_UOF_CITIZEN_MSG
} from '../../../../Constants/Messages';
import { MissingInformationModal } from './MissingInformationModal/MissingInformationModal';
import { ItemListSectionMessage } from './ItemListSection/ItemListSection';
import {
  isFieldEmpty,
  isEqualObject,
  CustomerContext,
  Customer,
  TourTooltip,
  TourContext
} from 'ui-smartforce-settings';
import { TagsForm } from './TagsForm/TagsForm';

const AMEND_TOOLTIP_MSG = `In the "Amend" section, you can make any changes you need to the report. Please note that you must modify at least one field to enable the "Submit" button.`;

export const CitizensContext = React.createContext<ItemValueType[]>([]);

const getDateTime = (date: moment.Moment, time: moment.Moment): string => {
  const dateWithTime: moment.Moment = moment(date);
  dateWithTime.set({
    hour: time.get('hour'),
    minutes: time.get('minutes'),
    second: 0,
    millisecond: 0
  });
  return dateWithTime.toISOString();
};

const isListValid = (config: ConfigBase, list?: ItemValueType[]) => {
  if (!list || list.length === 0) {
    return true;
  }

  for (let item of list) {
    if (!checkSectionsFields(config, item)) {
      return false;
    }
  }

  return true;
};

const isUseOfForceValid = (
  config: UseOfForceConfigType | undefined,
  useOfForceValue: UseOfForceValueType,
  isAmend?: boolean
): boolean => {
  if (!config) {
    return true;
  }

  const { other_agencies_involved, ...useOfForceFields } = useOfForceValue;

  return (
    checkSectionsFields(config, {
      use_of_force: { ...useOfForceFields }
    } as ItemValueType) &&
    (!isAmend ||
      !config.other_agencies_involved ||
      isListValid(config.other_agencies_involved, other_agencies_involved))
  );
};

const isUseOfForceEmpty = (
  useOfForceValue: UseOfForceValueType | undefined
): boolean => {
  if (!useOfForceValue) {
    return true;
  }

  const { other_agencies_involved, ...useOfForceFields } = useOfForceValue;

  return (
    other_agencies_involved.length === 0 &&
    Object.keys(useOfForceFields).length === 0
  );
};

function isLocationFormValid(value: LocationFormValueType): boolean {
  return (
    !isFieldEmpty(value.address?.full) &&
    !isFieldEmpty(value.type) &&
    !isFieldEmpty(value.address.city) &&
    !isFieldEmpty(value.address.zip)
  );
}

const isGeneralInformationValid = (
  value: GeneralInformationValueType,
  config: ConfigBase
): boolean => {
  return (
    !isFieldEmpty(value.date as FieldValueType) &&
    (value.date as moment.Moment).isValid() &&
    isValidYear(value.date as moment.Moment) &&
    !isFieldEmpty(value.time as FieldValueType) &&
    (value.time as moment.Moment).isValid() &&
    !checkFutureDay(value.date as moment.Moment) &&
    !checkFutureTime(
      value.date as moment.Moment,
      value.time as moment.Moment
    ) &&
    !isFieldEmpty(value.duration as FieldValueType) &&
    isDurationValid(value.duration as string) &&
    isLocationFormValid(value.location) &&
    checkSectionsFields(config, value.state_related)
  );
};

const checkFormValid = (
  report: ReportValue,
  config: ReportConfig,
  isAmend?: boolean
): boolean => {
  return (
    report.citizens.length > 0 &&
    isGeneralInformationValid(
      report.general_information,
      config.general_information
    ) &&
    (!report.general_information.was_use_of_force ||
      isUseOfForceValid(
        config.use_of_force,
        report.use_of_force as UseOfForceValueType,
        isAmend
      )) &&
    (!isAmend ||
      (isListValid(config.citizen, report.citizens) &&
        isListValid(config.officer, report.officers) &&
        isListValid(config.complaint, report.complaints) &&
        isListValid(config.investigation, report.investigations)))
  );
};

const initGeneralInfoFromReport = (
  report: ReportValue
): GeneralInformationValueType => ({
  ...cloneObject<GeneralInformationValueType>(report.general_information),
  date: moment(report.general_information.date as string),
  time: moment(report.general_information.date as string)
});

function getGeneralFormInitialValue(
  config: ConfigBase,
  caseNumber?: string,
  areas?: LocationAreaType[]
): GeneralInformationValueType {
  const form: GeneralInformationValueType = getGeneralFormDefaultValue(config);
  if (caseNumber) {
    form.state_related = {
      to_connect_reports: {
        event_identifier: caseNumber
      }
    };
  }

  return {
    ...form,
    location: {
      ...form.location,
      areas: areas
    }
  };
}

export interface ContactFormViewProps {
  caseNumber?: string;
  generalFormAreas?: LocationAreaType[];
  report?: ReportValue;
  deviceDuration: number;
  showSupervision?: boolean;
  onCancel: () => void;
  onSubmit: (contactValue: ReportValue, isTour?: boolean) => void;
}

export const ContactFormView = ({
  caseNumber,
  report,
  generalFormAreas,
  deviceDuration,
  showSupervision = false,
  onCancel,
  onSubmit
}: ContactFormViewProps): React.ReactElement<ContactFormViewProps> => {
  const {
    tour,
    status: tourStatus,
    onNext: onTourNext,
    onEnd: onTourEnd
  } = useContext(TourContext);
  const customer = React.useContext(CustomerContext).customer as Customer;
  const stateName = customer.state_name;

  const stateConfig: ReportConfig = React.useContext(StateConfigContext)
    .stateConfig as ReportConfig;

  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState<boolean>(true);

  const isAmend: boolean = !!report;
  // Use this ref to avoid cloning the object when comparing
  const refReport = React.useRef<ReportValue>();

  const [generalForm, setGeneralForm] =
    React.useState<GeneralInformationValueType>(
      report
        ? initGeneralInfoFromReport(report)
        : getGeneralFormInitialValue(
            stateConfig.general_information,
            caseNumber,
            generalFormAreas
          )
    );

  const [useOfForceForm, setUseOfForceForm] = React.useState<
    UseOfForceValueType | undefined
  >(
    report && report.use_of_force
      ? report.use_of_force
      : getUseOfForceDefaultValue(stateConfig.use_of_force)
  );

  const [citizens, setCitizens] = React.useState<ItemValueType[]>(
    report ? [...report.citizens] : []
  );

  const [complaints, setComplaints] = React.useState<ItemValueType[]>(
    report && report.complaints ? [...report.complaints] : []
  );

  const [investigations, setInvestigations] = React.useState<ItemValueType[]>(
    report && report.investigations ? [...report.investigations] : []
  );

  const [officers, setOfficers] = React.useState<ItemValueType[]>(
    report && report.officers ? [...report.officers] : []
  );

  const [agencyTags, setAgencyTags] = React.useState<ReportTag[]>(
    report?.tags?.agency ? [...report.tags.agency] : []
  );

  const [isMissingInfoModalOpen, setIsMissingInfoModalOpen] =
    React.useState<boolean>(false);

  const wasSubmittedRef = useRef<boolean>(false);
  const refIsSubmitPristine = useRef<boolean>(true);

  const [validators, setValidators] = useState({
    uof: getUOFValidator(
      stateConfig.validations?.use_of_force,
      report && report.citizens ? report.citizens : [],
      report && report.officers ? report.officers : []
    ),
    bystander: getBystanderValidator(
      stateName,
      report && report.citizens ? report.citizens : []
    )
  });

  React.useEffect(() => {
    if (refIsSubmitPristine.current && !isSubmitDisabled) {
      refIsSubmitPristine.current = false;
      if (tour?.id === 6) {
        onTourNext({ tourId: 6, step: 2 });
      } else if (tour?.id === 10) {
        onTourNext({ tourId: 10, step: 2 });
      }
    }
  }, [isSubmitDisabled, tour, onTourNext]);

  // Update ref if report prop changes
  React.useEffect(() => {
    if (report) {
      refReport.current = {
        general_information: initGeneralInfoFromReport(report),
        citizens: [...report.citizens],
        use_of_force: report.use_of_force
          ? { ...report.use_of_force }
          : getUseOfForceDefaultValue(stateConfig.use_of_force),
        complaints: report.complaints ? [...report.complaints] : [],
        investigations: report.investigations ? [...report.investigations] : [],
        officers: report.officers ? [...report.officers] : [],
        tags: report.tags
      };
    }
  }, [report, stateConfig]);

  // Validate form on field change to disable submit button
  React.useEffect(() => {
    let currentValue: ReportValue = {
      general_information: generalForm,
      citizens: citizens,
      use_of_force: useOfForceForm,
      complaints: complaints,
      investigations: investigations,
      officers: officers
    };

    if (agencyTags.length > 0) {
      currentValue.tags = {
        agency: agencyTags
      };
    }

    let isValid: boolean = checkFormValid(currentValue, stateConfig, isAmend);

    // When amend, disabled submit if there isn't changes
    if (isValid && isAmend && refReport.current) {
      isValid = !isEqualObject<ReportValue>(refReport.current, currentValue);
    }

    setIsSubmitDisabled(!isValid);
  }, [
    generalForm,
    citizens,
    isAmend,
    stateConfig,
    useOfForceForm,
    complaints,
    investigations,
    officers,
    agencyTags
  ]);

  // Update general information form when field change
  const onContactFieldChange = useCallback(
    (
      name: string,
      value: FieldValueType | SectionValueType,
      isStateRelated: boolean
    ): void => {
      setGeneralForm((form: GeneralInformationValueType) => {
        const newForm = { ...form };

        if (isStateRelated) {
          newForm.state_related[name] = value as SectionValueType;
        } else {
          newForm[name] = value as FieldValueType;
        }

        return newForm;
      });

      // If the field is "was_use_of_force" and it's value is now false
      // reset the uof form
      if (name === 'was_use_of_force' && !value) {
        setUseOfForceForm(getUseOfForceDefaultValue(stateConfig.use_of_force));
      }
    },
    [stateConfig.use_of_force]
  );

  // Set "was_use_of_force" value if needed
  React.useEffect(() => {
    if (validators.uof.isActive && validators.uof.areCitizensWithUOF) {
      onContactFieldChange('was_use_of_force', true, false);
    }
  }, [validators, onContactFieldChange]);

  const onCitizensChange = (newCitizens: ItemValueType[]): void => {
    setValidators({
      uof: getUOFValidator(
        stateConfig.validations?.use_of_force,
        newCitizens,
        officers
      ),
      bystander: getBystanderValidator(stateName, newCitizens)
    });
    setCitizens(newCitizens);
  };

  const onUseOfForceChange = (newUseOfForce: UseOfForceValueType): void => {
    setUseOfForceForm(newUseOfForce);
  };

  const onComplaintsChange = (newComplaints: ItemValueType[]): void => {
    setComplaints(newComplaints);
  };

  const onInvestigationsChange = (newInvestigations: ItemValueType[]): void => {
    setInvestigations(newInvestigations);
  };

  const onOfficersChange = (newOfficers: ItemValueType[]) => {
    setValidators((prev) => ({
      ...prev,
      uof: getUOFValidator(
        stateConfig.validations?.use_of_force,
        citizens,
        newOfficers
      )
    }));
    setOfficers(newOfficers);
  };

  const onClickSubmit = () => {
    wasSubmittedRef.current = true;
    if (
      !isUOFValid(validators.uof, !!generalForm.was_use_of_force) ||
      !isBystanderValid(validators.bystander)
    ) {
      setIsMissingInfoModalOpen(true);
    } else {
      let formSubmit: ReportValue = {
        general_information: {
          ...generalForm,
          date: getDateTime(
            generalForm.date as moment.Moment,
            generalForm.time as moment.Moment
          )
        },
        citizens: [...citizens]
      };

      if (!isUseOfForceEmpty(useOfForceForm)) {
        formSubmit.use_of_force = useOfForceForm;
      }

      if (isAmend) {
        formSubmit.complaints = complaints;
        formSubmit.investigations = investigations;
      }

      formSubmit.officers = officers;

      if (agencyTags.length > 0) {
        formSubmit.tags = {
          agency: agencyTags.map((t) => ({
            id: t.id,
            name: t.name,
            color: t.color
          }))
        };
      }

      delete formSubmit.general_information['time'];

      onTourEnd();
      onSubmit(formSubmit, tourStatus === 'active');
    }
  };

  const getCitizensMessage = (): ItemListSectionMessage | undefined => {
    if (citizens.length === 0) {
      return { value: CITIZENS_MIN_MSG, color: 'neutral' };
    } else if (
      wasSubmittedRef.current &&
      validators.uof.isActive &&
      generalForm.was_use_of_force &&
      !validators.uof.areCitizensWithUOF
    ) {
      return { value: CITIZENS_UOF_MIN_MSG, color: 'error' };
    }
  };

  const getOfficersMessage = (): ItemListSectionMessage | undefined => {
    if (citizens.length === 0) {
      return { value: OFFICERS_NEEDS_CITIZEN_MSG, color: 'neutral' };
    } else if (
      validators.uof.isActive &&
      generalForm.was_use_of_force &&
      !validators.uof.areOfficersLinked
    ) {
      return {
        value: OFFICERS_NEEDS_UOF_CITIZEN_MSG,
        color: wasSubmittedRef.current ? 'error' : 'neutral'
      };
    }
  };

  const getMissingInfoMessages = (): string[] => {
    let messages = [];
    if (validators.uof.isActive && generalForm.was_use_of_force) {
      if (!validators.uof.areCitizensWithUOF) {
        messages.push(CITIZENS_UOF_MIN_MSG);
      }

      if (!validators.uof.areOfficersLinked) {
        messages.push(OFFICERS_NEEDS_UOF_CITIZEN_MSG);
      }
    }

    if (validators.bystander.isActive) {
      if (!validators.bystander.isCitizenWithUOF) {
        messages.push(BYSTANDER_NEEDS_UOF_CITIZEN);
      }

      if (!validators.bystander.areBystanderValid) {
        messages.push(BYSTANDER_INVALID);
      }

      if (!validators.bystander.areBystanderNoAction) {
        messages.push(BYSTANDER_NO_ACTION);
      }
    }

    return messages;
  };

  return (
    <div className={`${styles.contactFormView} ${styles.withFooterButtons}`}>
      <MissingInformationModal
        isOpen={isMissingInfoModalOpen}
        messages={getMissingInfoMessages()}
        onClose={() => setIsMissingInfoModalOpen(false)}
      />

      <SFScrollable
        className={styles.scrollable}
        containerClassName={styles.scrollableContainer}
      >
        <CitizensContext.Provider value={citizens}>
          <div className={styles.content}>
            {!isAmend && (
              <h2 className={styles.title}>Complete Contact form</h2>
            )}

            {isAmend && tour?.id === 6 && (
              <TourTooltip
                title="“Amend” section"
                description={AMEND_TOOLTIP_MSG}
                step={2}
                lastStep={3}
                tourId={6}
                placement="bottom-start"
                preventOverflow
              >
                <h2 className={styles.title}>Amend Contact form</h2>
              </TourTooltip>
            )}

            {isAmend && tour?.id === 10 && (
              <TourTooltip
                title="“Amend” section"
                description={AMEND_TOOLTIP_MSG}
                step={2}
                lastStep={3}
                tourId={10}
                placement="bottom-start"
                preventOverflow
              >
                <h2 className={styles.title}>Amend Contact form</h2>
              </TourTooltip>
            )}

            <div className={styles.form}>
              <div>
                <ItemListForm
                  name="citizen"
                  className={styles.citizenSection}
                  config={stateConfig.citizen}
                  itemLabel="Citizen"
                  message={getCitizensMessage()}
                  itemListValue={citizens}
                  onChange={onCitizensChange}
                />

                <ContactForm
                  value={generalForm}
                  onDefaultFieldChange={(name: string, value: FieldValueType) =>
                    onContactFieldChange(name, value, false)
                  }
                  onSectionChange={(name: string, value: SectionValueType) =>
                    onContactFieldChange(name, value, true)
                  }
                />
              </div>

              <Divider />

              <div className={styles.useOfForce}>
                <div className={styles.useOfForceSwitch}>
                  <h3>Use of Force</h3>

                  <SwitchField
                    label="Was Use of Force Applied?"
                    value={generalForm.was_use_of_force as boolean}
                    disabled={
                      validators.uof.isActive &&
                      validators.uof.areCitizensWithUOF
                    }
                    onChange={(value: FieldValueType) =>
                      onContactFieldChange('was_use_of_force', value, false)
                    }
                  />
                </div>

                {stateConfig.use_of_force && generalForm.was_use_of_force && (
                  <UseOfForceForm
                    config={stateConfig.use_of_force}
                    value={useOfForceForm as UseOfForceValueType}
                    onChange={onUseOfForceChange}
                  />
                )}
              </div>

              <Divider />

              <ItemListForm
                name="officer"
                config={stateConfig.officer}
                itemLabel="Officer"
                itemListValue={officers}
                onChange={onOfficersChange}
                disabled={citizens.length === 0}
                message={getOfficersMessage()}
              />

              <Divider />

              <TagsForm
                value={agencyTags}
                onChange={(tags: ReportTag[]) => setAgencyTags(tags)}
              />

              <Divider />

              <DurationForm
                value={generalForm.duration as string}
                duration={deviceDuration}
                onChange={(value: string) =>
                  onContactFieldChange('duration', value, false)
                }
              />

              {showSupervision && (
                <Fragment>
                  <Divider />

                  <ItemListForm
                    name="complaint"
                    config={stateConfig.complaint}
                    itemLabel="Complaint"
                    itemListValue={complaints}
                    onChange={onComplaintsChange}
                  />

                  <ItemListForm
                    name="investigation"
                    config={stateConfig.investigation}
                    itemLabel="Investigation"
                    itemListValue={investigations}
                    onChange={onInvestigationsChange}
                  />
                </Fragment>
              )}
            </div>
          </div>
        </CitizensContext.Provider>
      </SFScrollable>

      <footer>
        <FooterButtons
          isAmend={isAmend}
          onCancel={onCancel}
          buttonAction={{
            label: 'Submit',
            disabled: isSubmitDisabled,
            onClick: onClickSubmit
          }}
        />
      </footer>
    </div>
  );
};
