import React, { useMemo } from 'react';
import styles from './GradientLine.module.scss';
import {
  SFGrey,
  SFScrollable,
  SFText,
  SFTextBlack,
  SFTextWhite,
  SFThemeType
} from 'sfui';
import * as echarts from 'echarts/core';
import { EChartsOption } from 'echarts';
import { EChart } from '../../../EChart/EChart';
import { AppearanceContext } from 'ui-smartforce-settings';
import { AnalyticsPeriodType } from '../../../../Models';

function getLinearGradient(themeType: SFThemeType) {
  return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: `rgba(13, 105, 242, ${themeType === 'day' ? '0.3' : '0.4'})`
    },
    {
      offset: 1,
      color: 'rgba(13, 105, 242, 0)'
    }
  ]);
}

function getChartOption(
  categories: string[],
  data: number[],
  themeType: SFThemeType,
  data_label: string
): EChartsOption {
  return {
    grid: {
      top: 10,
      bottom: 30,
      right: 46,
      left: 66
    },
    xAxis: {
      data: categories,
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        margin: 18,
        color: themeType === 'day' ? SFGrey[600] : SFGrey[400]
      },
      axisTick: {
        show: false
      },
      axisLine: {
        lineStyle: {
          color: themeType === 'day' ? SFGrey[200] : SFGrey[700],
          width: 1
        }
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        margin: 12,
        color: themeType === 'day' ? SFGrey[600] : SFGrey[400]
      },
      splitLine: {
        lineStyle: {
          color: themeType === 'day' ? SFGrey[100] : SFGrey[800],
          type: [6, 4]
        }
      }
    },
    series: [
      {
        data: data,
        type: 'line',
        symbol: 'circle',
        symbolSize: 8,
        lineStyle: {
          color: themeType === 'day' ? '#006cf3' : '#006cec',
          width: 4,
          type: 'solid'
        },
        itemStyle: {
          borderWidth: 2,
          borderColor: themeType === 'day' ? 'white' : '#1f1f1f',
          color: themeType === 'day' ? '#006cf3' : '#006cec'
        },
        areaStyle: {
          color: getLinearGradient(themeType)
        },
        emphasis: {
          scale: 1.5
        }
      }
    ],
    tooltip: {
      show: true,
      trigger: 'axis',
      className: 'gradientLineTooltip',
      padding: 12,
      backgroundColor: themeType === 'day' ? '#333' : '#CCC',
      formatter: `{c0} ${data_label}`,
      textStyle: {
        color: themeType === 'day' ? SFTextWhite : SFTextBlack,
        fontWeight: 500,
        fontFamily: 'Roboto',
        fontSize: '13px',
        lineHeight: 22
      }
    }
  };
}

function getLegend(periodType: AnalyticsPeriodType): string {
  if (periodType === 'year') {
    return 'Months';
  } else if (periodType === 'half_year') {
    return 'Fortnights';
  } else if (periodType === 'quarter') {
    return 'Weeks';
  } else {
    return 'Dates';
  }
}
export interface GradientLineProps {
  categories: string[];
  data: number[];
  data_label: string;
  periodType: AnalyticsPeriodType;
}

export const GradientLine = ({
  categories,
  data,
  data_label,
  periodType
}: GradientLineProps): React.ReactElement<GradientLineProps> => {
  const { themeType } = React.useContext(AppearanceContext);

  const option = useMemo(
    () => getChartOption(categories, data, themeType, data_label),
    [categories, data, themeType, data_label]
  );

  return (
    <SFScrollable
      className={styles.scrollable}
      containerClassName={styles.gradientLine}
    >
      <EChart className={styles.echart} option={option} />

      <div className={styles.downloadFooter}>
        <SFText type="component-messages" sfColor="neutral">
          {getLegend(periodType)}
        </SFText>
      </div>
    </SFScrollable>
  );
};
