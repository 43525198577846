import React from 'react';
import styles from './AnalyticsRequestTimeout.module.scss';
import { SFButton, SFIcon, SFText } from 'sfui';

export interface AnalyticsRequestTimeoutProps {
  onTryLater: () => void;
}

export const AnalyticsRequestTimeout = (
  props: AnalyticsRequestTimeoutProps
): React.ReactElement<AnalyticsRequestTimeoutProps> => {
  return (
    <div className={styles.analyticsRequestTimeout}>
      <div className={styles.content}>
        <SFIcon className={styles.icon} icon="Time-App" size={96} />

        <h2 className={styles.title}>The connection has expired.</h2>
        <SFText
          type="component-2"
          sfColor="neutral"
          className={styles.subtitle}
        >
          The process is taking a little longer than usual.
          <br />
          Please try again later.
        </SFText>

        <SFButton
          className={styles.button}
          sfColor="blue"
          variant="contained"
          size="large"
          onClick={props.onTryLater}
        >
          Try Later
        </SFButton>
      </div>
    </div>
  );
};
