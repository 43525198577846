import React from 'react';
import styles from './FilterSelector.module.scss';
import { SFCheckbox, SFScrollable } from 'sfui';
import { FilterSelectorItem } from './FilterSelectorItem/FilterSelectorItem';
import { FilterMapOption } from '../../../../../Models';

export interface FilterSelectorProps {
  className?: string;
  options: FilterMapOption[];
  value: string[];
  onChange: (value: string[]) => void;
}

export const FilterSelector = ({
  className = '',
  options,
  value,
  onChange
}: FilterSelectorProps): React.ReactElement<FilterSelectorProps> => {
  const checkAll: boolean = value.length === options.length;

  const onCheckAll = (checked: boolean) => {
    if (checked) {
      onChange(options.map((option: FilterMapOption) => option.label));
    } else {
      onChange([]);
    }
  };

  const onItemChange = (option: FilterMapOption, checked: boolean) => {
    if (checked) {
      onChange([...value, option.label]);
    } else {
      const index = value.indexOf(option.label);
      onChange([
        ...value.slice(0, index),
        ...value.slice(index + 1, value.length)
      ]);
    }
  };

  return (
    <div className={`${styles.filterSelector} ${className}`}>
      <div className={styles.checkboxHeader}>
        <span className={styles.title}>FILTER BY</span>
        <SFCheckbox
          checked={checkAll}
          onChange={(_e, checked) => onCheckAll(checked)}
          role="checkbox"
          aria-label="All"
          aria-checked={checkAll}
        />
      </div>

      <SFScrollable className={styles.scrollable}>
        {options.map((option: FilterMapOption) => (
          <FilterSelectorItem
            key={`filter-selector-option-${option.label}`}
            option={option}
            checked={value.indexOf(option.label) !== -1}
            onChange={(checked: boolean) => onItemChange(option, checked)}
          />
        ))}
      </SFScrollable>
    </div>
  );
};
