import React, { useContext } from 'react';
import styles from './TinyWidgets.module.scss';
import { SFCollapse, SFIcon } from 'sfui';
import {
  InteractiveBox,
  MediaContext,
  TourContext,
  TourTooltip
} from 'ui-smartforce-settings';
import { ReportsTotals } from '../../../../Models';
import { TinyWidgetsContent } from './TinyWidgetsContent/TinyWidgetsContent';

const COLLAPSED_SIZE: number = 80;

export interface TinyWidgetsProps {
  totals: ReportsTotals;
  contactTypeFilter?: string;
}

export const TinyWidgets = ({
  totals,
  contactTypeFilter
}: TinyWidgetsProps): React.ReactElement<TinyWidgetsProps> => {
  const { isMediumScreen } = React.useContext(MediaContext);
  const { onEnd: onTourEnd, setIsFeatureReminderOpen } =
    useContext(TourContext);

  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

  const onCollapseButtonClick = (): void => {
    setIsExpanded((open) => !open);
  };

  const onTourGotIt = () => {
    onTourEnd();
    setIsFeatureReminderOpen(true);
  };

  return (
    <div className={styles.tinyWidgets}>
      <div className={styles.container}>
        <SFCollapse in={isExpanded} exit collapsedSize={COLLAPSED_SIZE}>
          <TourTooltip
            title="FastFacts"
            description="FastFacts is a group of widgets whose values change based on the filters you choose. Using this information you can stay informed about your agency's activities."
            step={1}
            lastStep={1}
            tourId={8}
            preventOverflow
            placement="bottom"
            onGotIt={onTourGotIt}
          >
            <TinyWidgetsContent
              totals={totals}
              contactTypeFilter={contactTypeFilter}
            />
          </TourTooltip>
        </SFCollapse>
      </div>

      {!isMediumScreen && (
        <InteractiveBox
          className={styles.collapseButton}
          onClick={onCollapseButtonClick}
          role="button"
          aria-label={`${isExpanded ? 'Collapse' : 'Expand'} Fast Facts`}
        >
          <SFIcon icon={isExpanded ? 'Up-2' : 'Down-2'} />
        </InteractiveBox>
      )}
    </div>
  );
};
