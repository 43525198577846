import { Tour } from 'ui-smartforce-settings';

export interface TourPlanConfig {
  name: string;
  circuits: number[];
}

export interface TourRoleConfig {
  role: string;
  plans: TourPlanConfig[];
}

export const TOURS: Tour[] = [
  {
    id: 1,
    title: 'Invite Officers',
    description: 'Empower your agency by inviting officers.',
    link: 'https://www.youtube.com/embed/z3HRdDPTceI'
  },
  {
    id: 2,
    title: 'Add Managers',
    description: 'Increase agency leadership by adding managers.',
    link: 'https://www.youtube.com/embed/Kw2O6QFMHUE'
  },
  {
    id: 3,
    title: 'Billing',
    description: 'Manage your agency plan and billing.',
    link: 'https://www.youtube.com/embed/RvVS2PYNhuc'
  },
  {
    id: 4,
    title: 'Business Card',
    description: 'Personalize your digital business card.',
    link: 'https://www.youtube.com/embed/A_T2h-sbQcs'
  },
  {
    id: 5,
    title: 'Agency Areas',
    description: 'Define agency areas by dragging and dropping.',
    link: 'https://www.youtube.com/embed/8-5NX-YONOM'
  },
  {
    id: 6,
    title: 'Amend officers reports',
    description: 'Perfect your agency reports by easily correcting them.',
    link: 'https://www.youtube.com/embed/5QJGZuE3Wwg'
  },
  {
    id: 7,
    title: 'Advance filtering',
    description: 'Explore a multitude of search options.',
    link: 'https://www.youtube.com/embed/CZ3T9xBmm-I'
  },
  {
    id: 8,
    title: 'FastFacts',
    description:
      "Gain clarity by staying informed about your agency's activities.",
    link: 'https://www.youtube.com/embed/GCjyxl5gbQA'
  },
  {
    id: 9,
    title: 'Create Groups',
    description: "Shape your agency's groups.",
    link: 'https://www.youtube.com/embed/41kPI8yjp8E'
  },
  {
    id: 10,
    title: 'Amend my reports',
    description: 'Perfect your reports by easily correcting them.',
    link: 'https://www.youtube.com/embed/n2SSk8oJyfQ'
  }
];

export const TOURS_CONFIG: TourRoleConfig[] = [
  {
    role: 'owner',
    plans: [
      {
        name: 'analytics',
        circuits: [9, 5, 6, 7, 8, 1, 2, 3, 4]
      },
      {
        name: 'basic',
        circuits: [9, 5, 6, 1, 2, 3, 4]
      },
      {
        name: 'connect',
        circuits: [1, 2, 3, 9]
      }
    ]
  },
  {
    role: 'manager',
    plans: [
      {
        name: 'analytics',
        circuits: [9, 5, 6, 7, 8, 1, 4]
      },
      {
        name: 'basic',
        circuits: [9, 5, 6, 1, 4]
      },
      {
        name: 'connect',
        circuits: [1, 4]
      }
    ]
  },
  {
    role: 'officer',
    plans: [
      {
        name: 'analytics',
        circuits: [4, 10]
      },
      {
        name: 'basic',
        circuits: [4, 10]
      },
      {
        name: 'connect',
        circuits: [4]
      }
    ]
  }
];
