import React from 'react';
import { SFChip } from 'sfui';
import {
  ANALYTICS_DAY_PALETTE,
  ANALYTICS_NIGHT_PALETTE
} from '../../../../../../Constants/Colors';
import styles from './DoughnutItem.module.scss';
import { AppearanceContext } from 'ui-smartforce-settings';

export interface DoughnutItemProps {
  index: number;
  name: string;
  value: number;
  percentage: string;
  color?: string;
}

export const DoughnutItem = ({
  index,
  name,
  value,
  percentage,
  color
}: DoughnutItemProps): React.ReactElement<DoughnutItemProps> => {
  const { themeType } = React.useContext(AppearanceContext);

  return (
    <div className={styles.doughnutItem} key={name}>
      <div className={styles.label}>
        <div
          style={{
            backgroundColor: color
              ? color
              : themeType === 'day'
              ? ANALYTICS_DAY_PALETTE[index % ANALYTICS_DAY_PALETTE.length]
              : ANALYTICS_NIGHT_PALETTE[index % ANALYTICS_NIGHT_PALETTE.length]
          }}
          className={styles.color}
        ></div>

        <span className={styles.text}>{name}</span>
      </div>

      <div className={styles.value}>
        <span className={styles.text}>{value}</span>
        <SFChip size="small" sfColor="default" label={`${percentage}%`} />
      </div>
    </div>
  );
};
