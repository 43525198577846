import React from 'react';
import styles from './TagsInformation.module.scss';
import { SFChip } from 'sfui';
import { ReportTag } from '../../../../../Models';

export interface TagsInformationProps {
  tags: ReportTag[];
}

export const TagsInformation = ({
  tags
}: TagsInformationProps): React.ReactElement<TagsInformationProps> => {
  return (
    <div className={styles.tagsInformation}>
      <h3>Tags</h3>

      <div className={styles.tags}>
        <h4>Agency Tags</h4>
        <div className={styles.chips}>
          {tags.map((t) => (
            <SFChip
              key={t.id}
              size="small"
              variant="outlined"
              sfColor={t.color}
              label={t.name}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
