import React from 'react';
import styles from './WidgetCardHeader.module.scss';
import {
  SFChip,
  SFDivider,
  SFIcon,
  SFIconButton,
  SFMenu,
  SFMenuItem,
  SFTooltip
} from 'sfui';
import { TextOverflowTooltip } from '../../TextOverflowTooltip/TextOverflowTooltip';
import { InteractiveBox, MediaContext } from 'ui-smartforce-settings';
import { isMobile } from '../../../Helpers';

export interface WidgetCardHeaderMenuOption {
  label: string;
  onClick: () => void;
}

export interface WidgetCardHeaderProps {
  title: string;
  isActive?: boolean;
  drawerMenuOption?: WidgetCardHeaderMenuOption;
  infoMessage?: string;
  isMaximize?: boolean;
  onToggleMaximize?: (isMaximize: boolean) => void;
  onAdd?: () => void;
  onRemove?: () => void;
  onShare?: () => void;
}

export const WidgetCardHeader = ({
  title,
  isActive = false,
  drawerMenuOption,
  infoMessage,
  isMaximize,
  onToggleMaximize,
  onAdd,
  onRemove,
  onShare
}: WidgetCardHeaderProps): React.ReactElement<WidgetCardHeaderProps> => {
  const { isPhone } = React.useContext(MediaContext);
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<any>();
  const [isTooltipOpen, setIsTooltipOpen] = React.useState<boolean>(false);

  const onInfoClose = () => {
    if (isMobile()) {
      setIsTooltipOpen(false);
    }
  };

  const onInfoClick = () => {
    if (isMobile() && !isTooltipOpen) {
      setIsTooltipOpen(true);
    }
  };

  const onClick = (e: any) => {
    if (!isMenuOpen) {
      setAnchorEl(e.currentTarget);
      setIsMenuOpen(true);
    }
  };

  return (
    <div className={styles.widgetCardHeader}>
      <div className={styles.title}>
        <TextOverflowTooltip title={title}>
          <h3>{title}</h3>
        </TextOverflowTooltip>

        {infoMessage && (
          <SFTooltip
            classes={{
              popper: styles.limitedResponseTooltip
            }}
            title={infoMessage}
            placement={isPhone ? 'bottom' : 'bottom-start'}
            open={isMobile() ? isTooltipOpen : undefined}
            leaveTouchDelay={3500}
            onClose={onInfoClose}
            disableHoverListener={isMobile()}
          >
            <InteractiveBox className={styles.icon} onClick={onInfoClick}>
              <SFIcon icon="Information" size={14} />
            </InteractiveBox>
          </SFTooltip>
        )}
      </div>

      <div className={styles.actions}>
        {!isMaximize && onToggleMaximize && (
          <SFIconButton
            aria-label="Maximize"
            sfIcon="Maximize-1"
            sfSize="small"
            onClick={() => onToggleMaximize(true)}
          />
        )}

        {isMaximize && onToggleMaximize && (
          <SFIconButton
            aria-label="Minimize"
            sfIcon="Minimize-1"
            sfSize="small"
            onClick={() => onToggleMaximize(false)}
          />
        )}

        <SFIconButton
          sfIcon="Other"
          sfSize="small"
          rotate="right"
          onClick={onClick}
          aria-label="More Options"
        />
      </div>

      <SFMenu
        MenuListProps={{ className: styles.menu }}
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={() => setIsMenuOpen(false)}
      >
        {drawerMenuOption && (
          <SFMenuItem
            onClick={() => {
              drawerMenuOption.onClick();
              setIsMenuOpen(false);
            }}
          >
            {drawerMenuOption.label}
          </SFMenuItem>
        )}

        <SFMenuItem
          disabled={!onShare}
          className={styles.shareItem}
          onClick={() => {
            setIsMenuOpen(false);
            onShare && onShare();
          }}
        >
          Download
          <SFChip sfColor="default" label="BETA" />
        </SFMenuItem>

        <SFDivider />

        {!isActive && <SFMenuItem onClick={onAdd}>Add</SFMenuItem>}
        {isActive && <SFMenuItem onClick={onRemove}>Remove</SFMenuItem>}
      </SFMenu>
    </div>
  );
};
