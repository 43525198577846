import React from 'react';
import { SFButton, SFCard, SFIcon, SFText } from 'sfui';
import { DottedArrowIcon } from '../../../Components/DottedArrowIcon/DottedArrowIcon';
import styles from './PricingInformation.module.scss';
import { useHistory } from 'react-router-dom';

export interface PriceInformation {
  cost: string;
  basis?: string;
  description?: string;
}

type PricingInformationColorType = 'blue' | 'purple' | 'default';

export interface PricingInformationProps {
  plan: string;
  color: PricingInformationColorType;
  price: PriceInformation;
  features: string[];
  featuresTitle?: string;
  selectPlanVariant: 'outlined' | 'contained';
}

export const PricingInformation = ({
  plan,
  color,
  price,
  features,
  featuresTitle,
  selectPlanVariant
}: PricingInformationProps): React.ReactElement<PricingInformationProps> => {
  const history = useHistory();

  const onSelectPlan = () => history.push('/registration');

  return (
    <SFCard
      sfElevation={12}
      className={`${styles.pricingInformation} ${styles[color]}`}
      containerClassName={styles.pricingContainer}
    >
      <div className={styles.title}>
        <DottedArrowIcon className={styles.arrowIcon} />
        <h4 className={styles.plan}>{plan}</h4>
      </div>
      <div className={styles.price}>
        <div className={styles.cost}>
          <h1>{price.cost}</h1>
          {price.basis && <p className={styles.basis}>{price.basis}</p>}
        </div>

        <SFText className={styles.description} type="component-2">
          {price.description ?? 'Annual Plan'}
        </SFText>
      </div>

      <SFButton variant={selectPlanVariant} onClick={onSelectPlan}>
        Select Plan
      </SFButton>

      <div className={styles.featuresContainer}>
        {featuresTitle && (
          <h4 className={styles.featuresTitle}>{featuresTitle}</h4>
        )}
        <ul>
          {features.map((feature: string, index: number) => (
            <li key={`feature-${index}`}>
              <SFIcon size={14} icon="Check-1" />
              <SFText type="component-1">{feature}</SFText>
            </li>
          ))}
        </ul>
      </div>
    </SFCard>
  );
};
