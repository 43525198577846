import React, { useContext } from 'react';
import styles from './SFNavContent.module.scss';
import { SFIconButton, SFPaper, SFScrollable } from 'sfui';
import SFNavContentLink from './SFNavContentLink/SFNavContentLink';
import { SFNavLink, SFNavLinkRoute } from '../SFNavLink';
import { OnlineStatusContext } from '../../../Context/OnlineStatusContext';
import { useLocation } from 'react-router-dom';
import { isOfflinePath } from '../../../Helpers';
import { ReminderProps, SFLogo } from 'ui-smartforce-settings';
import { SFNavContentBrand } from './SFNavContentBrand/SFNavContentBrand';
import ContentLinkWithReminder from './ContentLinkWithReminder/ContentLinkWithReminder';

export interface SFNavContentProps {
  isPanel: boolean;
  links: SFNavLink[];
  selected: SFNavLinkRoute;
  isCollapsed?: boolean;
  reminderProps: ReminderProps;
  onToggleCollapse?: () => void;
  onLinkChange: (
    e: React.MouseEvent<HTMLAnchorElement>,
    link: SFNavLinkRoute
  ) => void;
  onClose?: () => void;
}

const SFNavContent = ({
  isPanel,
  links,
  selected,
  isCollapsed = false,
  reminderProps,
  onToggleCollapse,
  onLinkChange,
  onClose
}: SFNavContentProps): React.ReactElement<SFNavContentProps> => {
  const { isOnline } = useContext(OnlineStatusContext);
  const location = useLocation();

  const onClick = (link: SFNavLink) => {
    link.type === 'event' && link.onClick();
    onClose && onClose();
  };

  return (
    <SFPaper
      className={`${styles.sfNavContent} ${
        isCollapsed ? styles.isCollapsed : ''
      }`}
    >
      {!isPanel && (
        <div
          className={`${styles.logo} ${
            isCollapsed ? styles.logoCollapsed : ''
          }`}
        >
          {!isCollapsed && <SFLogo />}
          <SFIconButton
            sfIcon={isCollapsed ? 'Menu-Expanded' : 'Menu-Collapsed'}
            sfSize="medium"
            onClick={onToggleCollapse}
          />
        </div>
      )}

      <div className={styles.content}>
        <SFScrollable
          className={styles.scrollable}
          containerClassName={styles.scrollableContainer}
        >
          <ul>
            {links
              .filter((link: SFNavLink) => !link.bottomContent)
              .filter(
                (link: SFNavLink) =>
                  !link.temporal ||
                  (link.type === 'route_internal' &&
                    location.pathname === link.to)
              )
              .map((link: SFNavLink) => (
                <li key={`link-${link.label}`}>
                  <SFNavContentLink
                    link={link}
                    selected={
                      link.type === 'route_internal' && link.to === selected.to
                    }
                    disabled={
                      !isOnline &&
                      link.type === 'route_internal' &&
                      !isOfflinePath(link.to)
                    }
                    onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                      onLinkChange(e, link as SFNavLinkRoute)
                    }
                  />
                </li>
              ))}
          </ul>
          <div className={styles.settings}>
            <ul>
              {links
                .filter((link: SFNavLink) => link.bottomContent)
                .map((link: SFNavLink) => {
                  if (link.label === 'Feature Tours') {
                    return (
                      <li key={`link-${link.label}`}>
                        <ContentLinkWithReminder
                          disabled={!isOnline}
                          link={link}
                          selected={false}
                          onClick={() => onClick(link)}
                          tooltipProps={{
                            open:
                              reminderProps.type === 'tours' &&
                              reminderProps.open,
                            type: 'tours',
                            onGotIt: reminderProps.onGotIt
                          }}
                        />
                      </li>
                    );
                  } else if (link.label === 'Feedback') {
                    return (
                      <li key={`link-${link.label}`}>
                        <ContentLinkWithReminder
                          disabled={!isOnline}
                          link={link}
                          selected={false}
                          onClick={() => onClick(link)}
                          tooltipProps={{
                            open:
                              reminderProps.type === 'surveys' &&
                              reminderProps.open,
                            type: 'surveys',
                            onGotIt: reminderProps.onGotIt
                          }}
                        />
                      </li>
                    );
                  } else {
                    return (
                      <li key={`link-${link.label}`}>
                        <SFNavContentLink
                          disabled={!isOnline}
                          link={link}
                          selected={
                            link.type === 'route_internal' &&
                            link.to === selected.to
                          }
                          onClick={
                            link.type === 'event'
                              ? () => onClick(link)
                              : (e: React.MouseEvent<HTMLAnchorElement>) =>
                                  onLinkChange(e, link)
                          }
                        />
                      </li>
                    );
                  }
                })}
            </ul>

            <SFNavContentBrand isOnline={isOnline} isCollapsed={isCollapsed} />
          </div>
        </SFScrollable>
      </div>
    </SFPaper>
  );
};

export default SFNavContent;
