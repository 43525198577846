import React, { Fragment } from 'react';
import styles from './TinyWidgetsContent.module.scss';
import { ReportsTotals } from '../../../../../Models';
import { TinyWidget } from '../TinyWidget/TinyWidget';
import { Divider } from '../../../../../Components/Divider/Divider';
import { MediaContext } from 'ui-smartforce-settings';

const PHONE_COLUMNS = 2;
const SMALL_COLUMNS = 3;

function getTitle(key: string): string {
  if (key === 'uof') return 'Use of Force';
  else if (key === 'cfs') return 'Call for Service';
  else if (key === 'opa') return 'Officer Pro-active';
  else if (key === 'complaints') return 'Complaints';
  else if (key === 'consent_searches') return 'Consent Searches';
  else return 'Total Reports';
}

function isWidgetDisabled(
  totalsKey: string,
  contactTypeFilter?: string
): boolean {
  return (
    (totalsKey === 'cfs' && contactTypeFilter === 'Officer Pro-active') ||
    (totalsKey === 'opa' && contactTypeFilter === 'Call For Service')
  );
}

export interface TinyWidgetsContentProps {
  totals: ReportsTotals;
  contactTypeFilter?: string;
}

export const TinyWidgetsContent = ({
  totals,
  contactTypeFilter
}: TinyWidgetsContentProps): React.ReactElement<TinyWidgetsContentProps> => {
  const { isPhone, isSmallScreen, isMediumScreen } =
    React.useContext(MediaContext);

  return (
    <div className={styles.tinyWidgetsContent}>
      {Object.keys(totals).map(
        (totalsKey: string, index: number, list: string[]) => {
          const isLastOfRow: boolean =
            !isMediumScreen &&
            ((isPhone &&
              index < list.length - 1 &&
              (index + 1) % PHONE_COLUMNS === 0) ||
              (isSmallScreen &&
                index < list.length - 1 &&
                (index + 1) % SMALL_COLUMNS === 0));

          return (
            <Fragment key={totalsKey}>
              <TinyWidget
                className={`${styles.widget} ${
                  index === list.length - 1 || isLastOfRow
                    ? styles.noBorder
                    : ''
                }`}
                title={getTitle(totalsKey)}
                value={totals[totalsKey]}
                disabled={isWidgetDisabled(totalsKey, contactTypeFilter)}
              />

              {isLastOfRow && <Divider className={styles.horizontalDivider} />}
            </Fragment>
          );
        }
      )}
    </div>
  );
};
