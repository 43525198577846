import React, { useContext } from 'react';
import styles from './ContactTypeView.module.scss';

import ContactButton from './ContactButton/ContactButton';
import { SFScrollable } from 'sfui';
import { getFieldConfig } from '../../../../Services/ReportService';
import {
  CustomerContext,
  ReportCustomizationValue
} from 'ui-smartforce-settings';

export interface ContactTypeViewProps {
  onContactTypeSelected: (type: string) => void;
}

const ButtonOptions = [
  {
    icon: 'Phone-In-Talk',
    text: 'Call For Service'
  },
  {
    icon: 'Open-Hand-1',
    text: 'Officer Pro-active'
  }
];

function isValidOption(
  reportCustomizationValue: ReportCustomizationValue,
  option: string
) {
  const fieldConfig = getFieldConfig(
    reportCustomizationValue,
    'type',
    'general_information'
  );

  return !fieldConfig?.options || fieldConfig.options.includes(option);
}
const ContactTypeView = (
  props: ContactTypeViewProps
): React.ReactElement<ContactTypeViewProps> => {
  const { reportCustomizationValue } = useContext(CustomerContext);

  return (
    <div className={styles.contactTypeView}>
      <SFScrollable
        className={styles.scrollable}
        containerClassName={styles.scrollableContainer}
      >
        <div className={styles.content}>
          <h2 className={styles.title}>Select your Contact Type</h2>
          <p className={styles.subTitle}>
            To start your report please select the type of Contact.
          </p>

          <div className={styles.buttonsContainer}>
            {ButtonOptions.filter((o) =>
              isValidOption(reportCustomizationValue, o.text)
            ).map((option) => {
              return (
                <ContactButton
                  key={`contactType-${option.text}`}
                  icon={option.icon}
                  text={option.text}
                  onClick={props.onContactTypeSelected}
                />
              );
            })}
          </div>
        </div>
      </SFScrollable>
    </div>
  );
};

export default ContactTypeView;
