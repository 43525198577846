import React, { useContext, useEffect, useState } from 'react';
import styles from './FilterByModal.module.scss';
import moment from 'moment';
import {
  SFButton,
  SFRadioOptionsProps,
  SFText,
  SFDateRange,
  SFDateRangeValue,
  SFRadio,
  SFSelect,
  SFMenuOption
} from 'sfui';
import {
  Group,
  MemberPicker,
  PanelModal,
  User,
  UserContext,
  checkPermissions,
  getGroups,
  isEqualObject,
  isRoleOfficer,
  isRoleWatcher,
  AGENCY_GROUPS_READ,
  getTags,
  AgencyTag
} from 'ui-smartforce-settings';
import {
  AnalyticsFiltersForm,
  AnalyticsOfficerFilterOption,
  AnalyticsPeriodOption,
  AnalyticsPeriodOptions,
  AnalyticsPeriodType,
  AnalyticsSourceFilterOption,
  ReportTag
} from '../../../../../Models';
import { GroupFilter } from './GroupFilter/GroupFilter';
import { handleError } from '../../../../../Helpers';
import { useHistory } from 'react-router-dom';
import { AreaSelect } from '../../../../../Components/AreaSelect/AreaSelect';
import { upperFirst } from 'lodash';
import { TagsField } from '../../../ReportWizard/FieldInput/TagsField/TagsField';
import { AgencyTagsContext } from '../../../AgencyReports/ReportsFilterModal/ReportsFilterModal';

function getPeriodLabel(period: 'quarter' | 'half_year' | 'year'): string {
  if (period === 'half_year') return 'Half-year';
  else return upperFirst(period);
}

function getFirstSelectableOption(
  items: AnalyticsPeriodOption[]
): string | undefined {
  for (let item of items) {
    if (!item.disabled) return item.value;
  }
}

const MIN_DATE_RANGE = new Date(2022, 6, 1);

const officerRadioOptions: SFRadioOptionsProps[] = [
  {
    label: 'Name',
    value: 'name'
  },
  {
    label: 'Group',
    value: 'group'
  }
];

const sourceRadioOptions: SFRadioOptionsProps[] = [
  {
    label: 'All Reports',
    value: 'all'
  },
  {
    label: 'My Reports',
    value: 'created'
  },
  {
    label: 'Collaborated Reports',
    value: 'collaborated'
  }
];

const initialForm: AnalyticsFiltersForm = {
  period: 'month',
  officerOption: 'name'
};

function isDateRangeInvalid(value?: SFDateRangeValue): boolean {
  if (!value?.from || !value.to) {
    return false;
  }

  return moment(value.to).diff(value.from, 'day') > 30;
}

function isShowDisabled(
  value: AnalyticsFiltersForm,
  filters: AnalyticsFiltersForm
): boolean {
  if (isEqualObject(value, filters)) {
    return true;
  } else {
    return (
      value.period === 'custom' &&
      (!value.dateRange?.from ||
        !value.dateRange.to ||
        isDateRangeInvalid(value.dateRange))
    );
  }
}

export interface FilterByModalProps {
  filters: AnalyticsFiltersForm;
  isOpen: boolean;
  periodOptions?: AnalyticsPeriodOptions;
  onSubmit: (filters: AnalyticsFiltersForm) => void;
  onClose: () => void;
}

export const FilterByModal = ({
  filters,
  isOpen,
  periodOptions,
  onSubmit,
  onClose
}: FilterByModalProps): React.ReactElement<FilterByModalProps> => {
  const history = useHistory();
  const user = useContext(UserContext).user as User;
  const [value, setValue] = useState<AnalyticsFiltersForm>(filters);
  const [groups, setGroups] = useState<Group[]>([]);
  const [agencyTags, setAgencyTags] = useState<AgencyTag[]>([]);
  const [periodTypeOptions, setPeriodTypeOptions] = useState<SFMenuOption[]>(
    []
  );

  useEffect(() => {
    let isSubscribed: boolean = true;

    const init = async () => {
      try {
        const tags = await getTags(
          process.env.REACT_APP_SETTINGS_API_BASE_URL as string
        );

        let groups: Group[] = [];

        if (checkPermissions(AGENCY_GROUPS_READ, user.role.permissions)) {
          groups = await getGroups(
            process.env.REACT_APP_SETTINGS_API_BASE_URL as string
          );
        }

        let periodTypeOptions: SFMenuOption[] = [
          {
            label: 'Last week',
            value: 'week'
          },
          {
            label: 'Last month',
            value: 'month'
          }
        ];

        if (!isRoleOfficer(user.role.id)) {
          periodTypeOptions = [
            ...periodTypeOptions,
            {
              label: 'Quater',
              value: 'quarter',
              disabled:
                !periodOptions ||
                !getFirstSelectableOption(periodOptions.quarter)
            },
            {
              label: 'Half-year',
              value: 'half_year',
              disabled:
                !periodOptions ||
                !getFirstSelectableOption(periodOptions.half_year)
            },
            {
              label: 'Year',
              value: 'year',
              disabled:
                !periodOptions || !getFirstSelectableOption(periodOptions.year)
            }
          ];
        }

        if (isSubscribed) {
          setGroups(groups);
          setAgencyTags(tags);
          setPeriodTypeOptions([
            ...periodTypeOptions,
            {
              label: 'Exact dates',
              value: 'custom'
            }
          ]);
        }
      } catch (e) {
        console.error('Analytics::FilterByModal::init');
        handleError(e, history);
      }
    };

    init();

    // Unsuscribed when cleaning up
    return () => {
      isSubscribed = false;
    };
  }, [history, periodOptions, user]);

  useEffect(() => {
    setValue(filters);
  }, [filters]);

  const onModalDiscard = () => {
    onClose();
    setValue(filters);
  };

  const onClearAll = () => {
    setValue(initialForm);
  };

  const onPeriodChange = (period: string) => {
    let newValue = {
      ...value,
      period: period as AnalyticsPeriodType,
      dateRange: undefined
    };

    if (
      periodOptions &&
      (period === 'quarter' || period === 'half_year' || period === 'year')
    ) {
      newValue.periodValue = getFirstSelectableOption(periodOptions[period]);
    } else {
      newValue.periodValue = undefined;
    }

    setValue(newValue);
  };

  return (
    <AgencyTagsContext.Provider value={agencyTags}>
      <PanelModal
        classes={{
          dialog: {
            paper: styles.dialogPaper
          }
        }}
        headerTitle="Filter by"
        dialogCloseButton={{
          label: 'Discard',
          variant: 'text',
          sfColor: 'grey',
          onClick: onModalDiscard
        }}
        actionButton={{
          label: 'Show Results',
          disabled: isShowDisabled(value, filters),
          onClick: () => onSubmit(value)
        }}
        isOpen={isOpen}
        onClose={onClose}
      >
        <div className={styles.filterByModal}>
          <div className={styles.filter}>
            <SFText type="component-title">Date *</SFText>
            <div className={styles.filter}>
              <div className={styles.periodOptions}>
                {periodTypeOptions.map((option) => (
                  <div key={option.value} className={styles.periodOption}>
                    <SFRadio
                      key={option.value}
                      label={option.label}
                      disabled={option.disabled}
                      checked={value.period === option.value}
                      onChange={(_e, checked) => {
                        if (checked) {
                          onPeriodChange(option.value);
                        }
                      }}
                    />
                  </div>
                ))}
              </div>

              {value.period === 'custom' && (
                <SFDateRange
                  calendarClassName={styles.calendar}
                  min={MIN_DATE_RANGE}
                  max={new Date()}
                  disableFuture
                  helperText="You can filter up to 31 days."
                  error={isDateRangeInvalid(value.dateRange)}
                  value={value.dateRange}
                  CalendarPopperProps={{
                    modifiers: {
                      preventOverflow: { enabled: false },
                      flip: { enabled: false }
                    }
                  }}
                  onChange={(dateRange) =>
                    setValue({
                      ...value,
                      dateRange
                    })
                  }
                />
              )}

              {periodOptions &&
                (value.period === 'quarter' ||
                  value.period === 'half_year' ||
                  value.period === 'year') && (
                  <SFSelect
                    label={getPeriodLabel(value.period)}
                    options={periodOptions[value.period]}
                    value={value.periodValue}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        periodValue: e.target.value as string
                      })
                    }
                  />
                )}
            </div>
          </div>

          {!isRoleOfficer(user.role.id) && (
            <div className={styles.filter}>
              <SFText type="component-title">Officer</SFText>
              <div className={styles.officerOptions}>
                {officerRadioOptions.map((option) => (
                  <SFRadio
                    key={option.value}
                    label={option.label}
                    disabled={option.disabled}
                    checked={value.officerOption === option.value}
                    onChange={(_e, checked) => {
                      if (checked) {
                        setValue({
                          ...value,
                          officerOption:
                            option.value as AnalyticsOfficerFilterOption
                        });
                      }
                    }}
                  />
                ))}
              </div>

              {value.officerOption === 'name' && (
                <MemberPicker
                  activeOnly={false}
                  disabled={isRoleWatcher(user.role.id)}
                  baseUrl={
                    process.env.REACT_APP_SETTINGS_API_BASE_URL as string
                  }
                  label="Name"
                  value={value.officer}
                  onChange={(officer) =>
                    setValue({
                      ...value,
                      officer
                    })
                  }
                />
              )}

              {value.officerOption === 'group' && (
                <GroupFilter
                  options={groups}
                  value={value.group || []}
                  onChange={(group) =>
                    setValue({
                      ...value,
                      group: group.length > 0 ? group : undefined
                    })
                  }
                />
              )}
            </div>
          )}

          {isRoleOfficer(user.role.id) && (
            <div className={styles.filter}>
              <SFText type="component-title">Source</SFText>
              <div className={styles.sourceOptions}>
                {sourceRadioOptions.map((option) => (
                  <SFRadio
                    key={option.value}
                    label={option.label}
                    disabled={option.disabled}
                    checked={value.source === option.value}
                    onChange={(_e, checked) => {
                      if (checked) {
                        setValue({
                          ...value,
                          source: option.value as AnalyticsSourceFilterOption
                        });
                      }
                    }}
                  />
                ))}
              </div>
            </div>
          )}

          <div className={styles.filter}>
            <SFText type="component-title">Location</SFText>
            <AreaSelect
              label="Area"
              value={value.area || []}
              onChange={(area) =>
                setValue({ ...value, area: area.length > 0 ? area : undefined })
              }
            />
          </div>

          <div className={styles.filter}>
            <SFText type="component-title">Tags</SFText>
            <TagsField
              label="Agency Tags"
              value={value.tags || []}
              onChange={(v) =>
                setValue({
                  ...value,
                  tags: v as ReportTag[]
                })
              }
            />
          </div>

          <SFButton
            fullWidth
            size="large"
            variant="outlined"
            disabled={isEqualObject(value, initialForm)}
            onClick={onClearAll}
          >
            Clear All
          </SFButton>
        </div>
      </PanelModal>
    </AgencyTagsContext.Provider>
  );
};
