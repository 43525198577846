import React, { useContext, useMemo } from 'react';
import styles from './MultipleReportsModalSelect.module.scss';
import { SFMenuOption, SFSelect } from 'sfui';
import { Divider } from '../../Divider/Divider';
import { MultipleReportsModalSelectList } from './MultipleReportsModalSelectList/MultipleReportsModalSelectList';
import { FullReportGroupType } from '../../FullReportModal/FullReportModal';
import { MultipleReportsModalSelectOptionItem } from './MultipleReportsModalSelectOptionItem/MultipleReportsModalSelectOptionItem';
import { Customer, CustomerContext } from 'ui-smartforce-settings';
import { formatDate } from '../../../Helpers';

export interface MultipleReportsModalOption {
  incident_number: string;
  incident_date: string;
  author_name: string;
}

export interface MultipleReportsModalSelectProps {
  groupType: FullReportGroupType;
  options: MultipleReportsModalOption[];
  selected: string;
  onChange: (value: string) => void;
}

export const MultipleReportsModalSelect = ({
  groupType,
  options,
  selected,
  onChange
}: MultipleReportsModalSelectProps): React.ReactElement<MultipleReportsModalSelectProps> => {
  const timezone = (useContext(CustomerContext).customer as Customer).timezone;

  const label = `Reports ${
    groupType === 'noCoords' ? 'without' : 'with close'
  }  coordinates`;

  const reportOptions: SFMenuOption[] = useMemo(() => {
    return options.map((option: MultipleReportsModalOption) => ({
      label: formatDate(option.incident_date, 'L - HH:mm', timezone),
      value: option.incident_number,
      item: (
        <MultipleReportsModalSelectOptionItem
          title={formatDate(option.incident_date, 'L - HH:mm', timezone)}
          subTitle={option.author_name}
        />
      )
    }));
  }, [options, timezone]);

  return (
    <div className={styles.multipleReportsModalSelect}>
      <h4 className={styles.label}>
        <span className={styles.count}>{options.length}</span> {label}
      </h4>

      <div className={styles.select}>
        <SFSelect
          label="Report"
          value={selected}
          options={reportOptions}
          onChange={(
            e: React.ChangeEvent<{
              name?: string | undefined;
              value: unknown;
            }>
          ) => onChange(e.target.value as string)}
        />
        <Divider size={2} />
      </div>

      <MultipleReportsModalSelectList
        className={styles.optionList}
        options={options}
        selected={selected}
        onClick={(incidentNumber: string) => onChange(incidentNumber)}
      />
    </div>
  );
};
