import React from 'react';
import styles from './StaticMap.module.scss';
import { SFThemeType } from 'sfui';
import {
  GOOGLE_STATIC_DAY_MODE_URL,
  GOOGLE_STATIC_NIGHT_MODE_URL
} from '../../../../Constants/Maps';

import { GeoLocationCoords, AppearanceContext } from 'ui-smartforce-settings';

const IMG_WIDTH = 640;
const IMG_HEIGHT = 360;
const ZOOM = 15;
const SCALE = 2;
const MARKER_DAY_MODE_URL = `${process.env.REACT_APP_IMAGES_BASE_URL}/markers/DM_map-marker.png`;
const MARKER_NIGHT_MODE_URL = `${process.env.REACT_APP_IMAGES_BASE_URL}/markers/NM_map-marker.png`;

const getMapUrl = (
  themeType: SFThemeType,
  position: GeoLocationCoords,
  iconUrl: string,
  zoom: number,
  width: number,
  height: number,
  scale: number
) => {
  let url: string =
    themeType === 'day'
      ? GOOGLE_STATIC_DAY_MODE_URL
      : GOOGLE_STATIC_NIGHT_MODE_URL;

  url += `&center=${position.latitude},${position.longitude}`;
  url += `&zoom=${zoom}`;
  url += `&size=${width}x${height}&scale=${scale}`;
  url += `&markers=icon:${iconUrl}|anchor:center|${position.latitude},${position.longitude}`;
  url += `&key=AIzaSyDET6OJJuN80emEkb8VMTd-K-XaXDrgAjI`;

  return url;
};

export interface StaticMapProps {
  position: GeoLocationCoords;
}

export const StaticMap = ({
  position
}: StaticMapProps): React.ReactElement<StaticMapProps> => {
  const { themeType } = React.useContext(AppearanceContext);

  const iconUrl: string =
    themeType === 'day' ? MARKER_DAY_MODE_URL : MARKER_NIGHT_MODE_URL;

  const googleUrl: string = getMapUrl(
    themeType,
    position,
    iconUrl,
    ZOOM,
    IMG_WIDTH,
    IMG_HEIGHT,
    SCALE
  );

  return (
    <div
      className={styles.staticMap}
      style={{ backgroundImage: `url(${googleUrl})` }}
    ></div>
  );
};
