import React, { useContext } from 'react';
import styles from './ContentLinkWithReminder.module.scss';
import { SFIcon } from 'sfui';
import {
  InteractiveBox,
  ReminderTooltip,
  ReminderTooltipType
} from 'ui-smartforce-settings';
import { SFNavContentLinkProps } from '../SFNavContentLink/SFNavContentLink';
import { NavPanelContext } from '../../../../Context/NavPanelContext';

export interface ContentLinkWithReminderProps extends SFNavContentLinkProps {
  tooltipProps: {
    open: boolean;
    type: ReminderTooltipType;
    onGotIt: (type: ReminderTooltipType, checked: boolean) => void;
  };
  onClick: () => void;
}

const ContentLinkWithReminder = ({
  link,
  disabled = false,
  tooltipProps,
  onClick
}: ContentLinkWithReminderProps): React.ReactElement<ContentLinkWithReminderProps> => {
  const { isCollapsed } = useContext(NavPanelContext);

  return (
    <div
      className={`${styles.linkContainer} ${disabled ? styles.disabled : ''}`}
    >
      <InteractiveBox className={styles.link} onClick={onClick}>
        {isCollapsed && (
          <ReminderTooltip
            type={tooltipProps.type}
            open={tooltipProps.open}
            onGotIt={tooltipProps.onGotIt}
            style={{
              marginLeft: '12px'
            }}
          >
            <SFIcon icon={link.icon} className={styles.icon} size={20} />
          </ReminderTooltip>
        )}

        {!isCollapsed && (
          <>
            <SFIcon icon={link.icon} className={styles.icon} size={20} />
            <ReminderTooltip
              type={tooltipProps.type}
              open={tooltipProps.open}
              onGotIt={tooltipProps.onGotIt}
              style={{
                marginLeft: '12px'
              }}
            >
              <div className={styles.linkContent}>
                <span className={styles.text}>{link.label}</span>
              </div>
            </ReminderTooltip>
          </>
        )}
      </InteractiveBox>
    </div>
  );
};

export default ContentLinkWithReminder;
