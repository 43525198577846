import styles from './SectionForm.module.scss';
import {
  Field,
  FieldValueType,
  Section,
  SectionValueType,
  PeoplePickerValueType
} from '../../../../Models';
import { checkCitizensWithUOF, checkConditional } from '../../../../Helpers';
import { SectionFormField } from './SectionFormField/SectionFormField';
import { Fragment, useContext } from 'react';
import { CitizensContext } from '../ContactFormView/ContactFormView';
import { StateConfigContext } from '../../../../Context/StateConfigContext';
import { getFieldConfig } from '../../../../Services/ReportService';
import { CustomerContext } from 'ui-smartforce-settings';

const clearConditionalFields = (
  field: Field,
  sectionValue: SectionValueType
) => {
  let formValue = { ...sectionValue };

  function clearField(field: Field) {
    if (field.condition_if) {
      let cleared = 0;
      for (let conditional of field.condition_if) {
        if (
          !checkConditional(
            'conditional_if',
            conditional,
            formValue[field.name]
          )
        ) {
          cleared++;
          for (let condField of conditional.fields) {
            formValue[condField.name] = undefined;
            clearField(condField);
          }
        }
      }

      if (
        field.condition_else &&
        (!formValue[field.name] || cleared === field.condition_if.length - 1)
      ) {
        for (let condField of field.condition_else) {
          formValue[condField.name] = undefined;
          clearField(condField);
        }
      }
    }

    if (field.condition_if_not) {
      for (let conditional of field.condition_if_not) {
        if (
          !checkConditional(
            'conditional_if_not',
            conditional,
            formValue[field.name]
          )
        ) {
          for (let condField of conditional.fields) {
            formValue[condField.name] = undefined;
            clearField(condField);
          }
        }
      }
    }
  }

  clearField(field);

  return formValue;
};

export interface SectionFormProps {
  parentSectionName: string;
  config: Section;
  isInner?: boolean;
  value: SectionValueType;
  onChange: (value: SectionValueType) => void;
}

export const SectionForm = (props: SectionFormProps) => {
  const { reportCustomizationValue } = useContext(CustomerContext);
  const citizens = useContext(CitizensContext);
  const uofValidationConfig =
    useContext(StateConfigContext).stateConfig?.validations?.use_of_force;

  const fields: Field[] = props.config.fields.filter(
    (field: Field) => !field.hidden
  );

  const formValue = props.value || {};

  const onFieldChange = (field: Field, value: FieldValueType) => {
    let newForm = {
      ...formValue
    };

    if (field.component === 'peoplepicker') {
      if (value) {
        field.fields_to_populate?.forEach((fieldName: string) => {
          newForm[fieldName] = (
            (value as PeoplePickerValueType).asyncObject as any
          )[fieldName];
        });

        newForm[field.name] = (value as PeoplePickerValueType).name;
      } else {
        field.fields_to_populate?.forEach((fieldName: string) => {
          newForm[fieldName] = undefined;
        });
        newForm[field.name] = undefined;
      }
    } else {
      newForm[field.name] = value;
    }

    newForm = clearConditionalFields(field, newForm);
    props.onChange(newForm);
  };

  return (
    <div
      className={`${styles.sectionForm} ${props.isInner ? styles.isInner : ''}`}
    >
      {fields.map((field: Field) => {
        let disabled = false;
        let disabledMessage;

        // Check if this field must be disabled by the uof validation
        if (uofValidationConfig) {
          const disabledField =
            uofValidationConfig.officers.fields_disabled.find(
              (f) => f.name === `${props.config.name}.${field.name}`
            );

          if (
            disabledField &&
            !checkCitizensWithUOF(citizens, uofValidationConfig)
          ) {
            disabled = true;
            disabledMessage = disabledField.message;
          }
        }

        const fieldConfig = getFieldConfig(
          reportCustomizationValue,
          field.name,
          props.parentSectionName,
          props.config.name
        );

        return (
          <Fragment key={field.name}>
            {(!fieldConfig || !fieldConfig?.disabled) && (
              <SectionFormField
                disabled={disabled}
                disabledMessage={disabledMessage}
                field={
                  fieldConfig?.options
                    ? {
                        ...field,
                        options: fieldConfig.options
                      }
                    : field
                }
                sectionValue={formValue}
                onChange={onFieldChange}
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};
