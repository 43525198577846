import React from 'react';
import styles from './DateRangeField.module.scss';
import moment from 'moment';
import { InputProps } from '../FieldInput';
import { SFDatePicker, SFSwitch, SFText } from 'sfui';
import { DateRange } from '../../../../../Models';

const dateToday: moment.Moment = moment().endOf('day');

export interface DateRangeError {
  dateFrom: boolean;
  dateTo: boolean;
}

const isDateRangeError = (dateRange: DateRange): DateRangeError => {
  const dateFrom: moment.Moment | undefined = dateRange.dateFrom
    ? moment(dateRange.dateFrom)
    : undefined;
  const dateTo: moment.Moment | undefined = dateRange.dateTo
    ? moment(dateRange.dateTo)
    : undefined;

  let dateFromError: boolean = !moment(dateRange.dateFrom).isValid();
  let dateToError: boolean = !moment(dateRange.dateTo).isValid();

  if (dateFrom) {
    if (dateTo && dateTo.diff(dateFrom, 'days') > 365) {
      dateFromError = true;
      dateToError = true;
    }

    if (dateFrom > dateToday) {
      dateFromError = true;
    }

    if (dateTo && (dateTo < dateFrom || dateTo > dateToday)) {
      dateToError = true;
    } else if (!dateTo && !dateRange.exactDate) {
      dateToError = true;
    }
  }

  if (dateTo && !dateFrom) {
    dateFromError = true;
    dateToError = true;
  }

  return { dateFrom: dateFromError, dateTo: dateToError };
};

const EMPTY_FIELD_VALUE: DateRange = {
  dateFrom: undefined,
  dateTo: undefined,
  exactDate: false
};

export const DEFAULT_FILTERS_MIN_DATE: string = '01/01/2022';

export const DateRangeField = (
  props: InputProps
): React.ReactElement<InputProps> => {
  const value: DateRange = (props.value as DateRange) ?? EMPTY_FIELD_VALUE;
  const hasValueErrors = isDateRangeError(value);

  const onChange = (value: DateRange) => {
    props.onChange(
      !value.exactDate && !value.dateFrom && !value.dateTo ? undefined : value
    );
  };

  const onDateFromChange = (date: string | null | undefined) => {
    const newDateRange = {
      ...value,
      dateFrom: date ?? undefined
    };

    onChange(newDateRange);
  };

  const onDateToChange = (date: string | null | undefined) => {
    const newDateRange = {
      ...value,
      dateTo: date ?? undefined
    };

    onChange(newDateRange);
  };

  const onExactDate = (checked: boolean) => {
    onChange({
      ...value,
      dateTo: undefined,
      exactDate: checked
    });
  };

  return (
    <div className={styles.dateRangeField}>
      <SFText type="component-2">{props.label}</SFText>
      <SFDatePicker
        disableFuture
        label={value.exactDate ? 'On' : 'From'}
        minDate={DEFAULT_FILTERS_MIN_DATE}
        maxDate={moment().format('MM/DD/YYYY')}
        error={hasValueErrors.dateFrom}
        helperText={
          hasValueErrors.dateFrom ? 'Please enter a valid date.' : undefined
        }
        value={value.dateFrom}
        onChange={(_date, value) => onDateFromChange(value)}
      />
      {!value.exactDate && (
        <SFDatePicker
          disableFuture
          label="To"
          minDate={DEFAULT_FILTERS_MIN_DATE}
          maxDate={moment().format('MM/DD/YYYY')}
          error={hasValueErrors.dateTo}
          helperText={
            hasValueErrors.dateTo ? 'Please enter a valid date.' : undefined
          }
          value={value.dateTo}
          onChange={(_date, value) => onDateToChange(value)}
        />
      )}
      <SFSwitch
        label="Exact Date"
        checked={value.exactDate}
        onChange={(_e, checked: boolean) => onExactDate(checked)}
      />
    </div>
  );
};
