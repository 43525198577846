import React, { useContext } from 'react';
import styles from './FieldItem.module.scss';
import { FieldValueType } from '../../../../../Models';
import { getFieldFormattedValue } from '../Helpers';
import { Customer, CustomerContext } from 'ui-smartforce-settings';

export interface FieldItemProps {
  label: string;
  value: FieldValueType;
  type: string;
}

export const FieldItem = ({
  label,
  value,
  type
}: FieldItemProps): React.ReactElement<FieldItemProps> => {
  const timezone = (useContext(CustomerContext).customer as Customer).timezone;

  return (
    <div className={styles.fieldItem}>
      <h4 className={styles.label}>{label}</h4>
      <p className={styles.value}>
        {getFieldFormattedValue(value, type, timezone)}
      </p>
    </div>
  );
};
