import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from '../../../Components/Loader/Loader';
import { AGENCY_REPORTS_AMEND } from '../../../Constants/RolesAndPermissions';
import {
  AgencyTag,
  TourContext,
  UserContext,
  checkPermissions,
  getTags,
  useCloseTour
} from 'ui-smartforce-settings';
import { handleError } from '../../../Helpers';
import { ReportValue } from '../../../Models';
import { amendReport } from '../../../Services/ReportService';
import CancelModal from '../ReportWizard/CancelModal/CancelModal';
import { ContactFormView } from '../ReportWizard/ContactFormView/ContactFormView';
import styles from './AmendReport.module.scss';
import { AgencyTagsContext } from '../AgencyReports/ReportsFilterModal/ReportsFilterModal';

interface AmendReportState {
  report: ReportValue;
  redirectTo: string;
}

export const AmendReport = (): React.ReactElement<{}> | null => {
  const location = useLocation();
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { setIsFeatureReminderOpen } = useContext(TourContext);
  const locationState = location.state as AmendReportState;
  const [isCancelModalOpen, setCancelIsModalOpen] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [agencyTags, setAgencyTags] = React.useState<AgencyTag[]>([]);

  const report = locationState ? locationState.report : undefined;

  useCloseTour([
    { tourId: 6, step: 2 },
    { tourId: 6, step: 3 },
    { tourId: 10, step: 2 },
    { tourId: 10, step: 3 }
  ]);

  useEffect(() => {
    if (!locationState) {
      history.replace('/error');
    }
  }, [locationState, history]);

  useEffect(() => {
    let isSubscribed = true;

    const init = async () => {
      try {
        const tags = await getTags(
          process.env.REACT_APP_SETTINGS_API_BASE_URL as string
        );

        if (isSubscribed) {
          setAgencyTags(tags);
        }
      } catch (e) {
        console.error('AmendReport::init', e);
        handleError(e, history);
      }
    };

    init();

    //Cleanup
    return () => {
      isSubscribed = false;
    };
  }, [history]);

  const onCancel = () => {
    setCancelIsModalOpen(true);
  };

  const onDiscardChanges = () => {
    setCancelIsModalOpen(false);
    history.goBack();
  };

  const onModalClose = () => {
    setCancelIsModalOpen(false);
  };

  const onSubmit = async (contactValue: ReportValue, isTour?: boolean) => {
    try {
      setIsLoading(true);
      await amendReport({
        ...contactValue,
        created_in_version: process.env.REACT_APP_CC_VERSION
      });

      if (isTour) {
        setIsFeatureReminderOpen(true);
      }

      history.replace(locationState.redirectTo, {
        showMainAlert: true,
        mainAlertData: {
          message: 'The report was updated successfully.',
          autoHideDuration: 3600
        }
      });
    } catch (e) {
      console.error('AmendReport::onSubmit');
      handleError(e, history);
    }
  };

  const showSupervision: boolean = checkPermissions(
    AGENCY_REPORTS_AMEND,
    user?.role.permissions
  );

  return (
    <div className={styles.amendReport}>
      {isLoading && <Loader />}

      {!isLoading && report && (
        <AgencyTagsContext.Provider value={agencyTags}>
          <React.Fragment>
            <CancelModal
              isOpen={isCancelModalOpen}
              isUpdate
              onDiscard={onDiscardChanges}
              onClose={onModalClose}
            />

            <ContactFormView
              report={report}
              deviceDuration={
                report.general_information.device?.duration as number
              }
              showSupervision={showSupervision}
              onCancel={onCancel}
              onSubmit={onSubmit}
            />
          </React.Fragment>
        </AgencyTagsContext.Provider>
      )}
    </div>
  );
};
