import React, { Fragment } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom';
import {
  createSFTheme,
  SFAlertDialog,
  SFScrollableContext,
  SFStylesProvider,
  SFTheme,
  SFThemeProvider
} from 'sfui';
import Main from './Main/Main';
import { LandingView } from './LandingView/LandingView';
import { LoginView } from './LoginView/LoginView';
import { RegistrationView } from './RegistrationView/RegistrationView';
import { VerifyView } from './VerifyView/VerifyView';
import { ForgotPasswordView } from './ForgotPasswordView/ForgotPasswordView';
import { ResetPasswordView } from './ResetPasswordView/ResetPasswordView';
import { ErrorView } from './ErrorView/ErrorView';
import { AgencyInvitation } from './AgencyInvitation/AgencyInvitation';
import { RouteAuthGuard } from '../Router/RouteAuthGuard';
import {
  OnlineStatusContext,
  OnlineStatusProvider
} from '../Context/OnlineStatusContext';
import { StateConfigProvider } from '../Context/StateConfigContext';
import { NoConnectionView } from './NoConnectionView/NoConnectionView';
import { RouteOnlineGuard } from '../Router/RouteOnlineGuard';
import { NotMemberView } from './NotMemberView/NotMemberView';
import { SESSION_EXPIRE, SW_UPDATE } from '../Constants/Events';
import { UserIdErrorView } from './UserIdErrorView/UserIdErrorView';
import { UserOriErrorView } from './UserOriErrorView/UserOriErrorView';
import { SoftwareUpdateModal } from '../Components/SoftwareUpdateModal/SoftwareUpdateModal';
import { ActionNotAllowedErrorView } from './ActionNotAllowedErrorView/ActionNotAllowedErrorView';
import { ActionNotAvailableErrorView } from './ActionNotAvailableErrorView/ActionNotAvailableErrorView';
import { VerifyTokenErrorView } from './VerifyTokenErrorView/VerifyTokenErrorView';
import { PricingView } from './PricingView/PricingView';
import { getStatesList } from '../Services/AppService';
import { handleError } from '../Helpers';
import { SubscriptionStateView } from './SubscriptionStateView/SubscriptionStateView';

import {
  UserProvider,
  CustomerProvider,
  MediaProvider,
  AreasProvider,
  StatesListConfigContext,
  removeUserSession,
  SubscriptionProvider,
  TimezonesProvider,
  TourProvider,
  AppNotificationsProvider,
  AppearanceContext,
  AppearanceService
} from 'ui-smartforce-settings';
import { isNewVersionAvailable } from '../Services/SoftwareUpdateService';
import { UserNotVerifiedErrorView } from './UserNotVerifiedErrorView/UserNotVerifiedErrorView';
import { useAuth0 } from '@auth0/auth0-react';
import { OktaCallback } from './OktaCallback/OktaCallback';
import { CompleteYourInformationView } from './CompleteYourInformationView/CompleteYourInformationView';
import { NavPanelProvider } from '../Context/NavPanelContext';

const App = () => {
  const history = useHistory();
  const location = useLocation();
  const { isLoading: isOktaLoading } = useAuth0();
  const { isOnline } = React.useContext(OnlineStatusContext);
  const { themeType, scrollConfigType } = React.useContext(AppearanceContext);
  const theme: SFTheme = createSFTheme(themeType);
  const { setStatesList } = React.useContext(StatesListConfigContext);
  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const [isSWUpdateModalOpen, setIsSWUpdateModalOpen] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (isNewVersionAvailable() && location.pathname.includes('/cc')) {
      removeUserSession();
      history.push('/');
    }
  }, [history, location.pathname]);

  React.useEffect(() => {
    const showModal = (e: Event) => {
      setIsDialogOpen(true);
    };

    document.addEventListener(SESSION_EXPIRE, showModal);

    return () => {
      document.removeEventListener(SESSION_EXPIRE, showModal);
    };
  }, []);

  React.useEffect(() => {
    const showModal = (e: Event) => {
      setIsSWUpdateModalOpen(true);
    };

    document.addEventListener(SW_UPDATE, showModal);
    return () => {
      document.removeEventListener(SW_UPDATE, showModal);
    };
  }, []);

  React.useEffect(() => {
    const getStatesConfigList = async () => {
      setIsLoading(true);
      try {
        const statesList = await getStatesList();
        setStatesList(statesList);
        setIsLoading(false);
      } catch (e) {
        console.error('App::Error getting States List', e);
        setIsLoading(false);
        handleError(e, history);
      }
    };

    getStatesConfigList();
  }, [setStatesList, history]);

  const onGoToLogin = () => {
    setIsDialogOpen(false);
    //Remove stored session
    removeUserSession();
    history.push('/login');
  };

  const onVersionUpdate = () => {
    setIsSWUpdateModalOpen(false);
    //Remove stored session
    removeUserSession();
    history.push('/', { data: 'sw-update-reload' });
  };

  return (
    <Fragment>
      {!isLoading && !isOktaLoading && (
        <OnlineStatusProvider>
          <MediaProvider>
            <NavPanelProvider>
              <SFThemeProvider theme={theme}>
                <SFScrollableContext.Provider
                  value={AppearanceService.getScrollConfig(scrollConfigType)}
                >
                  <SFStylesProvider injectFirst>
                    <SFAlertDialog
                      title="Session Expired"
                      contentText="The action you tried to perform could not be completed. Please log in and try it again."
                      open={isDialogOpen}
                      rightAction={{
                        label: 'Go to Log in',
                        buttonProps: {
                          onClick: onGoToLogin
                        }
                      }}
                    />

                    <SoftwareUpdateModal
                      isOpen={isSWUpdateModalOpen}
                      onClick={onVersionUpdate}
                    />

                    <Switch>
                      <Route path="/" exact>
                        <LandingView />
                      </Route>

                      <Route path="/pricing">
                        <PricingView />
                      </Route>

                      <RouteOnlineGuard
                        path="/registration"
                        isOnline={isOnline}
                      >
                        <RegistrationView />
                      </RouteOnlineGuard>

                      <RouteOnlineGuard path="/verify" isOnline={isOnline}>
                        <VerifyView />
                      </RouteOnlineGuard>

                      <RouteOnlineGuard
                        path="/agency-invitation"
                        isOnline={isOnline}
                      >
                        <AgencyInvitation />
                      </RouteOnlineGuard>

                      <RouteOnlineGuard path="/login" isOnline={isOnline}>
                        <LoginView />
                      </RouteOnlineGuard>

                      <RouteOnlineGuard
                        path="/forgot-password"
                        isOnline={isOnline}
                      >
                        <ForgotPasswordView />
                      </RouteOnlineGuard>

                      <RouteOnlineGuard
                        path="/reset-password"
                        isOnline={isOnline}
                      >
                        <ResetPasswordView />
                      </RouteOnlineGuard>

                      <RouteAuthGuard path="/cc">
                        <UserProvider>
                          <TimezonesProvider>
                            <CustomerProvider>
                              <StateConfigProvider>
                                <SubscriptionProvider>
                                  <AreasProvider>
                                    <AppNotificationsProvider>
                                      <TourProvider>
                                        <Main />
                                      </TourProvider>
                                    </AppNotificationsProvider>
                                  </AreasProvider>
                                </SubscriptionProvider>
                              </StateConfigProvider>
                            </CustomerProvider>
                          </TimezonesProvider>
                        </UserProvider>
                      </RouteAuthGuard>

                      <Route path="/error">
                        <ErrorView />
                      </Route>

                      <Route path="/user-id-error">
                        <UserIdErrorView />
                      </Route>

                      <Route path="/user-ori-error">
                        <UserOriErrorView />
                      </Route>

                      <Route path="/no-connection">
                        <NoConnectionView />
                      </Route>

                      <Route path="/not-member">
                        <NotMemberView />
                      </Route>

                      <Route path="/action-not-allowed">
                        <ActionNotAllowedErrorView />
                      </Route>

                      <Route path="/action-not-available">
                        <ActionNotAvailableErrorView />
                      </Route>

                      <Route path="/verify-token-expired">
                        <VerifyTokenErrorView />
                      </Route>

                      <Route path="/user-not-verified">
                        <UserNotVerifiedErrorView />
                      </Route>

                      <Route path="/subscription-paused">
                        <SubscriptionStateView state="paused" />
                      </Route>

                      <Route path="/subscription-canceled">
                        <SubscriptionStateView state="canceled" />
                      </Route>

                      <Route
                        path="/login-verification"
                        component={() => <OktaCallback />}
                      />

                      <Route
                        path="/complete-your-information"
                        component={() => <CompleteYourInformationView />}
                      />

                      <Route path="*">
                        <Redirect to={'/'} />
                      </Route>
                    </Switch>
                  </SFStylesProvider>
                </SFScrollableContext.Provider>
              </SFThemeProvider>
            </NavPanelProvider>
          </MediaProvider>
        </OnlineStatusProvider>
      )}
    </Fragment>
  );
};

export default App;
