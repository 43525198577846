import html2canvas from 'html2canvas';
import { SFThemeType } from 'sfui';
import { LandingConfigImages } from '../Models/Landing';

export function cloneObject<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export const isValueEmpty = (value: any): boolean => {
  return (
    value === null ||
    value === undefined ||
    value === 'null' ||
    value === 'undefined' ||
    value === '' ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === typeof {} && Object.keys(value).length === 0)
  );
};

export function isFormEmpty<T>(formValue: T | undefined) {
  if (!formValue) {
    return true;
  }

  for (let key in formValue) {
    if (isValueEmpty(formValue[key as keyof T])) {
      return true;
    }
  }

  return false;
}

export function removeRepetead<T>(list: T[]): T[] {
  return list.filter((e: T, index: number) => {
    return list.indexOf(e) === index;
  });
}

export function clearEmptyObjects(o: any): any {
  for (var k in o) {
    if (!o[k] || typeof o[k] !== 'object') {
      continue;
    }

    clearEmptyObjects(o[k]);
    if (Object.keys(o[k]).length === 0) {
      delete o[k];
    }
  }
  return o;
}

export function getMomentDateString(date: moment.Moment): string {
  return date ? (date as any)._i ?? date.toISOString() : undefined;
}

export function toCanvas(
  element: HTMLElement,
  onClone: (document: Document, element: HTMLElement) => void
): Promise<HTMLCanvasElement> {
  return html2canvas(element, {
    allowTaint: true,
    useCORS: true,
    logging: false,
    onclone: onClone,
    scale: 2
  });
}

export function downloadCanvas(canvas: HTMLCanvasElement, name: string): void {
  // Download canvas as image
  var link = document.createElement('a');
  link.download = name;
  link.href = canvas.toDataURL();
  link.click();
}

export function getLandingImageUrl(
  image: LandingConfigImages,
  themeType: SFThemeType
): string {
  return `${process.env.REACT_APP_IMAGES_BASE_URL}/${
    themeType === 'day' ? image.dm : image.nm
  }`;
}

export function getUserSettingsByApp<T extends { app: string }>(
  settings: T[],
  app: string
): T | undefined {
  return settings.find((settings) => settings.app === app);
}
