import React from 'react';
import styles from './ContactButton.module.scss';
import { SFIcon } from 'sfui';
import { InteractiveBox } from 'ui-smartforce-settings';

export interface ContactButtonProps {
  icon: string;
  text: string;
  onClick: (contactType: string) => void;
}

const ContactButton = (
  props: ContactButtonProps
): React.ReactElement<ContactButtonProps> => {
  return (
    <InteractiveBox
      className={styles.contactButton}
      onClick={() => props.onClick(props.text)}
    >
      <div className={styles.buttonIcon}>
        <SFIcon size="42px" icon={props.icon} />
      </div>
      <h3 className={styles.buttonText}>{props.text}</h3>
    </InteractiveBox>
  );
};

export default ContactButton;
