import React from 'react';
import { SFScrollable } from 'sfui';
import { DoughnutDataType } from '../../../../../Models';
import styles from './DoughnutInformation.module.scss';
import { DoughnutItem } from './DoughnutItem/DoughnutItem';

export interface DoughnutInformationProps {
  data: DoughnutDataType[];
}

export const DoughnutInformation = ({
  data
}: DoughnutInformationProps): React.ReactElement<DoughnutInformationProps> => {
  const totalValue: number = data.reduce(
    (previous: number, current: DoughnutDataType) => previous + current.value,
    0
  );

  const getPercentage = (value: number): string => {
    return (Math.round((value || 0) * 100) / totalValue).toFixed(2);
  };

  return (
    <div className={styles.doughnutInformation}>
      <div className={styles.title}>
        <span>TYPE</span>
        <span>VALUE</span>
      </div>

      <div>
        <SFScrollable containerClassName={styles.content}>
          {data.map((v: DoughnutDataType, i: number) => (
            <DoughnutItem
              key={`${v.name}-${i}`}
              percentage={getPercentage(v.value)}
              index={i}
              name={v.name}
              value={v.value}
              color={v.color}
            />
          ))}
        </SFScrollable>
      </div>
    </div>
  );
};
