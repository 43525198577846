import React, { createContext, useState } from 'react';
import { SFDialog } from 'sfui';
import {
  WidgetCardContainer,
  WidgetCardContainerProps
} from './WidgetCardContainer/WidgetCardContainer';
import { Widget, AnalyticsPeriod, FilterMapOption } from '../../Models';
import { isMapWidget } from '../../Helpers';

function getFilterMapLabels(options: FilterMapOption[]) {
  return options.map((o) => o.label);
}

export const FilterMapContext = createContext<{
  checked: string[];
  setChecked: React.Dispatch<React.SetStateAction<string[]>>;
  isPanelOpen: boolean;
  setIsPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  checked: [],
  setChecked: () => {},
  isPanelOpen: false,
  setIsPanelOpen: () => {}
});

export interface WidgetCardProps extends WidgetCardContainerProps {
  widget: Widget;
  isActive?: boolean;
  period: AnalyticsPeriod;
  onAdd?: (id: number) => void;
  onRemove?: (id: number) => void;
}

export const WidgetCard = (
  props: WidgetCardProps
): React.ReactElement<WidgetCardProps> => {
  const [isMaximize, setIsMaximize] = useState<boolean>(false);
  const [filterMapLabelsChecked, setFilterMapLabelsChecked] = useState<
    string[]
  >(
    props.widget.component.name === 'filter_map'
      ? getFilterMapLabels(props.widget.component.config.filterMapOptions)
      : []
  );
  const [isFilterMapPanelOpen, setIsFilterMapPanelOpen] =
    useState<boolean>(false);

  const isMap: boolean = isMapWidget(props.widget);

  return (
    <FilterMapContext.Provider
      value={{
        checked: filterMapLabelsChecked,
        setChecked: setFilterMapLabelsChecked,
        isPanelOpen: isFilterMapPanelOpen,
        setIsPanelOpen: setIsFilterMapPanelOpen
      }}
    >
      {isMaximize && (
        <SFDialog fullScreen open>
          <WidgetCardContainer
            {...props}
            isMaximize={isMaximize}
            onToggleMaximize={(value) => setIsMaximize(value)}
          />
        </SFDialog>
      )}

      <WidgetCardContainer
        {...props}
        isMaximize={isMaximize}
        onToggleMaximize={isMap ? (value) => setIsMaximize(value) : undefined}
      />
    </FilterMapContext.Provider>
  );
};
