import React from 'react';
import styles from './LandingView.module.scss';
import { SFPaper, SFScrollable } from 'sfui';
import { AppearanceContext } from 'ui-smartforce-settings';
import { useHistory, useLocation } from 'react-router-dom';
import { LandingPresentation } from './LandingPresentation/LandingPresentation';
import { LandingProduct } from './LandingProduct/LandingProduct';
import { LandingFeatures } from './LandingFeatures/LandingFeatures';
import { LandingHowTo } from './LandingHowTo/LandingHowTo';
import { LandingHighlight } from './LandingHighlight/LandingHighlight';
import { LandingContact } from './LandingContact/LandingContact';
import { LandingTopBar } from '../../Components/LandingTopBar/LandingTopBar';
import { Footer } from 'footer-component';
import { getLandingConfig } from '../../Services/AppService';
import { LandingConfig } from '../../Models/Landing';
import { getLandingImageUrl } from '../../Helpers';

interface LandingViewLocationState {
  data: string;
}

const FOOTER_URL: string = `${process.env.REACT_APP_FOOTER_URL}`;

const observerOptions = {
  root: null,
  rootMargin: '0px',
  threshold: [0.0, 0.75]
};

export const LandingView = (): React.ReactElement<{}> => {
  const history = useHistory();
  const location = useLocation();
  const contactRef = React.useRef<HTMLDivElement>(null);
  const startRef = React.useRef<HTMLDivElement>(null);
  const { themeType } = React.useContext(AppearanceContext);
  const [currentSection, setCurrentSection] = React.useState<string>();
  const [config, setConfig] = React.useState<LandingConfig | undefined>();

  React.useEffect(() => {
    const init = async () => {
      try {
        const config = await getLandingConfig();
        setConfig(config);
      } catch (e) {
        console.error('LandingView::init', e);
      }
    };

    init();
  }, []);

  const handleIntersection = (value: IntersectionObserverEntry[]) => {
    // Check for first value in entries as it's observing only one element
    if (value[0].isIntersecting) {
      setCurrentSection('contact_us');
    } else {
      setCurrentSection(undefined);
    }
  };

  const intersectionObserver = new IntersectionObserver(
    handleIntersection,
    observerOptions
  );

  const onScrollableScroll = () => {
    // this comes from SFScrollable on scroll method
    if (contactRef.current) {
      intersectionObserver.observe(contactRef.current);
    }
  };

  const scrollToPosition = (ref: React.RefObject<HTMLDivElement>) => {
    ref?.current?.scrollIntoView({
      // scrolls to contact section
      block: 'center',
      inline: 'center',
      behavior: 'smooth'
    });
  };

  React.useEffect(() => {
    const locationState: LandingViewLocationState =
      location.state as LandingViewLocationState;

    if (locationState && locationState.data === 'sw-update-reload') {
      // Clear the history state
      history.replace({ state: {} });
      window.location.reload();
    }
  }, [location, history]);

  React.useEffect(() => {
    if (location.hash === '#contact-us') {
      scrollToPosition(contactRef);
    } else {
      scrollToPosition(startRef);
    }
  }, [location]);

  return (
    <>
      {config && (
        <SFPaper className={styles.landingView}>
          <LandingTopBar currentSection={currentSection} />
          <div>
            <SFScrollable
              containerClassName={styles.scrollable}
              onScroll={onScrollableScroll}
            >
              <div ref={startRef}></div>
              <main
                className={styles.scrollableContent}
                style={{
                  backgroundImage: `url(${getLandingImageUrl(
                    config.main.background_image,
                    themeType
                  )})`
                }}
              >
                <LandingPresentation config={config.main} />
                <LandingProduct config={config.product} />
                <LandingFeatures config={config.features} />
                <LandingHowTo config={config.how_to} />
                <LandingHighlight config={config.stats} />
                <LandingContact
                  config={config.contact}
                  forwardedRef={contactRef}
                />
              </main>

              <Footer theme={themeType} url={FOOTER_URL} />
            </SFScrollable>
          </div>
        </SFPaper>
      )}
    </>
  );
};
