import React from 'react';
import styles from './ContactForm.module.scss';
import {
  ReportConfig,
  Section,
  LocationFormValueType
} from '../../../../../Models';
import { SectionForm } from '../../SectionForm/SectionForm';
import { DateField } from '../../FieldInput/DateField/DateField';
import { TimeField } from '../../FieldInput/TimeField/TimeField';
import {
  GeneralInformationValueType,
  FieldValueType,
  SectionValueType
} from '../../../../../Models';
import { StateConfigContext } from '../../../../../Context/StateConfigContext';
import { LocationForm } from '../../LocationForm/LocationForm';
import {
  DATE_FUTURE_MSG,
  INCIDENT_DATE_YEAR_ERROR_MSG,
  TIME_FUTURE_MSG,
  TIME_HELP_MSG
} from '../../../../../Constants/Messages';
import { checkFutureDay, checkFutureTime } from '../../../../../Helpers';
import { CheckboxField } from '../../FieldInput/CheckboxField/CheckboxField';

const MIN_DATE = new Date('01/01/2022');

const LIGHTING_CONDITIONS_OPTIONS: string[] = [
  'Artificial Lighting (Streetlights)',
  'Artificial Lighting (Vehicle Lights)',
  'Bright Daylight',
  'Complete Darkness',
  'Overcast/Cloudy',
  'Shadowed/Partially Lit',
  'Twilight/Dawn'
];

function getDateHelperText(
  isFutureDay: boolean,
  isInvalidYear: boolean
): string {
  if (isFutureDay) {
    return DATE_FUTURE_MSG;
  } else if (isInvalidYear) {
    return INCIDENT_DATE_YEAR_ERROR_MSG;
  } else {
    return '';
  }
}

export function isValidYear(date: moment.Moment) {
  if (!date) {
    return false;
  }

  return date.year() >= 2022;
}

export interface ContactFormProps {
  value: GeneralInformationValueType;
  onDefaultFieldChange: (name: string, value: FieldValueType) => void;
  onSectionChange: (name: string, value: SectionValueType) => void;
}

export const ContactForm = ({
  value,
  onDefaultFieldChange,
  onSectionChange
}: ContactFormProps): React.ReactElement<ContactFormProps> => {
  const stateConfig = React.useContext(StateConfigContext)
    .stateConfig as ReportConfig;

  const isFutureDay: boolean = checkFutureDay(value['date'] as moment.Moment);
  const isFutureTime: boolean = checkFutureTime(
    value['date'] as moment.Moment,
    value['time'] as moment.Moment
  );

  const isInvalidYear = !isValidYear(value['date'] as moment.Moment);

  return (
    <div className={styles.contactForm}>
      <h3>General Information</h3>

      <div className={styles.generalInformation}>
        <div className={styles.defaultFields}>
          <h4>Date and Location</h4>

          <DateField
            error={isFutureDay || isInvalidYear}
            helperText={getDateHelperText(isFutureDay, isInvalidYear)}
            label="Date"
            minDate={MIN_DATE}
            onChange={(value: FieldValueType) =>
              onDefaultFieldChange('date', value)
            }
            required
            value={value['date'] as FieldValueType}
          />

          <TimeField
            label="Time"
            required
            value={value['time'] as FieldValueType}
            error={isFutureTime}
            helperText={isFutureTime ? TIME_FUTURE_MSG : TIME_HELP_MSG}
            onChange={(value: FieldValueType) =>
              onDefaultFieldChange('time', value)
            }
          />

          <LocationForm
            config={stateConfig.location_type}
            value={value['location'] as LocationFormValueType}
            onChange={(value) => onDefaultFieldChange('location', value)}
          />
        </div>
        <div className={styles.inputField}>
          <h4>Lighting conditions</h4>
          <CheckboxField
            label=""
            value={value['lighting_conditions'] as FieldValueType}
            options={LIGHTING_CONDITIONS_OPTIONS}
            onChange={(value) =>
              onDefaultFieldChange('lighting_conditions', value ?? [])
            }
          />
        </div>

        {stateConfig.general_information.sections.map((section: Section) => (
          <div key={section.name}>
            <h4>{section.label}</h4>
            <SectionForm
              parentSectionName="general_information"
              config={section}
              isInner
              value={value.state_related[section.name]}
              onChange={(sectionValue: SectionValueType) =>
                onSectionChange(section.name, sectionValue)
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};
