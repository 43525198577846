import React from 'react';
import styles from './SmartforceLegend.module.scss';

export interface SmartforceLegendProps {
  className?: string;
}

export const SmartforceLegend = ({
  className = ''
}: SmartforceLegendProps): React.ReactElement<SmartforceLegendProps> => {
  return (
    <div className={`${styles.smartforceLegend} ${className}`}>
      Powered by <span className={styles.strong}>SmartForce®</span>
    </div>
  );
};
