import React from 'react';
import styles from './TagsForm.module.scss';
import { ReportTag } from '../../../../../Models';
import { SFChip } from 'sfui';
import { TagsField } from '../../FieldInput/TagsField/TagsField';

export interface TagsFormProps {
  value: ReportTag[];
  onChange: (tags: ReportTag[]) => void;
}

export const TagsForm = ({
  value,
  onChange
}: TagsFormProps): React.ReactElement<TagsFormProps> => {
  return (
    <div className={styles.tagsForm}>
      <div className={styles.title}>
        <h3>Tags</h3>
        <SFChip label="NEW" size="small" sfColor="primary" />
      </div>

      <TagsField
        label="Agency Tags"
        value={value}
        onChange={(v) => onChange(v as ReportTag[])}
      />
    </div>
  );
};
