import React, { useContext } from 'react';
import styles from './PatterMapItemIncident.module.scss';
import { PatternMapDataType } from '../../../../../../../Models/Widget';
import {
  Customer,
  CustomerContext,
  InteractiveBox
} from 'ui-smartforce-settings';
import { formatDate, getFormattedNumber } from '../../../../../../../Helpers';

export interface PatterMapItemIncidentData extends PatternMapDataType {
  index: number;
}

export interface PatterMapItemIncidentProps extends PatterMapItemIncidentData {
  isClickable: boolean;
  onClick?: () => void;
}

export const PatterMapItemIncident = ({
  index,
  incident_number,
  incident_date,
  isClickable,
  onClick
}: PatterMapItemIncidentProps): React.ReactElement<PatterMapItemIncidentProps> => {
  const timezone = (useContext(CustomerContext).customer as Customer).timezone;

  return (
    <InteractiveBox
      className={`${styles.patterMapItemIncident} ${
        isClickable ? styles.isClickable : ''
      }`}
      onClick={onClick}
    >
      <span className={styles.itemIndex}>{getFormattedNumber(index)}</span>

      <div className={styles.incident}>
        <span className={styles.title}>INCIDENT NUMBER</span>

        <div className={styles.info}>
          <span className={styles.incidentNumber}>{incident_number}</span>
          <span className={styles.incidentDate}>
            {formatDate(incident_date, 'HH:mm', timezone)}
          </span>
        </div>
      </div>
    </InteractiveBox>
  );
};
