import React, { useContext, useMemo } from 'react';
import styles from './Sankey.module.scss';
import { EChartsOption } from 'echarts';
import {
  SankeyLinkDataType,
  SankeyNodeDataType
} from '../../../../Models/Widget';
import { EChart } from '../../../EChart/EChart';
import { ANALYTICS_COMMON_PALETTE } from '../../../../Constants/Colors';
import { SFGrey, SFScrollable } from 'sfui';
import { AppearanceContext } from 'ui-smartforce-settings';

function getChartOption(
  nodes: SankeyNodeDataType[],
  links: SankeyLinkDataType[],
  dayMode: boolean
): EChartsOption {
  return {
    tooltip: {
      trigger: 'item',
      triggerOn: 'mousemove',
      formatter: '{b} <strong>{c}</strong>'
    },
    series: [
      {
        type: 'sankey',
        data: nodes,
        links: links,
        left: 0,
        top: 0,
        bottom: 10,
        draggable: false,
        emphasis: {
          focus: 'adjacency'
        },
        label: {
          color: dayMode ? SFGrey[900] : SFGrey[50]
        },
        levels: [
          {
            depth: 0,
            itemStyle: {
              color: ANALYTICS_COMMON_PALETTE[0]
            },
            lineStyle: {
              color: 'source',
              opacity: 0.6
            }
          },
          {
            depth: 1,
            itemStyle: {
              color: ANALYTICS_COMMON_PALETTE[1]
            },
            lineStyle: {
              color: 'source',
              opacity: 0.6
            }
          },
          {
            depth: 2,
            itemStyle: {
              color: ANALYTICS_COMMON_PALETTE[2]
            },
            lineStyle: {
              color: 'source',
              opacity: 0.6
            }
          },
          {
            depth: 3,
            itemStyle: {
              color: ANALYTICS_COMMON_PALETTE[3]
            },
            lineStyle: {
              color: 'source',
              opacity: 0.6
            }
          }
        ],
        lineStyle: {
          curveness: 0.5
        }
      }
    ]
  };
}

export interface SankeyProps {
  nodes: SankeyNodeDataType[];
  links: SankeyLinkDataType[];
}

export const Sankey = ({
  nodes,
  links
}: SankeyProps): React.ReactElement<SankeyProps> => {
  const { themeType } = useContext(AppearanceContext);
  const option = useMemo(
    () => getChartOption(nodes, links, themeType === 'day'),
    [nodes, links, themeType]
  );

  return (
    <SFScrollable
      className={styles.scrollable}
      containerClassName={styles.sankey}
    >
      <EChart className={styles.echart} option={option} />
    </SFScrollable>
  );
};
