import React, { useContext, useMemo } from 'react';
import styles from './FilterMap.module.scss';
import { FilterSelector } from './FilterSelector/FilterSelector';
import {
  Coords,
  FilterMapDataType,
  FilterMapOption
} from '../../../../Models/Widget';
import { getMarker, transformCoordsToLatLng } from '../../../../Helpers';
import { SFButton } from 'sfui';

import { WidgetPanel } from '../../WidgetPanel/WidgetPanel';
import { MediaContext, Marker } from 'ui-smartforce-settings';
import { WidgetDrawer } from '../../WidgetDrawer/WidgetDrawer';
import {
  useFilteredReports,
  useMapOptions,
  useMarkerClick
} from '../../../../Hooks';
import { ReportsMap } from '../../../ReportsMap/ReportsMap';
import { AppearanceContext } from 'ui-smartforce-settings';
import { FilterMapContext } from '../../../WidgetCard/WidgetCard';

const removeReportDuplicates = (
  reports: FilterMapDataType[],
  filters: string[]
): FilterMapDataType[] => {
  return reports
    .filter((v: FilterMapDataType) => filters.includes(v.name))
    .reduce<FilterMapDataType[]>(
      (acc: FilterMapDataType[], r: FilterMapDataType) =>
        acc.findIndex(
          (rv: FilterMapDataType) => rv.incident_number === r.incident_number
        ) !== -1
          ? acc
          : [...acc, r],
      []
    );
};

export interface FilterMapProps {
  title: string;
  data: FilterMapDataType[];
  isDrawerOpen?: boolean;
  options: FilterMapOption[];
  onDrawerClose?: () => void;
}

export const FilterMap = ({
  title,
  isDrawerOpen = false,
  data,
  options,
  ...props
}: FilterMapProps): React.ReactElement<FilterMapProps> => {
  const { isPhone } = useContext(MediaContext);
  const { themeType } = useContext(AppearanceContext);
  const { checked, setChecked, isPanelOpen, setIsPanelOpen } =
    useContext(FilterMapContext);

  const { reportsWithCoords, reportsWithoutCoords } =
    useFilteredReports<FilterMapDataType>(data);

  const mapOptions = useMapOptions(data);

  // Filter report with multiple label values but same id
  const reportsWithoutCoordsNoDuplicates: FilterMapDataType[] = useMemo(
    () => removeReportDuplicates(reportsWithoutCoords, checked),
    [reportsWithoutCoords, checked]
  );

  const reportsWithCoordsNoDuplicates: FilterMapDataType[] = useMemo(
    () => removeReportDuplicates(reportsWithCoords, checked),
    [reportsWithCoords, checked]
  );

  const { onMarkerClick } = useMarkerClick(reportsWithCoordsNoDuplicates);

  const onDrawerClose = () => {
    props.onDrawerClose && props.onDrawerClose();
  };

  const onDrawerSave = () => {
    props.onDrawerClose && props.onDrawerClose();
  };

  return (
    <div className={styles.filterMap}>
      <ReportsMap
        {...mapOptions}
        reportsWithoutCoords={reportsWithoutCoordsNoDuplicates}
      >
        {reportsWithCoords.map(
          (item: FilterMapDataType, index: number): JSX.Element | undefined => (
            <Marker
              key={`${item.incident_number}-${index}`}
              visible={checked.indexOf(item.name) !== -1}
              {...getMarker(
                transformCoordsToLatLng(item.coords as Coords),
                item.color[themeType],
                index + 1
              )}
              onClick={() => onMarkerClick('closeCoords', item)}
            />
          )
        )}
      </ReportsMap>

      <FilterSelector
        className={styles.filterSelector}
        options={options}
        value={checked}
        onChange={(newChecked: string[]) => setChecked(newChecked)}
      />

      {!isPhone && (
        <WidgetPanel
          isOpen={isPanelOpen}
          onToggle={() => setIsPanelOpen((open: boolean) => !open)}
        >
          <FilterSelector
            options={options}
            value={checked}
            onChange={(newChecked: string[]) => setChecked(newChecked)}
          />
        </WidgetPanel>
      )}

      {isPhone && (
        <WidgetDrawer
          title={title}
          isOpen={isDrawerOpen}
          onClose={onDrawerClose}
        >
          <FilterSelector
            options={options}
            value={checked}
            onChange={(checked: string[]) => {
              setChecked(checked);
            }}
          />

          <div className={styles.drawerSave}>
            <SFButton fullWidth size="large" onClick={onDrawerSave}>
              Save Changes
            </SFButton>
          </div>
        </WidgetDrawer>
      )}
    </div>
  );
};
