import {
  createContext,
  Fragment,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import styles from './Main.module.scss';

// inject Stripe main script
import '@stripe/stripe-js';

import {
  Switch,
  Route,
  useLocation,
  useRouteMatch,
  useHistory
} from 'react-router-dom';

import { SFPaper, SFSnackBar, useSFMediaQuery } from 'sfui';

import { Onboarding } from './Onboarding/Onboarding';
import ReportWizard from './ReportWizard/ReportWizard';
import { AnalyticsMain } from './Analytics/AnalyticsMain';
import { PlanUpgrade } from './PlanUpgrade/PlanUpgrade';

import Loader from '../../Components/Loader/Loader';
import SFNavPanel from '../../Components/SFNavPanel/SFNavPanel';
import { SFNavLink } from '../../Components/SFNavPanel/SFNavLink';
import {
  AreasContext,
  CustomerContext,
  SubscriptionContext,
  StatesListConfigContext,
  UserContext,
  LARGE_SCREEN,
  Area,
  Customer,
  SFSettings,
  Subscription,
  User,
  getUser,
  getAreas,
  State,
  AGENCY_SUBSCRIPTION_READ,
  AGENCY_SUBSCRIPTION_UPDATE,
  checkPermissions,
  getSubscriptions,
  isPlanConnect,
  isRoleWatcher,
  SETTINGS_CUSTOM_EVENT,
  getCustomer,
  SettingsError,
  useSubscription,
  getAppSubscription,
  AppEnv,
  TimezonesContext,
  getTimezones,
  ToursCarrouselModal,
  Tour,
  MediaContext,
  TourContext,
  TourResumeTab,
  UserSettings,
  getUserSettings,
  useSaveTourAction,
  getAppNotifications,
  AppNotificationsContext,
  UserInvitation,
  getUserInvitation,
  acceptTokenInvitation,
  removeUserInvitation,
  getInvitations,
  isRoleOfficer,
  ReportCustomizationConfig,
  getReportCustomization,
  FeedbackModal,
  hideTours,
  ReminderTooltipType,
  saveFeedback,
  hideSurveys,
  Assistant,
  AssistantAreas
} from 'ui-smartforce-settings';
import { FullReportProvider } from '../../Context/FullReportContext';

import { RouteMainDefault } from '../../Router/RouteMainDefault';
import { RouteOnboardingGuard } from '../../Router/RouteOnboardingGuard';

import { getStateConfig } from '../../Services/AppService';
import { StateConfigContext } from '../../Context/StateConfigContext';
import {
  handleError,
  isOfficerAlreadyWithAgency,
  isOfficerIdAlreadyExist,
  isOwnerInvited,
  isUserInvitationNotExist
} from '../../Helpers/errors';
import { AmendReport } from './AmendReport/AmendReport';
import { MAIN_ALERT_MESSAGE, MAIN_INIT } from '../../Constants/Events';
import {
  dispatchMainAlertEvent,
  getMainAlertEventData,
  MainAlertEventData
} from '../../Events/MainAlertEvent';
import { OnlineStatusContext } from '../../Context/OnlineStatusContext';
import { NoConnectionModal } from '../../Components/NoConnectionModal/NoConnectionModal';
import {
  USER_REPORTS_AMEND,
  AGENCY_REPORTS_COLLABORATION_READ,
  AGENCY_REPORTS_CREATE,
  AGENCY_REPORTS_READ,
  USER_REPORTS_READ
} from '../../Constants/RolesAndPermissions';
import {
  getStateFileName,
  getToursByPlanAndRole,
  getUserSettingsByApp,
  isOfflinePath
} from '../../Helpers';
import { MyReports } from './MyReports/MyReports';
import { CollaboratedReports } from './CollaboratedReports/CollaboratedReports';
import { AgencyReports } from './AgencyReports/AgencyReports';
import { BusinessCardSection } from './BusinessCardSection/BusinessCardSection';
import { MainAd } from '../../Components/MainAd/MainAd';
import { ReportConfig } from '../../Models';
import { CCTopBar } from '../../Components/CCTopBar/CCTopBar';
import {
  isNewVersionUpdated,
  removeNewVersionUpdated
} from '../../Services/SoftwareUpdateService';
import { SoftwareUpdatedModal } from '../../Components/SoftwareUpdatedModal/SoftwareUpdatedModal';
import { TOURS } from '../../Constants/Tours';
import { ActivatePlan } from './ActivatePlan/ActivatePlan';
import { CompleteDebitInformationView } from '../CompleteDebitInformationView/CompleteDebitInformationView';
import { UserInvitations } from './UserInvitations/UserInvitations';

const hideAssistantPaths = [
  '/cc/onboarding',
  '/cc/user-invitations',
  '/cc/plan-upgrade',
  '/cc/plan-activate',
  '/cc/complete-debit',
  '/cc/amend'
];

export interface MainAssistantContextProps {
  setShowAssistant: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MainAssistantContext = createContext<MainAssistantContextProps>({
  setShowAssistant: () => {}
});

type navigationConfiguration = {
  path: string;
  isTopBarBottomTitleVisible?: boolean;
  isTopBarTopTitleVisible?: boolean;
  isFullWidth?: boolean;
  minimalMode?: boolean;
};

const navigationConfigurations: navigationConfiguration[] = [
  {
    path: 'default',
    isTopBarBottomTitleVisible: true,
    isFullWidth: true
  },
  {
    path: 'plan-upgrade',
    isTopBarBottomTitleVisible: false,
    isTopBarTopTitleVisible: false,
    isFullWidth: false,
    minimalMode: true
  },
  {
    path: 'plan-activate',
    isTopBarBottomTitleVisible: false,
    isTopBarTopTitleVisible: false,
    isFullWidth: false,
    minimalMode: true
  },
  {
    path: 'onboarding',
    isTopBarBottomTitleVisible: false,
    isTopBarTopTitleVisible: false,
    isFullWidth: false,
    minimalMode: true
  },
  {
    path: 'user-invitations',
    isTopBarBottomTitleVisible: false,
    isTopBarTopTitleVisible: false,
    isFullWidth: false,
    minimalMode: true
  },
  {
    path: 'complete-debit',
    isTopBarBottomTitleVisible: false,
    isTopBarTopTitleVisible: false,
    isFullWidth: false,
    minimalMode: true
  },
  {
    path: 'settings',
    isTopBarBottomTitleVisible: true,
    isFullWidth: true
  },
  {
    path: 'report',
    isTopBarBottomTitleVisible: false
  },
  {
    path: 'agency-reports',
    isTopBarBottomTitleVisible: true
  },
  {
    path: 'my-reports',
    isTopBarBottomTitleVisible: true
  },
  {
    path: 'collaborated-reports',
    isTopBarBottomTitleVisible: true
  },
  {
    path: 'amend',
    isTopBarBottomTitleVisible: false
  },
  {
    path: 'analytics',
    isTopBarBottomTitleVisible: true
  },
  { path: 'business-card', isTopBarBottomTitleVisible: true }
];

const getNavigationConfig = (
  pathname: string
): navigationConfiguration | undefined => {
  return navigationConfigurations.find(
    (config: navigationConfiguration) => config.path === pathname.split('/')[2]
  );
};

interface MainLocationState {
  section: string | undefined;
  fromLogin?: boolean;
}

const Main = (): ReactElement<{}> => {
  const { url, path } = useRouteMatch();
  const location = useLocation();
  const browserHistory = useHistory();

  const { isPhone } = useContext(MediaContext);
  const { user, setUser, userSettings, setUserSettings, setIsOnboarding } =
    useContext(UserContext);
  const { setNotifications } = useContext(AppNotificationsContext);
  const { setTimezones } = useContext(TimezonesContext);
  const { setAreas } = useContext(AreasContext);
  const { customer, setCustomer, setReportCustomizationValue } =
    useContext(CustomerContext);
  const { setSubscriptions } = useContext(SubscriptionContext);
  const subscription = useSubscription('cc');
  const { stateConfig, setStateConfig } = useContext(StateConfigContext);
  const { isOnline } = useContext(OnlineStatusContext);
  const { statesList } = useContext(StatesListConfigContext);
  const {
    onStart: onTourStart,
    onEnd: onTourEnd,
    onClose: onTourClose,
    tour: activeTour,
    isFeatureReminderOpen,
    setIsFeatureReminderOpen,
    onDisableReminder,
    onInitPaused
  } = useContext(TourContext);

  const isBigScreen: boolean = useSFMediaQuery(LARGE_SCREEN);

  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [alertData, setAlertData] = useState<MainAlertEventData>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isNavPanelOpen, setIsNavPanelOpen] = useState<boolean>(false);
  const [isOfflineModalOpen, setIsOfflineModalOpen] = useState<boolean>(false);
  const [isUpdatedModalOpen, setIsUpdatedModalOpen] = useState<boolean>(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] =
    useState<boolean>(false);
  const [isFeedbackReminderOpen, setIsFeedbackReminderOpen] =
    useState<boolean>(false);
  const [isFeedbackSaving, setIsFeedbackSaving] = useState<boolean>(false);
  const [showAssistant, setShowAssistant] = useState<boolean>(true);
  const [siteTitle, setSiteTitle] = useState<string>('Settings');
  const [navigationConfig, setNavigationConfig] = useState<
    navigationConfiguration | undefined
  >(getNavigationConfig(browserHistory.location.pathname));

  const [settingsSelectedSection, setSettingsSelectedSection] = useState<
    string | undefined
  >(location.state ? (location.state as MainLocationState).section : undefined);

  const [isToursCarrouselOpen, setIsToursCarrouselOpen] =
    useState<boolean>(false);

  const [invitations, setInvitations] = useState<UserInvitation[]>([]);

  // Ref to show feature carrousel modal only when user came from login view
  const refFromLogin = useRef<boolean>(
    !!location.state && !!(location.state as MainLocationState).fromLogin
  );

  useSaveTourAction(
    process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
    'cc'
  );

  useEffect(() => {
    if (
      hideAssistantPaths.includes(location.pathname) ||
      isPlanConnect(subscription?.plan)
    ) {
      setShowAssistant(false);
    } else {
      setShowAssistant(true);
    }
  }, [location.pathname, subscription]);

  useEffect(() => {
    setSettingsSelectedSection(
      location.state ? (location.state as MainLocationState).section : undefined
    );
  }, [location.state]);

  useEffect(() => {
    const updateAlertMsg = (e: Event) => {
      const data: MainAlertEventData = getMainAlertEventData(e);
      setAlertData(data);
      setShowSnackbar(true);
    };

    const initData = async (e: Event) => {
      setIsLoading(true);

      try {
        const userData: User = await getUser(
          process.env.REACT_APP_SETTINGS_API_BASE_URL as string
        );

        setUser(userData);

        const timezones = await getTimezones(
          process.env.REACT_APP_SETTINGS_API_BASE_URL as string
        );
        setTimezones(timezones);

        const customerData: Customer = await getCustomer(
          process.env.REACT_APP_SETTINGS_API_BASE_URL as string
        );
        setCustomer(customerData);

        if (customerData.status === 'Active') {
          const userSettings: UserSettings = await getUserSettings(
            process.env.REACT_APP_SETTINGS_API_BASE_URL as string
          );
          setUserSettings(userSettings);

          const appNotifications = await getAppNotifications(
            process.env.REACT_APP_SETTINGS_API_BASE_URL as string
          );
          setNotifications(appNotifications);

          if (
            checkPermissions(
              AGENCY_SUBSCRIPTION_READ,
              userData.role.permissions
            )
          ) {
            const subscriptions: Subscription[] = await getSubscriptions(
              process.env.REACT_APP_SETTINGS_API_BASE_URL as string
            );
            setSubscriptions(subscriptions);

            const currentSubscription = getAppSubscription(subscriptions, 'cc');

            // Set tour and surveys settings  only if has app subscription
            if (currentSubscription) {
              const tourSettings = getUserSettingsByApp(
                userSettings.tours,
                'cc'
              );

              if (tourSettings?.circuit) {
                const pausedTour = TOURS.find(
                  (t) => t.id === tourSettings?.circuit
                );
                if (pausedTour) {
                  onInitPaused(pausedTour);
                }
              }

              const isToursVisible = !tourSettings || tourSettings.show_again;

              if (!isToursVisible) {
                onDisableReminder();
              }

              setIsToursCarrouselOpen(
                !isRoleWatcher(userData.role.id) &&
                  refFromLogin.current &&
                  isToursVisible
              );
            }

            const areas: Area[] = await getAreas(
              process.env.REACT_APP_SETTINGS_API_BASE_URL as string
            );
            setAreas(areas);

            if (!isPlanConnect(currentSubscription?.plan)) {
              const stateConfig: ReportConfig = await getStateConfig(
                getStateFileName(
                  statesList as State[],
                  customerData?.state_name
                )
              );

              const reportCustomizationValue = await getReportCustomization(
                process.env.REACT_APP_API_BASE_URL as string
              );

              setReportCustomizationValue(reportCustomizationValue);
              setStateConfig(stateConfig);
            }
          }
        }

        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.error('Main::initData', e);
        handleError(e, browserHistory);
      }
    };

    document.addEventListener(MAIN_ALERT_MESSAGE, updateAlertMsg);
    document.addEventListener(SETTINGS_CUSTOM_EVENT, updateAlertMsg);
    document.addEventListener(MAIN_INIT, initData);

    return () => {
      document.removeEventListener(MAIN_ALERT_MESSAGE, updateAlertMsg);
      document.removeEventListener(SETTINGS_CUSTOM_EVENT, updateAlertMsg);
      document.removeEventListener(MAIN_INIT, initData);
    };
  }, [
    onDisableReminder,
    onInitPaused,
    setAreas,
    setCustomer,
    setNotifications,
    setStateConfig,
    setSubscriptions,
    setTimezones,
    setUser,
    setUserSettings,
    statesList,
    browserHistory,
    setReportCustomizationValue
  ]);

  // Hide alert when leaving route
  useEffect(() => {
    setShowSnackbar(false);
  }, [location.pathname]);

  const links: SFNavLink[] = useMemo(() => {
    let myLinks: SFNavLink[] = [];

    if (user) {
      if (
        !isPlanConnect(subscription?.plan) &&
        checkPermissions(AGENCY_REPORTS_READ, user.role?.permissions)
      ) {
        myLinks = [
          ...myLinks,
          {
            to: `${url}/agency-reports`,
            label: 'Agency Reports',
            icon: 'Users',
            bottomContent: false,
            type: 'route_internal'
          }
        ];
      }

      if (
        !isPlanConnect(subscription?.plan) &&
        checkPermissions(AGENCY_REPORTS_CREATE, user.role?.permissions)
      ) {
        myLinks = [
          ...myLinks,
          {
            to: `${url}/report`,
            label: 'Create Report',
            icon: 'Add-File',
            bottomContent: false,
            type: 'route_internal'
          }
        ];
      }

      if (
        !isPlanConnect(subscription?.plan) &&
        checkPermissions(USER_REPORTS_READ, user.role?.permissions)
      ) {
        myLinks = [
          ...myLinks,
          {
            to: `${url}/my-reports`,
            label: 'My Reports',
            icon: 'Files',
            bottomContent: false,
            type: 'route_internal'
          }
        ];
      }

      if (
        !isPlanConnect(subscription?.plan) &&
        isRoleOfficer(user.role.id) &&
        checkPermissions(
          AGENCY_REPORTS_COLLABORATION_READ,
          user?.role?.permissions
        )
      ) {
        myLinks = [
          ...myLinks,
          {
            to: `${url}/collaborated-reports`,
            label: 'Collaborated Reports',
            icon: 'Share-File-2',
            bottomContent: false,
            type: 'route_internal'
          }
        ];
      }

      myLinks = [
        ...myLinks,
        {
          to: `${url}/business-card`,
          label: 'Business Card',
          icon: 'Name-Tag',
          bottomContent: false,
          type: 'route_internal'
        }
      ];

      if (!isPlanConnect(subscription?.plan)) {
        myLinks = [
          ...myLinks,
          {
            to: `${url}/analytics`,
            label: 'Analytics',
            icon: 'Block-Chart-1',
            bottomContent: false,
            type: 'route_internal'
          }
        ];
      }
    }

    myLinks = [
      ...myLinks,
      {
        to: `${url}/amend`,
        label: 'Amend Report',
        icon: 'Pencil',
        bottomContent: false,
        type: 'route_internal',
        temporal: true
      },
      {
        to: `${url}/settings`,
        label: 'Settings',
        icon: 'Preferences-1',
        bottomContent: true,
        type: 'route_internal'
      }
    ];

    if (!isPhone && !isRoleWatcher(user?.role.id)) {
      myLinks = [
        ...myLinks,
        {
          label: 'Feature Tours',
          icon: 'Rectangle-Star',
          bottomContent: true,
          type: 'event',
          onClick: () => {
            onTourClose();
            setIsToursCarrouselOpen(true);
          }
        }
      ];
    }

    return [
      ...myLinks,
      {
        to: `${process.env.REACT_APP_CC_SUPPORT_URL}`,
        label: 'Feedback',
        icon: 'Speach-Bubble',
        bottomContent: true,
        type: 'event',
        onClick: () => setIsFeedbackModalOpen(true)
      },
      {
        to: `${process.env.REACT_APP_CC_SUPPORT_URL}`,
        label: 'Support',
        icon: 'Question-Mark',
        bottomContent: true,
        type: 'route_external'
      }
    ];
  }, [url, user, subscription, isPhone, onTourClose]);

  useEffect(() => {
    const getInitialData = async () => {
      try {
        let userData: User = await getUser(
          process.env.REACT_APP_SETTINGS_API_BASE_URL as string
        );

        if (isRoleOfficer(userData.role.id) && !userData.agency_id) {
          const userInvitation: string | null = getUserInvitation();
          if (userInvitation) {
            const newUser = await acceptTokenInvitation(
              process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
              userInvitation
            );

            if (newUser) {
              userData = newUser;
            }
          } else {
            const invitations = await getInvitations(
              process.env.REACT_APP_SETTINGS_API_BASE_URL as string
            );

            if (invitations.length > 0) {
              setInvitations(invitations);
              browserHistory.replace('/cc/user-invitations');
            }
          }
        }

        setUser(userData);

        const timezones = await getTimezones(
          process.env.REACT_APP_SETTINGS_API_BASE_URL as string
        );
        setTimezones(timezones);

        if (userData.agency_id) {
          const customerData: Customer = await getCustomer(
            process.env.REACT_APP_SETTINGS_API_BASE_URL as string
          );
          setCustomer(customerData);

          if (customerData.status === 'Active') {
            const userSettings: UserSettings = await getUserSettings(
              process.env.REACT_APP_SETTINGS_API_BASE_URL as string
            );
            setUserSettings(userSettings);

            const appNotifications = await getAppNotifications(
              process.env.REACT_APP_SETTINGS_API_BASE_URL as string
            );
            setNotifications(appNotifications);

            if (
              checkPermissions(
                AGENCY_SUBSCRIPTION_READ,
                userData.role.permissions
              )
            ) {
              const subscriptions: Subscription[] = await getSubscriptions(
                process.env.REACT_APP_SETTINGS_API_BASE_URL as string
              );
              setSubscriptions(subscriptions);

              const currentSubscription = getAppSubscription(
                subscriptions,
                'cc'
              );

              // Set tour and surveys settings only if has app subscription
              if (currentSubscription) {
                const tourSettings = getUserSettingsByApp(
                  userSettings.tours,
                  'cc'
                );

                if (tourSettings?.circuit) {
                  const pausedTour = TOURS.find(
                    (t) => t.id === tourSettings?.circuit
                  );
                  if (pausedTour) {
                    onInitPaused(pausedTour);
                  }
                }

                const isToursVisible = !tourSettings || tourSettings.show_again;

                if (!isToursVisible) {
                  onDisableReminder();
                }

                setIsToursCarrouselOpen(
                  !isRoleWatcher(userData.role.id) &&
                    refFromLogin.current &&
                    isToursVisible
                );
              }

              const areas: Area[] = await getAreas(
                process.env.REACT_APP_SETTINGS_API_BASE_URL as string
              );
              setAreas(areas);

              if (!isPlanConnect(currentSubscription?.plan)) {
                const stateConfig: ReportConfig = await getStateConfig(
                  getStateFileName(
                    statesList as State[],
                    customerData?.state_name
                  )
                );
                const reportCustomizationValue = await getReportCustomization(
                  process.env.REACT_APP_API_BASE_URL as string
                );

                setReportCustomizationValue(reportCustomizationValue);
                setStateConfig(stateConfig);
              }
            }
          }
        } else {
          setIsOnboarding(true);
        }

        setIsLoading(false);
        refFromLogin.current = false;

        if (isNewVersionUpdated()) {
          removeNewVersionUpdated();
          setIsUpdatedModalOpen(true);
        }
      } catch (e) {
        if (isOwnerInvited(e) || isUserInvitationNotExist(e)) {
          console.error(`Main::AcceptInvitation::OwnerUsersCanNotBeInvited`, e);
          removeUserInvitation();
        } else if (isOfficerIdAlreadyExist(e)) {
          console.error(`Main::AcceptInvitation::OfficerIdAlreadyExists`, e);
          browserHistory.replace('/user-id-error');
        } else if (isOfficerAlreadyWithAgency(e)) {
          console.error(
            `Main::AcceptInvitation::isOfficerAlreadyWithAgency`,
            e
          );
          browserHistory.replace('/user-ori-error');
        } else {
          console.error('Main::getInitialData', e);
          handleError(e, browserHistory);
        }
      }
    };

    getInitialData();
  }, [
    setUser,
    setUserSettings,
    setNotifications,
    setCustomer,
    setSubscriptions,
    setStateConfig,
    setAreas,
    setTimezones,
    setIsOnboarding,
    onDisableReminder,
    onInitPaused,
    statesList,
    browserHistory,
    setReportCustomizationValue
  ]);

  useEffect(() => {
    const link: SFNavLink | undefined = links.find(
      (link: SFNavLink) =>
        link.type === 'route_internal' && link.to === location.pathname
    );
    if (link) {
      setSiteTitle(link.label);
    }
  }, [links, location.pathname]);

  useEffect(() => {
    const unregisterBrowserHistory = browserHistory.listen((params) => {
      const { pathname } = params;
      const navigationConfig = getNavigationConfig(pathname);

      if (navigationConfig) {
        setNavigationConfig(navigationConfig);
      } else {
        setNavigationConfig(navigationConfigurations[0]);
      }
    });

    return () => {
      unregisterBrowserHistory();
    };
  }, [browserHistory]);

  useEffect(() => {
    // If its offline and
    // it's in onboarding (no customer id or customer not active)
    // or it's watcher role
    if (
      !isOnline &&
      (!user?.agency_id ||
        customer?.status !== 'Active' ||
        isRoleWatcher(user?.role.id))
    ) {
      browserHistory.replace('/no-connection');
    } else if (!isOnline && !isOfflinePath(location.pathname)) {
      setIsOfflineModalOpen(true);
    } else {
      setIsOfflineModalOpen(false);
    }
  }, [browserHistory, user, customer, location.pathname, isOnline]);

  const onSettingsError = useCallback(
    (e: SettingsError) => {
      handleError(e, browserHistory);
    },
    [browserHistory]
  );

  const onHome = useCallback(
    () => browserHistory.push('/cc'),
    [browserHistory]
  );

  const onUpgrade = useCallback(
    (product: string) => {
      browserHistory.push('/cc/plan-upgrade');
    },
    [browserHistory]
  );

  const onCloseTourCarrousel = () => {
    setIsFeatureReminderOpen(true);
    setIsToursCarrouselOpen(false);
  };

  const onStartTour = (tour: Tour) => {
    setIsToursCarrouselOpen(false);

    if (tour.id === 1 || tour.id === 2) {
      browserHistory.push('/cc/settings', { section: 'members' });
    } else if (tour.id === 3) {
      browserHistory.push('/cc/settings', { section: 'agency' });
    } else if (tour.id === 4) {
      browserHistory.push('/cc/settings', { section: 'business_card' });
    } else if (tour.id === 5) {
      browserHistory.push('/cc/settings', { section: 'areas' });
    } else if (tour.id === 6 || tour.id === 7 || tour.id === 8) {
      browserHistory.push('/cc/agency-reports');
    } else if (tour.id === 9) {
      browserHistory.push('/cc/settings', { section: 'groups' });
    } else if (tour.id === 10) {
      browserHistory.push('/cc/my-reports');
      onTourStart(tour);
    } else {
      return;
    }

    onTourStart(tour);
  };

  const onResumeTour = () => {
    onStartTour(activeTour as Tour);
  };

  const onExitTourResume = () => {
    setIsFeatureReminderOpen(true);
    onTourEnd();
  };

  const tours: Tour[] = useMemo(() => {
    if (subscription && user) {
      return getToursByPlanAndRole(
        subscription.plan,
        subscription.free,
        user.role.id
      );
    } else {
      return [];
    }
  }, [subscription, user]);

  const onActivate = useCallback(() => {
    browserHistory.push('/cc/plan-activate');
  }, [browserHistory]);

  const onGenerateDebitUrl = useCallback(
    (url: string) => {
      browserHistory.push('/cc/complete-debit', { url });
    },
    [browserHistory]
  );

  const onFeedbackSubmit = (content: string) => {
    try {
      setIsFeedbackSaving(true);
      saveFeedback(
        process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
        process.env.REACT_APP_CC_VERSION as string,
        'cc',
        content
      );
      dispatchMainAlertEvent({
        message: 'Thanks! We truly appreciate your feedback.',
        autoHideDuration: 3600
      });
      setIsFeedbackSaving(false);
      setIsFeedbackModalOpen(false);
    } catch (e) {
      setIsFeedbackSaving(false);
      console.error('Main::onFeedbackSumit', e);
      handleError(e, browserHistory);
    }
  };

  const onReminderGotIt = (type: ReminderTooltipType, checked: boolean) => {
    if (checked) {
      if (type === 'tours') {
        hideTours(process.env.REACT_APP_SETTINGS_API_BASE_URL as string, 'cc');
        onDisableReminder();
      } else {
        hideSurveys(
          process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
          'cc'
        );

        setUserSettings((settings) => {
          if (!settings) return settings;

          const newUserSettings: UserSettings = { ...settings };
          const appIndex = newUserSettings.surveys.findIndex(
            (s) => s.app === 'cc'
          );
          if (appIndex === -1) {
            newUserSettings.surveys.push({ app: 'cc', show_again: false });
          } else {
            newUserSettings.surveys.map((s) => {
              if (s.app === 'cc') {
                return { ...s, show_again: false };
              } else return s;
            });
          }
          return newUserSettings;
        });
      }
    }

    setIsFeedbackReminderOpen(false);
    setIsFeatureReminderOpen(false);
  };

  const onFeedbackSkip = () => {
    setIsFeedbackModalOpen(false);
    const surveysSettings = userSettings?.surveys?.find((f) => f.app === 'cc');
    if (!surveysSettings || surveysSettings.show_again) {
      setIsFeatureReminderOpen(false);
      setIsFeedbackReminderOpen(true);
    }
  };

  return (
    <MainAssistantContext.Provider value={{ setShowAssistant }}>
      <SFPaper className={styles.container}>
        {isLoading && <Loader className={styles.loader} />}

        {!isLoading && user && (
          <Fragment>
            <TourResumeTab onExit={onExitTourResume} onResume={onResumeTour} />

            <ToursCarrouselModal
              tours={tours}
              open={!isPhone && isToursCarrouselOpen}
              onClose={onCloseTourCarrousel}
              onStart={onStartTour}
            />

            <div
              className={`${styles.main} ${
                navigationConfig &&
                navigationConfig.isTopBarBottomTitleVisible &&
                !isBigScreen
                  ? styles.showBottomTitle
                  : ''
              } ${navigationConfig?.minimalMode ? styles.isMinimalMode : ''}`}
            >
              {!navigationConfig?.minimalMode && (
                <SFNavPanel
                  className={styles.navPanel}
                  links={links}
                  isBigScreen={isBigScreen}
                  isOpen={isNavPanelOpen}
                  onClose={() => setIsNavPanelOpen(false)}
                  onLinkSelected={(link: SFNavLink) => setSiteTitle(link.label)}
                  reminderProps={{
                    open: isFeatureReminderOpen || isFeedbackReminderOpen,
                    type: isFeatureReminderOpen ? 'tours' : 'surveys',
                    onGotIt: onReminderGotIt
                  }}
                />
              )}

              {/* For accessibility reason */}
              <h1 style={{ display: 'none' }}>{siteTitle}</h1>

              <CCTopBar
                className={styles.topBar}
                isMinimal={navigationConfig?.minimalMode}
                siteTitle={siteTitle}
                isTopTitleVisible={navigationConfig?.isTopBarTopTitleVisible}
                isBottomTitleVisible={
                  !isBigScreen
                    ? navigationConfig?.isTopBarBottomTitleVisible
                    : false
                }
                onMenuButtonClick={() => setIsNavPanelOpen(true)}
                onError={onSettingsError}
                reminderProps={{
                  open: isFeatureReminderOpen || isFeedbackReminderOpen,
                  type: isFeatureReminderOpen ? 'tours' : 'surveys',
                  onGotIt: onReminderGotIt
                }}
              />

              <main
                className={`${styles.mainView} ${
                  navigationConfig?.isFullWidth ? styles.fullWidth : ''
                }`}
              >
                <FullReportProvider>
                  <SFSnackBar
                    open={showSnackbar}
                    message={alertData?.message}
                    buttonText={alertData?.actionButtonText}
                    autoHideDuration={alertData?.autoHideDuration ?? 3600}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: isBigScreen ? 'left' : 'center'
                    }}
                    onClose={() => setShowSnackbar(false)}
                  />

                  <NoConnectionModal isOpen={isOfflineModalOpen} />

                  <SoftwareUpdatedModal
                    isOpen={isUpdatedModalOpen}
                    onClick={() => setIsUpdatedModalOpen(false)}
                  />

                  <FeedbackModal
                    isOpen={isFeedbackModalOpen}
                    isSaving={isFeedbackSaving}
                    onSubmit={onFeedbackSubmit}
                    onSkip={onFeedbackSkip}
                  />

                  <Assistant
                    show={showAssistant}
                    items={[{ title: 'Areas', component: <AssistantAreas /> }]}
                  />

                  <MainAd />

                  <Switch>
                    <RouteOnboardingGuard
                      path={`${path}/onboarding`}
                      user={user}
                      customer={customer}
                      subscription={subscription}
                      url={url}
                    >
                      <Onboarding />
                    </RouteOnboardingGuard>

                    <Route path={`${path}/user-invitations`}>
                      <UserInvitations invitations={invitations} />
                    </Route>

                    {checkPermissions(
                      AGENCY_REPORTS_READ,
                      user.role?.permissions
                    ) && (
                      <Route path={`${path}/agency-reports`}>
                        <AgencyReports />
                      </Route>
                    )}

                    {checkPermissions(
                      USER_REPORTS_READ,
                      user.role?.permissions
                    ) && (
                      <Route path={`${path}/my-reports`}>
                        <MyReports />
                      </Route>
                    )}

                    {checkPermissions(
                      AGENCY_REPORTS_COLLABORATION_READ,
                      user?.role?.permissions
                    ) && (
                      <Route path={`${path}/collaborated-reports`}>
                        <CollaboratedReports />
                      </Route>
                    )}

                    {checkPermissions(
                      AGENCY_REPORTS_CREATE,
                      user.role?.permissions
                    ) && (
                      <Route path={`${path}/report`}>
                        <ReportWizard />
                      </Route>
                    )}

                    <Route path={`${path}/analytics`}>
                      <AnalyticsMain />
                    </Route>

                    {checkPermissions(
                      USER_REPORTS_AMEND,
                      user?.role?.permissions
                    ) && (
                      <Route path={`${path}/amend`}>
                        <AmendReport />
                      </Route>
                    )}

                    {checkPermissions(
                      AGENCY_SUBSCRIPTION_UPDATE,
                      user?.role?.permissions
                    ) && (
                      <Route path={`${path}/plan-upgrade`}>
                        <PlanUpgrade />
                      </Route>
                    )}

                    {checkPermissions(
                      AGENCY_SUBSCRIPTION_UPDATE,
                      user?.role?.permissions
                    ) && (
                      <Route path={`${path}/plan-activate`}>
                        <ActivatePlan />
                      </Route>
                    )}

                    <Route path={`${path}/business-card`}>
                      <BusinessCardSection />
                    </Route>

                    <Route path={`${path}/settings`}>
                      <SFSettings
                        product="cc"
                        enviroment={process.env.REACT_APP_ENV as AppEnv}
                        stripeApiKey={`${process.env.REACT_APP_STRIPE_KEY}`}
                        selectedSectionName={settingsSelectedSection}
                        reportCustomizationConfig={
                          stateConfig?.report_customization as ReportCustomizationConfig
                        }
                        onSectionChange={(name) =>
                          setSettingsSelectedSection(name)
                        }
                        onError={onSettingsError}
                        onHome={onHome}
                        onUpgrade={onUpgrade}
                        onActivate={onActivate}
                        onGenerateDebitUrl={onGenerateDebitUrl}
                      />
                    </Route>

                    <Route path={`${path}/complete-debit`}>
                      <CompleteDebitInformationView />
                    </Route>

                    {/* Default path */}

                    <RouteMainDefault
                      path={`${path}/`}
                      url={url}
                      user={user}
                      subscription={subscription}
                    />

                    <RouteMainDefault
                      path={`${path}/*`}
                      url={url}
                      user={user}
                      subscription={subscription}
                    />
                  </Switch>
                </FullReportProvider>
              </main>
            </div>
          </Fragment>
        )}
      </SFPaper>
    </MainAssistantContext.Provider>
  );
};

export default Main;
