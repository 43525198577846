import React, { useContext } from 'react';
import styles from './TagsField.module.scss';
import { SFAutocompleteChip, SFText } from 'sfui';
import { ReportTag } from '../../../../../Models';
import { InputProps } from '../FieldInput';
import { AgencyTagsContext } from '../../../AgencyReports/ReportsFilterModal/ReportsFilterModal';

export const TagsField = (
  props: InputProps
): React.ReactElement<InputProps> => {
  const options = useContext(AgencyTagsContext).map((t) => ({
    id: t.id,
    name: t.name,
    color: t.color
  }));

  const renderOption = (option: ReportTag) => {
    return (
      <div className={styles.tagsFieldOption}>
        <div
          className={styles.color}
          style={{ backgroundColor: option.color }}
        />
        <SFText type="component-1">{option.name}</SFText>
      </div>
    );
  };

  return (
    <SFAutocompleteChip
      label={props.label}
      value={props.value ? (props.value as ReportTag[]) : []}
      options={options}
      onChange={props.onChange}
      renderOption={renderOption}
      getValueText={(value: ReportTag) => value.name}
      getValueColor={(value: ReportTag) => value.color}
      getOptionLabel={(option: ReportTag) => option.name}
      getOptionSelected={(option: ReportTag, value: ReportTag) =>
        option.id === value.id
      }
    />
  );
};
