import React from 'react';
import styles from './LandingContact.module.scss';

import { AppearanceContext } from 'ui-smartforce-settings';
import { LandingConfigContact } from '../../../Models/Landing';
import { getLandingImageUrl } from '../../../Helpers';

export interface LandingContactProps {
  config: LandingConfigContact;
  forwardedRef: React.LegacyRef<HTMLDivElement>;
}

export const LandingContact = ({
  config,
  forwardedRef
}: LandingContactProps): React.ReactElement<LandingContactProps> => {
  const { themeType } = React.useContext(AppearanceContext);

  return (
    <div className={styles.landingContact} ref={forwardedRef}>
      <h1 className={styles.title}>Contact Us</h1>

      <div className={styles.content}>
        <div className={styles.info}>
          <h2>{config.name}</h2>

          <a className={styles.link} href={`mailto:${config.email}`}>
            {config.email}
          </a>

          <p className={styles.address}>
            {config.phone} <br />
            {config.address} <br />
            {config.area}
          </p>

          <a
            className={styles.link}
            href={config.location}
            target="_blank"
            rel="noreferrer"
          >
            Get directions
          </a>
        </div>

        <div className={styles.image}>
          <img
            src={getLandingImageUrl(config.location_image, themeType)}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
