import React from 'react';
import styles from './ItemForm.module.scss';
import { SectionForm } from '../../SectionForm/SectionForm';
import {
  ItemListConfig,
  ItemValueType,
  Section,
  SectionValueType
} from '../../../../../Models';

export interface ItemFormProps {
  sectionName: string;
  config: ItemListConfig;
  value: ItemValueType;
  onChange: (value: ItemValueType) => void;
}

export const ItemForm = ({
  sectionName,
  config,
  value,
  onChange
}: ItemFormProps): React.ReactElement<ItemFormProps> => {
  const onSectionChange = (
    sectionName: string,
    sectionValue: SectionValueType
  ) => {
    const newValue: ItemValueType = {
      ...value,
      [sectionName]: sectionValue
    };

    onChange(newValue);
  };

  return (
    <div className={styles.citizenForm}>
      {config.sections.map((section: Section) => (
        <div key={section.name} className={styles.section}>
          <h2>{section.label}</h2>

          <SectionForm
            parentSectionName={sectionName}
            config={section}
            value={value[section.name]}
            onChange={(sectionValue: SectionValueType) =>
              onSectionChange(section.name, sectionValue)
            }
          />
        </div>
      ))}
    </div>
  );
};
