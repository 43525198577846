import React, { useMemo } from 'react';
import { WidgetList } from '../../../../../Components/WidgetList/WidgetList';
import { AnalyticsPeriod } from '../../../../../Models';
import AnalyticsService from '../../../../../Services/AnalyticsService';

export interface MyWidgetsProps {
  draggable: boolean;
  favourites: number[];
  period: AnalyticsPeriod;
  isResponseLimited: boolean;
  reportsLength: number;
  onRemove: (id: number) => void;
  onReorder: (ids: number[]) => void;
}

export const MyWidgets = ({
  favourites,
  draggable,
  period,
  isResponseLimited,
  reportsLength,
  onRemove,
  onReorder
}: MyWidgetsProps): React.ReactElement<MyWidgetsProps> => {
  const widgets = useMemo(
    () => AnalyticsService.getWidgets(favourites),
    [favourites]
  );

  return (
    <WidgetList
      widgets={widgets}
      draggable={draggable}
      isActive
      period={period}
      isResponseLimited={isResponseLimited}
      reportsLength={reportsLength}
      onRemove={onRemove}
      onReorder={onReorder}
    />
  );
};
