import React, { useContext } from 'react';
import styles from './GeneralInformation.module.scss';
import {
  GeneralInformationValueType,
  CreatorUserType
} from '../../../../Models';
import { formatDate, getFieldValueString } from '../../../../Helpers';
import { SFText } from 'sfui';
import { CustomerContext, Customer } from 'ui-smartforce-settings';

export interface GeneralInformationProps {
  data: GeneralInformationValueType;
}

export const GeneralInformation = ({
  data
}: GeneralInformationProps): React.ReactElement<GeneralInformationProps> => {
  const timezone = (useContext(CustomerContext).customer as Customer).timezone;

  const creatorUserGroups = data.created_by_user
    ? (data.created_by_user as CreatorUserType).groups
    : undefined;

  return (
    <div className={styles.generalInformation}>
      <div className={styles.h4}>General Information</div>

      {data.created_by_user?.title?.name && (
        <div className={styles.infoItem}>
          <SFText type="component-messages" sfColor="neutral">
            OFFICER TITLE
          </SFText>
          <SFText type="component-1">{data.created_by_user.title.name}</SFText>
        </div>
      )}

      <div className={styles.infoItem}>
        <SFText type="component-messages" sfColor="neutral">
          OFFICER NAME
        </SFText>
        <SFText type="component-1">{data.officer_name as string}</SFText>
      </div>

      <div className={styles.infoItem}>
        <SFText type="component-messages" sfColor="neutral">
          OFFICER ID
        </SFText>
        <SFText type="component-1">{data.officer_id_number as string}</SFText>
      </div>

      {creatorUserGroups && creatorUserGroups.length > 0 && (
        <div className={styles.infoItem}>
          <SFText type="component-messages" sfColor="neutral">
            OFFICER GROUPS
          </SFText>
          <SFText type="component-1">
            {getFieldValueString(creatorUserGroups.map((val) => val.name))}
          </SFText>
        </div>
      )}

      <div className={styles.infoItem}>
        <SFText type="component-messages" sfColor="neutral">
          DATE AND TIME
        </SFText>
        <SFText type="component-1">
          {formatDate(data.date as string, 'L - HH:mm', timezone)}
        </SFText>
      </div>

      <div className={styles.infoItem}>
        <SFText type="component-messages" sfColor="neutral">
          LOCATION
        </SFText>
        <SFText type="component-1">
          {data.location.address ? data.location.address.full : data.location}
        </SFText>
      </div>

      {data.location.areas && data.location.areas.length > 0 && (
        <div className={styles.infoItem}>
          <SFText type="component-messages" sfColor="neutral">
            AREAS
          </SFText>
          <SFText type="component-1">
            {getFieldValueString(data.location.areas.map((val) => val.name))}
          </SFText>
        </div>
      )}

      <div className={styles.infoItem}>
        <SFText type="component-messages" sfColor="neutral">
          TYPE
        </SFText>
        <SFText type="component-1">{data.type as string}</SFText>
      </div>

      {data.state_related?.to_connect_reports?.event_identifier && (
        <div className={styles.infoItem}>
          <SFText type="component-messages" sfColor="neutral">
            CAD / CASE NUMBER
          </SFText>
          <SFText type="component-1">
            {data.state_related.to_connect_reports.event_identifier}
          </SFText>
        </div>
      )}

      {data.was_use_of_force && (
        <div className={styles.infoItem}>
          <SFText type="component-messages" sfColor="neutral">
            WAS USE OF FORCE
          </SFText>
          <SFText type="component-1">Yes</SFText>
        </div>
      )}
    </div>
  );
};
