import React from 'react';
import styles from './NoData.module.scss';
import { AppearanceContext } from 'ui-smartforce-settings';
import { WidgetTypeImage } from '../../../../App/Main/Analytics/AnalyticsWidgets/ManageWidgetsModal/WidgetTypeForm/WidgetTypeImage/WidgetTypeImage';
import { SFText } from 'sfui';

export interface NoDataProps {
  type: string;
  link?: string;
  isContentOmitted?: boolean;
}

export const NoData = ({
  type,
  link,
  isContentOmitted
}: NoDataProps): React.ReactElement<NoDataProps> => {
  const { themeType } = React.useContext(AppearanceContext);

  return (
    <div
      className={`${styles.noData} ${
        type === 'rate_card' ? styles.isRateCard : ''
      }`}
    >
      {type !== 'rate_card' && (
        <WidgetTypeImage
          className={styles.image}
          type={type}
          isDayMode={themeType === 'day'}
        />
      )}

      {!isContentOmitted && (
        <>
          {type !== 'rate_card' && <h1>Get Started!</h1>}
          {type === 'rate_card' && <h2>Get Started!</h2>}
        </>
      )}

      {isContentOmitted && <h1>Content Omitted</h1>}

      {!isContentOmitted && (
        <SFText className={styles.message} type="component-1" sfColor="neutral">
          Need help collecting data for this widget? <br />
          <a href={link} target="blank">
            Click here
          </a>{' '}
          to learn how.
        </SFText>
      )}

      {isContentOmitted && (
        <SFText className={styles.message} type="component-1" sfColor="neutral">
          <span>
            This widget’s content was excluded from the reporting limit.
          </span>
          <br />
          <span>
            Try refining your filters to narrow down the data and find what you
            need.
          </span>
        </SFText>
      )}
    </div>
  );
};
