import React, { useContext } from 'react';
import { SFButton, SFScrollable, SFTimeline, SFTimelineItem } from 'sfui';
import styles from './ReportHistory.module.scss';
import { TourContext, TourTooltip } from 'ui-smartforce-settings';
import { ReportListItemTooltipType } from '../../ReportList/ReportList';
import { AmendDisabledTooltip } from '../../AmendDisabledTooltip/AmendDisabledTooltip';
import { useLocation } from 'react-router-dom';

const agencyTooltipDescription: Record<ReportListItemTooltipType, string> = {
  default:
    'You can amend a report as many times as you need. Just click the “Amend Report” button. In the History section, you will see all the versions. Any version can be viewed by clicking on it.',
  disabled:
    'Please note that there are no reports available for editing. Please try again another time.'
};

export interface ReportHistoryItem {
  version: number;
  title: string;
  subtitle: string;
}

export interface ReportHistoryProps {
  size?: 'medium' | 'large';
  items: ReportHistoryItem[];
  isAmendDisabled: boolean;
  selectedIndex?: number;
  tooltipType?: ReportListItemTooltipType;
  onItemClick: (item: ReportHistoryItem, index: number) => void;
  onAmend: () => void;
}

export const ReportHistory = ({
  size = 'medium',
  items,
  isAmendDisabled,
  tooltipType,
  selectedIndex,
  onItemClick,
  onAmend
}: ReportHistoryProps): React.ReactElement<ReportHistoryProps> => {
  const location = useLocation();
  const { tour, step: tourStep, onNext: onTourNext } = useContext(TourContext);

  const onAmendClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onAmend();
  };

  const onTimelineItemClick = (
    _timelineItem: SFTimelineItem,
    index: number
  ) => {
    onItemClick(items[index], index);
  };

  return (
    <div className={styles.reportHistory}>
      {(!tooltipType || location.pathname === '/cc/collaborated-reports') && (
        <AmendDisabledTooltip isActive={isAmendDisabled}>
          <div>
            <SFButton
              fullWidth
              size={size}
              variant="outlined"
              disabled={isAmendDisabled || selectedIndex !== 0}
              onClick={onAmendClick}
            >
              Amend Report
            </SFButton>
          </div>
        </AmendDisabledTooltip>
      )}

      {tooltipType && location.pathname === '/cc/agency-reports' && (
        <TourTooltip
          title="Amend your agency reports"
          description={agencyTooltipDescription[tooltipType]}
          step={1}
          lastStep={3}
          tourId={6}
          placement="bottom-end"
          preventOverflow
          topZIndex
          enterDelay={400}
        >
          <AmendDisabledTooltip
            isActive={isAmendDisabled && (tour?.id !== 6 || tourStep !== 1)}
          >
            <SFButton
              fullWidth
              size={size}
              variant="outlined"
              disabled={isAmendDisabled}
              onClick={() => {
                onTourNext({ tourId: 6, step: 1 });
                onAmend();
              }}
            >
              Amend Report
            </SFButton>
          </AmendDisabledTooltip>
        </TourTooltip>
      )}

      {tooltipType && location.pathname === '/cc/my-reports' && (
        <TourTooltip
          title="Amend your reports"
          description="You can amend a report as many times as you need. Just click the “Amend Report” button. In the History section, you will see all the versions. Any version can be viewed by clicking on it."
          step={1}
          lastStep={3}
          tourId={10}
          placement="bottom-end"
          topZIndex
          enterDelay={400}
        >
          <SFButton
            fullWidth
            size={size}
            variant="outlined"
            disabled={isAmendDisabled || selectedIndex !== 0}
            onClick={() => {
              onTourNext({ tourId: 10, step: 1 });
              onAmend();
            }}
          >
            Amend Report
          </SFButton>
        </TourTooltip>
      )}

      <SFScrollable containerClassName={styles.content}>
        <h4>History</h4>

        <SFTimeline
          size={size}
          items={items}
          selectedIndex={selectedIndex}
          onItemClick={onTimelineItemClick}
        />
      </SFScrollable>
    </div>
  );
};
