import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './OnboardingWizard.module.scss';
import { useHistory } from 'react-router-dom';
import {
  ChipFieldValueType,
  HttpStatusCode,
  SFAlertDialog,
  SFButton,
  SFScrollable,
  SFScrollableRefHandler
} from 'sfui';
import { WizardCard } from '../../../../../Components/WizardCard/WizardCard';
import { ApiError, ReportConfig } from '../../../../../Models';
import { SetUpAgency } from './SetUpAgency/SetUpAgency';
import { WizardCardStep } from '../../../../../Components/WizardCard/WizardCardStep/WizardCardStep';
import {
  UserContext,
  CustomerContext,
  State,
  Customer,
  User,
  saveCustomer,
  updateBadge,
  updateCustomer,
  PLAN_CONNECT,
  isPlanConnect,
  StatesListConfigContext,
  SubscriptionContext,
  getUserSettings,
  isEqualObject,
  getStripeCardToken,
  Member,
  Subscription,
  addInvitations,
  removeInvitations,
  createSubscription,
  getCardErrorMessage,
  StripeCardError,
  getAppSubscription,
  getPaidSubscription,
  BillingFormValue,
  PaymentFormValue,
  PaymentInformation,
  PurchaseDetails,
  getAgencyInvitations
} from 'ui-smartforce-settings';
import { AddMembers } from './AddMembers/AddMembers';
import {
  getStateFileName,
  handleError,
  isAgencyAlreadyExist
} from '../../../../../Helpers';
import { SelectPlan } from '../../../../../Components/SelectPlan/SelectPlan';
import {
  WizardFinish,
  WizardFinishType
} from '../../../../../Components/WizardFinish/WizardFinish';
import { WizardStepContent } from '../../../../../Components/WizardStepContent/WizardStepContent';

import {
  getData as getOnboardingData,
  saveOnboardingStep,
  saveOnboardingPlan,
  saveOnboardingBilling,
  removeData as removeOnboardingData
} from '../../../../../Services/OnboardingService';
import { StateConfigContext } from '../../../../../Context/StateConfigContext';
import { getStateConfig } from '../../../../../Services/AppService';
import { useElements, useStripe } from '@stripe/react-stripe-js';

const TRANSITION_TIMEOUT = 360;

const emptyAgency = {
  full_name: '',
  ori: '',
  state_name: '',
  phone1: '',
  email: '',
  timezone: ''
};

const initBillingFormValue: BillingFormValue = {
  billing_cycle: 'annually',
  additional_seats: 0
};

const initPaymentForm: PaymentFormValue = {
  acceptConditions: false,
  cardName: '',
  billing_details: { full_name: '', phone: '', full_address: '' }
};

export interface SetupWizardProps {
  show: boolean;
  onClose: () => void;
}

export const OnboardingWizard = ({
  show,
  ...props
}: SetupWizardProps): React.ReactElement<SetupWizardProps> => {
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const { customer, setCustomer } = useContext(CustomerContext);
  const { user, isOnboarding, setIsOnboarding, setUser, setUserSettings } =
    useContext(UserContext);
  const { subscriptions, setSubscriptions } = useContext(SubscriptionContext);
  const { setStateConfig } = useContext(StateConfigContext);
  const { statesList } = useContext(StatesListConfigContext);

  let localStorageData = getOnboardingData((user as User).id);

  const [step, setStep] = useState<number>(localStorageData.step);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOriTaken, setIsOriTaken] = React.useState<boolean>(false);
  const [isBadgeError, setIsBadgeError] = React.useState<boolean>(false);
  const [isPaymentsVisible, setIsPaymentsVisible] = useState<boolean>(true);

  const [agency, setAgency] = useState<Partial<Customer>>(
    customer ?? emptyAgency
  );
  const [badge, setBadge] = useState<string | Blob | undefined | null>(
    customer?.badge
  );
  const [members, setMembers] = useState<ChipFieldValueType[]>([]);
  const [plan, setPlan] = useState<string>(localStorageData.plan);

  const [billingFormValue, setBillingFormValue] = useState<BillingFormValue>({
    billing_cycle: localStorageData.billing_cycle,
    additional_seats: localStorageData.additional_seats
  });

  const [paymentFormValue, setPaymentFormValue] =
    useState<PaymentFormValue>(initPaymentForm);
  const [paymentMethod, setPaymentMethod] = useState<string>('card');
  const [paymentError, setPaymentError] = useState<
    StripeCardError | undefined
  >();
  const [finishType, setFinishType] = useState<WizardFinishType>('done');

  const refOriTakenValue = useRef<string | undefined>();
  // ref with saved members to compare and obtain added and deleted
  const refMembersSaved = useRef<string[]>([]);
  const refScrollable = useRef<SFScrollableRefHandler>(null);

  const hasOtherSubscription: boolean =
    subscriptions.length > 0 && !getAppSubscription(subscriptions, 'cc');

  const STEP_PURCHASE_DETAILS_NUMBER = hasOtherSubscription ? 1 : 4;
  const STEP_PAYMENT_NUMBER = hasOtherSubscription ? 2 : 5;
  const STEP_FINISH = hasOtherSubscription ? 3 : 6;

  const paidSubscription = getPaidSubscription(subscriptions);

  const refDebitPaymentUrl = useRef<string>('');

  const goNext = () => {
    if (!isOnboarding) {
      setIsOnboarding(true);
    }

    const nextStep = step + 1;

    //If it's not last step
    if (nextStep < STEP_FINISH) {
      // Save step to local storage
      // necessary to preserve step in case user close or refresh app
      saveOnboardingStep((user as User).id, nextStep);
    } else {
      // Remove saved data from local storage
      removeOnboardingData((user as User).id);
    }

    setStep((step) => step + 1);
  };

  const onClose = () => {
    props.onClose();
  };

  const onBack = () => {
    if (step === 1) {
      props.onClose();
    } else {
      const currentStep = step;
      // Save current step to local storage
      // necessary to preserve step in case user close or refresh app
      saveOnboardingStep((user as User).id, currentStep - 1);

      setStep((step) => step - 1);

      // Reset forms when animation ends
      setTimeout(() => {
        if (currentStep === STEP_PURCHASE_DETAILS_NUMBER) {
          // Reset billing and plan data from local storage
          saveOnboardingPlan((user as User).id, PLAN_CONNECT);
          saveOnboardingBilling((user as User).id, initBillingFormValue);
          setBillingFormValue(initBillingFormValue);
        } else if (currentStep === STEP_PAYMENT_NUMBER) {
          setPaymentError(undefined);
          setPaymentMethod('card');
          setPaymentFormValue({
            acceptConditions: false,
            cardName: '',
            billing_details: {
              full_name: user?.name ?? '',
              phone: user?.phone ?? '',
              full_address: agency.address ? agency.address.full : ''
            }
          });
        }
      }, TRANSITION_TIMEOUT);
    }
  };

  // Get members if agency is inactive
  useEffect(() => {
    const initMembers = async () => {
      setIsLoading(true);

      try {
        const members = await getAgencyInvitations(
          process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
          '',
          ['Pending Delivery'],
          0
        );

        const emails: string[] = members.data.map(
          (member: Member) => member.email as string
        );

        refMembersSaved.current = emails;

        const membersChipValues: ChipFieldValueType[] = emails.map(
          (email: string) => ({
            value: email
          })
        );

        setMembers(membersChipValues);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.error('OnboardingWizard::getMembers', e);
      }
    };

    if (customer?.status === 'Inactive') {
      initMembers();
    }
  }, [customer?.status]);

  // Update agency form with customer value if changes
  useEffect(() => {
    setAgency(customer ?? emptyAgency);
  }, [customer]);

  useEffect(() => {
    setPaymentFormValue((prevValue) => ({
      ...prevValue,
      billing_details: {
        full_name: user?.name ?? '',
        phone: user?.phone ?? '',
        full_address: agency.address ? agency.address.full : ''
      }
    }));
  }, [user, agency]);

  const initializeActiveCustomer = async (subscriptions: Subscription[]) => {
    // Update created subscription
    setSubscriptions(subscriptions);

    // Update customer status to Active
    setCustomer((customer) => ({
      ...(customer as Customer),
      status: 'Active'
    }));

    //Get state config to enable report creation
    if (!isPlanConnect(plan)) {
      const stateConfig: ReportConfig = await getStateConfig(
        getStateFileName(statesList as State[], customer?.state_name as string)
      );
      setStateConfig(stateConfig);
    }

    const userSettings = await getUserSettings(
      process.env.REACT_APP_SETTINGS_API_BASE_URL as string
    );
    setUserSettings(userSettings);
  };

  const onAgencyChange = (agency: Partial<Customer>) => {
    setAgency(agency);

    // clear ori error if user changed ori's value
    if (isOriTaken && agency.ori !== refOriTakenValue.current) {
      setIsOriTaken(false);
    }
  };

  const onSaveAgency = async () => {
    let customerEdit = { ...agency };

    setIsLoading(true);
    setIsOnboarding(true);

    try {
      // It's a new agency
      if (!agency.id) {
        customerEdit = await saveCustomer(
          process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
          agency.full_name as string,
          agency.state_name as string,
          agency.phone1 as string,
          agency.email as string,
          agency.ori as string,
          agency.timezone as string,
          agency.jurisdiction_code
        );

        const agencyId = (customerEdit as Customer).id;
        const newUser = {
          ...(user as User),
          agency_id: agencyId
        };

        setIsOriTaken(false);
        setUser(newUser);
      } else {
        // The agency already exists and is inactive

        // Check if customer has changes
        if (!isEqualObject<Partial<Customer>>(agency, customer as Customer)) {
          await updateCustomer(
            process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
            agency
          );
        }
      }

      // No ori error, clear ref
      refOriTakenValue.current = undefined;

      if (badge instanceof Blob) {
        try {
          const urlBadge: string = await updateBadge(
            process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
            badge
          );
          customerEdit.badge = urlBadge;
        } catch (e) {
          setIsBadgeError(true);
        }
      }
    } catch (e) {
      console.error('SetupAgency::onCreate', e);
      if (isAgencyAlreadyExist(e)) {
        // Set ref with existing ori
        refOriTakenValue.current = agency.ori;
        setIsOriTaken(true);
      } else {
        handleError(e, history);
        return;
      }
    }

    setIsLoading(false);

    // If there's no ori error, continue process
    if (!refOriTakenValue.current) {
      setCustomer(customerEdit as Customer);
      goNext();
    }
  };

  const onCloseBadgeError = () => setIsBadgeError(false);

  const onAddMembers = async () => {
    setIsLoading(true);

    try {
      const membersChipsEmails = members.map(
        (memberChip: ChipFieldValueType) => memberChip.value
      );

      // New members -> emails in chips but not saved
      let newMembers = membersChipsEmails.filter(
        (email: string) => !refMembersSaved.current.includes(email)
      );

      // Deleted members -> emails saved but not in chips input
      let deletedMembers = refMembersSaved.current.filter(
        (email: string) => !membersChipsEmails.includes(email)
      );

      if (newMembers.length > 0) {
        await addInvitations(
          process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
          newMembers
        );
      }

      if (deletedMembers.length > 0) {
        await removeInvitations(
          process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
          deletedMembers
        );
      }

      // Update the members list, needed to remove not available invitations already sent
      const membersSaved = await getAgencyInvitations(
        process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
        '',
        ['Pending Delivery'],
        0
      );

      refMembersSaved.current = membersSaved.data.map(
        (member: Member) => member.email as string
      );

      const membersChipValues: ChipFieldValueType[] =
        refMembersSaved.current.map((email: string) => ({
          value: email
        }));

      setMembers(membersChipValues);
      setIsLoading(false);
      goNext();
    } catch (e) {
      setIsLoading(false);
      console.error('OnboardingWizard::AddMembers', e);
      handleError(e, history);
    }
  };

  const onSelectPlan = async (plan: string) => {
    setPlan(plan);
    saveOnboardingPlan((user as User).id, plan);

    if (isPlanConnect(plan)) {
      setIsLoading(true);

      try {
        const newSubscription = await createSubscription(
          process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
          {
            plan,
            card_token: '',
            additional_seats: billingFormValue.additional_seats,
            billing_cycle: billingFormValue.billing_cycle,
            billing_details: paymentFormValue.billing_details,
            product: 'cc',
            payment_method: 'card'
          }
        );

        await initializeActiveCustomer([...subscriptions, newSubscription]);
      } catch (e) {
        console.error('OnboardingWizard::onSelectPlan', e);
        setIsLoading(false);
        handleError(e, history);
        return;
      }

      setIsLoading(false);
      setIsPaymentsVisible(false);
      setFinishType('done');
    } else {
      setIsPaymentsVisible(true);
    }

    goNext();
  };

  const onConfirm = async () => {
    setIsLoading(true);

    if (paymentMethod === 'card') {
      try {
        const cardToken = await getStripeCardToken(
          paymentFormValue.cardName,
          stripe,
          elements
        );

        const newSubscription = await createSubscription(
          process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
          {
            plan,
            product: 'cc',
            payment_method: 'card',
            card_token: cardToken,
            additional_seats: billingFormValue.additional_seats,
            billing_cycle: billingFormValue.billing_cycle,
            billing_details: paymentFormValue.billing_details
          }
        );

        await initializeActiveCustomer([...subscriptions, newSubscription]);

        setFinishType('payment-accepted');
        setIsLoading(false);
        goNext();
      } catch (e) {
        setIsLoading(false);
        console.error('OnboardingWizard::onConfirmPayment', e);
        const error: ApiError = e as ApiError;

        if (error.code === HttpStatusCode.BAD_REQUEST) {
          const cardErrorMsg = getCardErrorMessage(error.detail);
          setPaymentError({
            title: 'We could not proceed with the payment.',
            message: cardErrorMsg
          });
          refScrollable.current?.scrollToTop('smooth');
        } else {
          handleError(error, history);
        }
      }
    } else {
      try {
        const newSubscription = await createSubscription(
          process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
          {
            plan,
            product: 'cc',
            payment_method: 'debit',
            additional_seats: billingFormValue.additional_seats,
            billing_cycle: billingFormValue.billing_cycle,
            billing_details: paymentFormValue.billing_details
          }
        );

        refDebitPaymentUrl.current = newSubscription.payment
          ?.payment_method_setup_url as string;

        setFinishType('payment-pending');
        setIsLoading(false);
        goNext();
      } catch (e) {
        console.error('OnboardingWizard::onContactSales', e);
        handleError(e, history);
      }
    }
  };

  const onFinish = () => {
    if (finishType !== 'payment-pending') {
      removeOnboardingData((user as User).id);
      // Set as false so the guard redirects to main route
      setIsOnboarding(false);
      history.replace('/cc');
    } else {
      window.location.href = refDebitPaymentUrl.current;
    }
  };

  const totalSeatsUsed = subscriptions[0]
    ? subscriptions[0].total_seats_used
    : members.length + 1;

  return (
    <SFScrollable
      className={styles.scrollable}
      containerClassName={`${styles.onboardingWizard} ${
        !show ? styles.hide : ''
      }`}
    >
      <SFAlertDialog
        title="Before you go on..."
        contentText="Your agency was created successfully, but unfortunately, the image could
      not be uploaded. Please try again later from Settings."
        open={isBadgeError}
        onClose={onCloseBadgeError}
        rightAction={{
          label: 'Yes, I Understand',
          buttonProps: {
            onClick: onCloseBadgeError
          }
        }}
      />
      <WizardCard
        className={styles.wizardCard}
        isLoading={isLoading}
        show={show}
        step={step}
        onBack={hasOtherSubscription && step === 1 ? undefined : onBack}
        onClose={hasOtherSubscription ? undefined : onClose}
      >
        <WizardCardStep
          disabled={hasOtherSubscription}
          title="Setup"
          step={1}
          limit={3}
        >
          <WizardStepContent
            title="Set up your Agency"
            subtitle="To continue, please enter the required information."
          >
            <SetUpAgency
              agency={agency}
              badge={badge}
              isOriTaken={isOriTaken}
              onChange={onAgencyChange}
              onBadgeChange={(badge: Blob) => setBadge(badge)}
              onSave={onSaveAgency}
            />
          </WizardStepContent>
        </WizardCardStep>

        <WizardCardStep
          disabled={hasOtherSubscription}
          title="Setup"
          step={2}
          limit={3}
        >
          <WizardStepContent
            title="Add Members"
            subtitle="You can add them now or after finishing the onboarding process from Settings."
          >
            <AddMembers
              members={members}
              onChange={(members: ChipFieldValueType[]) => setMembers(members)}
              onContinue={onAddMembers}
            />
          </WizardStepContent>
        </WizardCardStep>

        <WizardCardStep
          title={hasOtherSubscription ? 'Step' : 'Setup'}
          step={hasOtherSubscription ? 1 : 3}
          limit={3}
        >
          <WizardStepContent
            title="Finish Setup"
            subtitle="Choose your agency plan."
          >
            <SelectPlan onSelect={onSelectPlan} />
          </WizardStepContent>
        </WizardCardStep>

        <WizardCardStep
          disabled={!isPaymentsVisible}
          title={hasOtherSubscription ? 'Step' : 'Payment'}
          step={hasOtherSubscription ? 2 : 1}
          limit={hasOtherSubscription ? 3 : 2}
        >
          <WizardStepContent
            title="Review your Purchase Details"
            subtitle={
              !hasOtherSubscription
                ? 'Before payment, select your billing cycle and add as many seats as your agency needs.'
                : 'Before making your payment, review your purchase information.'
            }
          >
            <PurchaseDetails
              onChange={(value: BillingFormValue) => {
                setBillingFormValue(value);
                saveOnboardingBilling((user as User).id, value);
              }}
              onContinue={goNext}
              plan={plan}
              totalSeatsUsed={totalSeatsUsed}
              totalSeatsBilled={paidSubscription?.total_seats_billed}
              value={billingFormValue}
              additionalSeatsAvailable
            />
          </WizardStepContent>
        </WizardCardStep>

        <WizardCardStep
          disabled={!isPaymentsVisible}
          title={hasOtherSubscription ? 'Step' : 'Payment'}
          step={hasOtherSubscription ? 3 : 2}
          limit={hasOtherSubscription ? 3 : 2}
        >
          <WizardStepContent
            title="Enter your Payment Information"
            subtitle="One more step before to finish."
          >
            <PaymentInformation
              method={paymentMethod}
              hideDebit={billingFormValue.billing_cycle === 'monthly'}
              onChange={(newValue) => setPaymentFormValue(newValue)}
              onConfirm={onConfirm}
              onMethodChange={(method: string) => setPaymentMethod(method)}
              error={paymentError}
              value={paymentFormValue}
              ref={refScrollable}
            />
          </WizardStepContent>
        </WizardCardStep>

        <WizardCardStep noHeader={true}>
          <WizardFinish
            className={styles.finish}
            type={finishType}
            ButtonElement={
              <SFButton onClick={onFinish}>
                {finishType !== 'payment-pending'
                  ? 'Go to Home'
                  : 'Complete Information'}
              </SFButton>
            }
          />
        </WizardCardStep>
      </WizardCard>
    </SFScrollable>
  );
};
