import React, { useContext, useState } from 'react';
import styles from './RankingCardElementList.module.scss';
import { RankingCardListElement } from '../../../../../../Models';
import { SFText, SFTooltip } from 'sfui';
import {
  formatDate,
  isISOStringValid,
  isMobile
} from '../../../../../../Helpers';
import {
  Customer,
  CustomerContext,
  InteractiveBox
} from 'ui-smartforce-settings';

export interface RankingCardElementListProps {
  className: string;
  list: RankingCardListElement[];
  tooltipLabel?: string;
}

export const RankingCardElementList = ({
  className,
  list,
  tooltipLabel
}: RankingCardElementListProps): React.ReactElement<RankingCardElementListProps> => {
  const timezone = (useContext(CustomerContext).customer as Customer).timezone;
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

  const onInfoClose = () => {
    if (isMobile()) {
      setIsTooltipOpen(false);
    }
  };

  const onInfoClick = () => {
    if (isMobile() && !isTooltipOpen) {
      setIsTooltipOpen(true);
    }
  };

  return (
    <ul className={`${styles.rankingCardElementList} ${className}`}>
      {list.map((element: RankingCardListElement) => (
        <li key={element.label}>
          {tooltipLabel && (
            <SFTooltip
              sfColor="inverted"
              title={tooltipLabel}
              content={<p style={{ margin: 0 }}>{element.tooltip_value}</p>}
              placement="bottom-start"
              open={isMobile() ? isTooltipOpen : undefined}
              leaveTouchDelay={3500}
              onClose={onInfoClose}
              disableHoverListener={isMobile()}
            >
              <InteractiveBox
                className={styles.tooltipRef}
                onClick={onInfoClick}
              >
                <SFText type="component-1">{element.label}</SFText>
              </InteractiveBox>
            </SFTooltip>
          )}

          {!tooltipLabel && <SFText type="component-1">{element.label}</SFText>}

          {element.detail && (
            <span className={styles.detail}>
              {isISOStringValid(element.detail)
                ? formatDate(element.detail, 'MM/DD/YYYY', timezone)
                : element.detail}
            </span>
          )}
        </li>
      ))}
    </ul>
  );
};
