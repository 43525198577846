import React, { useContext, useMemo, useState } from 'react';
import styles from './PatternMap.module.scss';
import { Coords, PatternMapDataType } from '../../../../Models/Widget';
import { DataDict, PatterMapInfo } from './PatterMapInfo/PatterMapInfo';
import { WidgetPanel } from '../../WidgetPanel/WidgetPanel';
import {
  MediaContext,
  Marker,
  Customer,
  CustomerContext
} from 'ui-smartforce-settings';
import { WidgetDrawer } from '../../WidgetDrawer/WidgetDrawer';
import {
  useFilteredReports,
  useMapOptions,
  useMarkerClick
} from '../../../../Hooks';
import {
  transformCoordsToLatLng,
  getCountMarker,
  formatDate
} from '../../../../Helpers';
import { ReportsMap } from '../../../ReportsMap/ReportsMap';
import { PatterMapItemIncidentData } from './PatterMapInfo/PatternMapItem/PatterMapItemIncident/PatterMapItemIncident';
import { FullReportContext } from '../../../../Context/FullReportContext';

export interface PatternMapProps {
  title: string;
  data: PatternMapDataType[];
  isDrawerOpen?: boolean;
  onDrawerClose?: () => void;
}

const arrangeData = (
  data: PatternMapDataType[],
  timezone: string
): DataDict => {
  let arrangedData: DataDict = {};

  data.forEach((value: PatternMapDataType, index: number) => {
    const formattedDate: string = formatDate(
      value.incident_date,
      'ddd, D MMM',
      timezone
    );
    arrangedData[formattedDate] = [
      ...(arrangedData[formattedDate] || []),
      { ...value, index: index + 1 }
    ];
  });

  return arrangedData;
};

export const PatternMap = ({
  title,
  data,
  isDrawerOpen = false,
  onDrawerClose
}: PatternMapProps): React.ReactElement<PatternMapProps> => {
  const timezone = (useContext(CustomerContext).customer as Customer).timezone;
  const { isPhone } = useContext(MediaContext);
  const { showFullReport } = useContext(FullReportContext);
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);

  const { reportsWithCoords, reportsWithoutCoords } =
    useFilteredReports<PatternMapDataType>(data);

  const arrangedData: DataDict = useMemo(
    () => arrangeData(data, timezone),
    [data, timezone]
  );

  const options = useMapOptions(data);

  const { onMarkerClick } = useMarkerClick(reportsWithCoords);

  const onIncidentClick = async (item: PatterMapItemIncidentData) =>
    showFullReport(item.incident_number);

  return (
    <div className={styles.patternMap}>
      <ReportsMap {...options} reportsWithoutCoords={reportsWithoutCoords}>
        {reportsWithCoords.map((item: PatternMapDataType, index: number) => (
          <Marker
            key={`${item.incident_number}-${index}`}
            {...getCountMarker(
              transformCoordsToLatLng(item.coords as Coords),
              index + 1
            )}
            onClick={() => onMarkerClick('closeCoords', item)}
          />
        ))}
      </ReportsMap>

      <PatterMapInfo
        className={styles.info}
        data={arrangedData}
        onIncidentClick={onIncidentClick}
      />

      {!isPhone && (
        <WidgetPanel
          isOpen={isPanelOpen}
          onToggle={() => setIsPanelOpen((open: boolean) => !open)}
        >
          <PatterMapInfo
            data={arrangedData}
            onIncidentClick={onIncidentClick}
          />
        </WidgetPanel>
      )}

      {isPhone && (
        <WidgetDrawer
          title={title}
          isOpen={isDrawerOpen}
          onClose={() => onDrawerClose && onDrawerClose()}
        >
          <PatterMapInfo
            data={arrangedData}
            onIncidentClick={onIncidentClick}
          />
        </WidgetDrawer>
      )}
    </div>
  );
};
