import React, { useContext } from 'react';
import styles from './WidgetTypeForm.module.scss';
import { SFCheckbox } from 'sfui';
import { AppearanceContext } from 'ui-smartforce-settings';
import { WidgetTypeImage } from './WidgetTypeImage/WidgetTypeImage';

export interface WidgetTypeFormValue {
  id: number;
  label: string;
}

export interface WidgetTypeFormProps {
  type: string;
  widgets: WidgetTypeFormValue[];
  value: number[];
  onChange: (id: number, checked: boolean) => void;
}

export const WidgetTypeForm = ({
  type,
  widgets,
  value,
  onChange
}: WidgetTypeFormProps): React.ReactElement<WidgetTypeFormProps> => {
  const { themeType } = useContext(AppearanceContext);

  return (
    <div className={styles.widgetTypeForm}>
      <WidgetTypeImage type={type} isDayMode={themeType === 'day'} />
      <ul className={styles.checkboxList}>
        {widgets.map((w) => (
          <li key={`checkbox-${w.label}`}>
            <SFCheckbox
              label={w.label}
              checked={value.includes(w.id)}
              onChange={(_e, checked) => onChange(w.id, checked)}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
