import React from 'react';
import styles from './SelectPlan.module.scss';
import { SFScrollable, SFText } from 'sfui';
import { PlanDescriptionCard } from '../PlanCards/PlanDescriptionCard/PlanDescriptionCard';
import { PlanDescriptionList } from '../PlanCards/PlanDescriptionCard/PlanDescriptionList/PlanDescriptionList';
import { PlanDescriptionItem } from '../PlanCards/PlanDescriptionCard/PlanDescriptionList/PlanDescriptionItem/PlanDescriptionItem';
import {
  SubscriptionPlan,
  isPlanConnect,
  PLAN_CONNECT,
  PLAN_ANALYTICS,
  ANNUALLY_FEE_ANALYTICS,
  isPlanAnalytics
} from 'ui-smartforce-settings';

function getPrice(value: number): string {
  return `$${value} x member x year`;
}

export interface SelectPlanProps {
  currentPlan?: SubscriptionPlan;
  onSelect: (plan: SubscriptionPlan) => void;
}

export const SelectPlan = ({
  currentPlan,
  onSelect
}: SelectPlanProps): React.ReactElement<SelectPlanProps> => {
  return (
    <SFScrollable containerClassName={styles.selectPlan}>
      <PlanDescriptionCard
        className={styles.planDescriptionCard}
        name="Connect"
        price="Free"
        priceDetail="For all Agencies"
        disabled={currentPlan && !isPlanAnalytics(currentPlan)}
        current={isPlanConnect(currentPlan)}
        onSelect={() => onSelect(PLAN_CONNECT)}
      >
        <PlanDescriptionList>
          <PlanDescriptionItem label="Business Card" />
          <PlanDescriptionItem label="Support Page" />
          <PlanDescriptionItem label="Roles" />
          <PlanDescriptionItem label="Titles" />
        </PlanDescriptionList>
      </PlanDescriptionCard>

      <PlanDescriptionCard
        className={styles.planDescriptionCard}
        buttonVariant="contained"
        name="Analytics"
        price={getPrice(ANNUALLY_FEE_ANALYTICS)}
        priceDetail="Billed annually"
        onSelect={() => onSelect(PLAN_ANALYTICS)}
      >
        <PlanDescriptionList>
          <SFText className={styles.strong} type="component-2">
            Everything in Connect, plus...
          </SFText>
          <PlanDescriptionItem label="Create Contacts" />
          <PlanDescriptionItem label="Contacts History" />
          <PlanDescriptionItem label="Amend Contacts" />
          <PlanDescriptionItem label="Offline Reporting" />
          <PlanDescriptionItem label="Amend Officers Contacts" />
          <PlanDescriptionItem label="Areas" />
          <PlanDescriptionItem label="Groups" />
          <PlanDescriptionItem label="Tags" />
          <PlanDescriptionItem label="Add Investigations and Complaints" />
          <PlanDescriptionItem label="Weekly, monthly, quarterly, semi-annual, and annual reports" />
          <PlanDescriptionItem label="+45 different widgets" />
          <PlanDescriptionItem label="Comparison of rates between periods" />
          <PlanDescriptionItem label="Mapping (Heatmaps, Patterns, Clusters and Filtered by categories)" />
          <PlanDescriptionItem label="Demographics" />
          <PlanDescriptionItem label="FastFacts" />
          <PlanDescriptionItem label="Advanced Filtering" />
        </PlanDescriptionList>
      </PlanDescriptionCard>
    </SFScrollable>
  );
};
