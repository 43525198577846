import React from 'react';
import styles from './TinyWidget.module.scss';
import { SFTooltip } from 'sfui';
import { MediaContext } from 'ui-smartforce-settings';

export interface TinyWidgetProps {
  className?: string;
  disabled?: boolean;
  title: string;
  value: number;
}

const getFormattedValue = (value: number): string => {
  const valueToString: string = value.toString();

  if (valueToString.length >= 6) {
    if (valueToString.length >= 9) {
      return `${valueToString.slice(0, 3)}M`;
    }

    return `${valueToString.slice(0, 3)}K`;
  }

  return valueToString;
};

const isSixDigitValue = (value: number): boolean => {
  return value > 99999;
};

export const TinyWidget = ({
  className,
  disabled,
  title,
  value
}: TinyWidgetProps): React.ReactElement<TinyWidgetProps> => {
  const { isPhone } = React.useContext(MediaContext);
  const [isTooltipOpen, setIsTooltipOpen] = React.useState<boolean>(false);

  const onMouseOver = () => {
    if (isSixDigitValue(value) && !disabled) {
      setIsTooltipOpen(true);
    }
  };

  const onMouseLeave = () => {
    if (!disabled) {
      setIsTooltipOpen(false);
    }
  };

  const onClick = () => {
    if (isSixDigitValue(value) && !disabled && isPhone) {
      setIsTooltipOpen(true);
    }
  };

  return (
    <div
      className={`${styles.tinyWidget} ${className} ${
        disabled ? styles.disabled : ''
      }`}
    >
      <h5 className={styles.title}>{title}</h5>

      <SFTooltip
        sfColor="inverted"
        title={title}
        content={value.toString()}
        leaveTouchDelay={3500}
        open={isTooltipOpen}
        onClose={() => setIsTooltipOpen(false)}
        placement="bottom"
        disableHoverListener={isPhone}
        onClick={onClick}
      >
        <div
          onMouseOver={onMouseOver}
          onFocus={onMouseOver}
          onMouseLeave={onMouseLeave}
          onBlur={onMouseLeave}
        >
          <h1>{getFormattedValue(value)}</h1>
        </div>
      </SFTooltip>
    </div>
  );
};
