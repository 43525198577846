import React from 'react';
import styles from './PeriodOptionSoon.module.scss';
import { SFChip, SFText } from 'sfui';

export interface PeriodOptionSoonProps {
  label: string;
}

export const PeriodOptionSoon = ({
  label
}: PeriodOptionSoonProps): React.ReactElement<PeriodOptionSoonProps> => {
  return (
    <div className={styles.periodOptionSoon}>
      <SFText type="component-1">{label}</SFText>
      <SFChip label="Coming Soon" size="small" sfColor="default" />
    </div>
  );
};
